import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _65233ee2 = () => interopDefault(import('..\\pages\\admin\\index.vue' /* webpackChunkName: "pages/admin/index" */))
const _7ad85b79 = () => interopDefault(import('..\\pages\\customer-profile.vue' /* webpackChunkName: "pages/customer-profile" */))
const _ceae8d9e = () => interopDefault(import('..\\pages\\dang-nhap.vue' /* webpackChunkName: "pages/dang-nhap" */))
const _fe9c9850 = () => interopDefault(import('..\\pages\\dautu\\index.vue' /* webpackChunkName: "pages/dautu/index" */))
const _15e04b08 = () => interopDefault(import('..\\pages\\du-an\\index.vue' /* webpackChunkName: "pages/du-an/index" */))
const _d6161bd4 = () => interopDefault(import('..\\pages\\forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _7e17e6d5 = () => interopDefault(import('..\\pages\\lien-he.vue' /* webpackChunkName: "pages/lien-he" */))
const _0adeed27 = () => interopDefault(import('..\\pages\\list-application-of-customer.vue' /* webpackChunkName: "pages/list-application-of-customer" */))
const _385050ba = () => interopDefault(import('..\\pages\\login.vue' /* webpackChunkName: "pages/login" */))
const _0a164075 = () => interopDefault(import('..\\pages\\map\\index.vue' /* webpackChunkName: "pages/map/index" */))
const _08b355e2 = () => interopDefault(import('..\\pages\\register.vue' /* webpackChunkName: "pages/register" */))
const _90bde750 = () => interopDefault(import('..\\pages\\reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _5f03d58c = () => interopDefault(import('..\\pages\\congnghiep\\cumcongnghiep.vue' /* webpackChunkName: "pages/congnghiep/cumcongnghiep" */))
const _3ec5d349 = () => interopDefault(import('..\\pages\\congnghiep\\khucongnghiep.vue' /* webpackChunkName: "pages/congnghiep/khucongnghiep" */))
const _eb6297c2 = () => interopDefault(import('..\\pages\\dautu\\cam-nang-dau-tu.vue' /* webpackChunkName: "pages/dautu/cam-nang-dau-tu" */))
const _5c1f5f30 = () => interopDefault(import('..\\pages\\dautu\\camnangdautu.vue' /* webpackChunkName: "pages/dautu/camnangdautu" */))
const _140adcee = () => interopDefault(import('..\\pages\\dautu\\duandautunoibat.vue' /* webpackChunkName: "pages/dautu/duandautunoibat" */))
const _9d4b4224 = () => interopDefault(import('..\\pages\\dautu\\luachonnhadautu\\index.vue' /* webpackChunkName: "pages/dautu/luachonnhadautu/index" */))
const _c0f8e942 = () => interopDefault(import('..\\pages\\dautu\\nghiencuudautu\\index.vue' /* webpackChunkName: "pages/dautu/nghiencuudautu/index" */))
const _4de874e7 = () => interopDefault(import('..\\pages\\dautu\\thuonghieudautu.vue' /* webpackChunkName: "pages/dautu/thuonghieudautu" */))
const _30576d23 = () => interopDefault(import('..\\pages\\dautu\\thuvienanhvideo.vue' /* webpackChunkName: "pages/dautu/thuvienanhvideo" */))
const _a05cd830 = () => interopDefault(import('..\\pages\\dautu\\tiemnangvacohoi.vue' /* webpackChunkName: "pages/dautu/tiemnangvacohoi" */))
const _efb6b466 = () => interopDefault(import('..\\pages\\dautu\\trinhtuthutuc.vue' /* webpackChunkName: "pages/dautu/trinhtuthutuc" */))
const _583fd95e = () => interopDefault(import('..\\pages\\dautu\\trinhtuthutucluachonnhadautu.vue' /* webpackChunkName: "pages/dautu/trinhtuthutucluachonnhadautu" */))
const _407f60fb = () => interopDefault(import('..\\pages\\dautu\\van-de-phap-ly.vue' /* webpackChunkName: "pages/dautu/van-de-phap-ly" */))
const _70b994ac = () => interopDefault(import('..\\pages\\error\\error-404.vue' /* webpackChunkName: "pages/error/error-404" */))
const _11d395da = () => interopDefault(import('..\\pages\\gis\\cumcongnghiep.vue' /* webpackChunkName: "pages/gis/cumcongnghiep" */))
const _24a2ec8a = () => interopDefault(import('..\\pages\\gis\\nghiencuudautu.vue' /* webpackChunkName: "pages/gis/nghiencuudautu" */))
const _282109c4 = () => interopDefault(import('..\\pages\\map\\cum-cong-nghiep.vue' /* webpackChunkName: "pages/map/cum-cong-nghiep" */))
const _03d54c53 = () => interopDefault(import('..\\pages\\map\\cum-hoac-khu-cong-nghiep.vue' /* webpackChunkName: "pages/map/cum-hoac-khu-cong-nghiep" */))
const _660e913b = () => interopDefault(import('..\\pages\\map\\district.vue' /* webpackChunkName: "pages/map/district" */))
const _327080ef = () => interopDefault(import('..\\pages\\map\\map-leaflet.vue' /* webpackChunkName: "pages/map/map-leaflet" */))
const _8d7a078c = () => interopDefault(import('..\\pages\\tintuc\\thong-tin-kinh-te-xa-hoi.vue' /* webpackChunkName: "pages/tintuc/thong-tin-kinh-te-xa-hoi" */))
const _63abb214 = () => interopDefault(import('..\\pages\\trangchu\\demo.vue' /* webpackChunkName: "pages/trangchu/demo" */))
const _119b3da8 = () => interopDefault(import('..\\pages\\trangchu\\demo2.vue' /* webpackChunkName: "pages/trangchu/demo2" */))
const _567e7273 = () => interopDefault(import('..\\pages\\uudai\\capnuocsach.vue' /* webpackChunkName: "pages/uudai/capnuocsach" */))
const _83f65384 = () => interopDefault(import('..\\pages\\uudai\\chatthairan.vue' /* webpackChunkName: "pages/uudai/chatthairan" */))
const _4d105153 = () => interopDefault(import('..\\pages\\uudai\\hoptacxa.vue' /* webpackChunkName: "pages/uudai/hoptacxa" */))
const _4b7a00cb = () => interopDefault(import('..\\pages\\uudai\\nganhnghe.vue' /* webpackChunkName: "pages/uudai/nganhnghe" */))
const _32a82ea9 = () => interopDefault(import('..\\pages\\uudai\\nongnghiepnongthon.vue' /* webpackChunkName: "pages/uudai/nongnghiepnongthon" */))
const _54341e5e = () => interopDefault(import('..\\pages\\uudai\\xahoihoa.vue' /* webpackChunkName: "pages/uudai/xahoihoa" */))
const _c1437540 = () => interopDefault(import('..\\pages\\admin\\du-an\\danh-sach.vue' /* webpackChunkName: "pages/admin/du-an/danh-sach" */))
const _6c6871d2 = () => interopDefault(import('..\\pages\\admin\\thu-hut-trong-diem\\danh-sach.vue' /* webpackChunkName: "pages/admin/thu-hut-trong-diem/danh-sach" */))
const _51fdb7f8 = () => interopDefault(import('..\\pages\\admin\\thu-hut-trong-diem\\them-moi.vue' /* webpackChunkName: "pages/admin/thu-hut-trong-diem/them-moi" */))
const _17d5182a = () => interopDefault(import('..\\pages\\dautu\\components\\readmore.vue' /* webpackChunkName: "pages/dautu/components/readmore" */))
const _665b8ab8 = () => interopDefault(import('..\\pages\\dautu\\luachonnhadautu\\chitiet.vue' /* webpackChunkName: "pages/dautu/luachonnhadautu/chitiet" */))
const _137f4472 = () => interopDefault(import('..\\pages\\dautu\\thongtinquyhoach\\quyhoachquangnam.vue' /* webpackChunkName: "pages/dautu/thongtinquyhoach/quyhoachquangnam" */))
const _ec9d1442 = () => interopDefault(import('..\\pages\\dautu\\thongtinquyhoach\\quyhoachvungdong.vue' /* webpackChunkName: "pages/dautu/thongtinquyhoach/quyhoachvungdong" */))
const _6b26106c = () => interopDefault(import('..\\pages\\dautu\\thongtinquyhoach\\quyhoachvungtinh.vue' /* webpackChunkName: "pages/dautu/thongtinquyhoach/quyhoachvungtinh" */))
const _22324d9a = () => interopDefault(import('..\\pages\\dautu\\thongtinquyhoach\\thongtinchitiet.vue' /* webpackChunkName: "pages/dautu/thongtinquyhoach/thongtinchitiet" */))
const _2a5b548e = () => interopDefault(import('..\\pages\\admin\\thu-hut-trong-diem\\components\\vitriduan.vue' /* webpackChunkName: "pages/admin/thu-hut-trong-diem/components/vitriduan" */))
const _6ea8ada3 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))
const _7eead987 = () => interopDefault(import('..\\pages\\dautu\\nghiencuudautu\\_id.vue' /* webpackChunkName: "pages/dautu/nghiencuudautu/_id" */))
const _23176ecf = () => interopDefault(import('..\\pages\\du-an\\_type.vue' /* webpackChunkName: "pages/du-an/_type" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'exact-active-link',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _65233ee2,
    meta: {"name":"admin","layout":"master-admin"},
    name: "admin"
  }, {
    path: "/customer-profile",
    component: _7ad85b79,
    meta: {"name":"ProfilePage","layout":"master","middleware":["auth"]},
    name: "customer-profile"
  }, {
    path: "/dang-nhap",
    component: _ceae8d9e,
    meta: {"name":"dangNhap"},
    name: "dang-nhap"
  }, {
    path: "/dautu",
    component: _fe9c9850,
    meta: {"name":"DauTu","layout":"master"},
    name: "dautu"
  }, {
    path: "/du-an",
    component: _15e04b08,
    meta: {},
    name: "du-an"
  }, {
    path: "/forgot-password",
    component: _d6161bd4,
    meta: {"name":"ForgotPassword","layout":"master"},
    name: "forgot-password"
  }, {
    path: "/lien-he",
    component: _7e17e6d5,
    meta: {"name":"lienHe","layout":"master"},
    name: "lien-he"
  }, {
    path: "/list-application-of-customer",
    component: _0adeed27,
    meta: {"name":"IndexPage","layout":"master","middleware":["auth"]},
    name: "list-application-of-customer"
  }, {
    path: "/login",
    component: _385050ba,
    meta: {"name":"LoginPage","layout":"master","middleware":["guest"]},
    name: "login"
  }, {
    path: "/map",
    component: _0a164075,
    meta: {"name":"Map","layout":"empty"},
    name: "map"
  }, {
    path: "/register",
    component: _08b355e2,
    meta: {"name":"RegisterPage","layout":"master"},
    name: "register"
  }, {
    path: "/reset-password",
    component: _90bde750,
    meta: {"name":"ResetPassword","layout":"master"},
    name: "reset-password"
  }, {
    path: "/congnghiep/cumcongnghiep",
    component: _5f03d58c,
    meta: {"name":"CumCongNghiepDanhSach","layout":"master"},
    name: "congnghiep-cumcongnghiep"
  }, {
    path: "/congnghiep/khucongnghiep",
    component: _3ec5d349,
    meta: {"name":"KhuCongNghiepDanhSach","layout":"master"},
    name: "congnghiep-khucongnghiep"
  }, {
    path: "/dautu/cam-nang-dau-tu",
    component: _eb6297c2,
    meta: {"layout":"master"},
    name: "dautu-cam-nang-dau-tu"
  }, {
    path: "/dautu/camnangdautu",
    component: _5c1f5f30,
    meta: {"name":"Camnangdautu","layout":"master","notFooter":true},
    name: "dautu-camnangdautu"
  }, {
    path: "/dautu/duandautunoibat",
    component: _140adcee,
    meta: {"name":"DuAnDauTuNoiBat","layout":"master"},
    name: "dautu-duandautunoibat"
  }, {
    path: "/dautu/luachonnhadautu",
    component: _9d4b4224,
    meta: {"name":"LuaChonNhaDauTu","layout":"master"},
    name: "dautu-luachonnhadautu"
  }, {
    path: "/dautu/nghiencuudautu",
    component: _c0f8e942,
    meta: {"name":"NghienCuuDauTu","layout":"master"},
    name: "dautu-nghiencuudautu"
  }, {
    path: "/dautu/thuonghieudautu",
    component: _4de874e7,
    meta: {"name":"ThuongHieuDauTu","layout":"master","notFooter":true},
    name: "dautu-thuonghieudautu"
  }, {
    path: "/dautu/thuvienanhvideo",
    component: _30576d23,
    meta: {"layout":"master","name":"thuVienAnhVideo"},
    name: "dautu-thuvienanhvideo"
  }, {
    path: "/dautu/tiemnangvacohoi",
    component: _a05cd830,
    meta: {"name":"TiemNangVaCoHoiDauTu","layout":"master"},
    name: "dautu-tiemnangvacohoi"
  }, {
    path: "/dautu/trinhtuthutuc",
    component: _efb6b466,
    meta: {"name":"TrinhTuThuTuc","layout":"master"},
    name: "dautu-trinhtuthutuc"
  }, {
    path: "/dautu/trinhtuthutucluachonnhadautu",
    component: _583fd95e,
    meta: {"name":"TrinhTuThuTucLuaChonNhaDauTu","layout":"master"},
    name: "dautu-trinhtuthutucluachonnhadautu"
  }, {
    path: "/dautu/van-de-phap-ly",
    component: _407f60fb,
    meta: {"name":"VanDePhapLy","layout":"master"},
    name: "dautu-van-de-phap-ly"
  }, {
    path: "/error/error-404",
    component: _70b994ac,
    meta: {"name":"error-404","layout":"master"},
    name: "error-error-404"
  }, {
    path: "/gis/cumcongnghiep",
    component: _11d395da,
    meta: {"name":"MapCumCongNghiep","layout":"master","notFooter":true},
    name: "gis-cumcongnghiep"
  }, {
    path: "/gis/nghiencuudautu",
    component: _24a2ec8a,
    meta: {"name":"Map","layout":"master","notFooter":true},
    name: "gis-nghiencuudautu"
  }, {
    path: "/map/cum-cong-nghiep",
    component: _282109c4,
    meta: {"layout":"master","notFooter":true},
    name: "map-cum-cong-nghiep"
  }, {
    path: "/map/cum-hoac-khu-cong-nghiep",
    component: _03d54c53,
    meta: {"layout":"master","notFooter":true},
    name: "map-cum-hoac-khu-cong-nghiep"
  }, {
    path: "/map/district",
    component: _660e913b,
    meta: {"name":"Map","layout":"master"},
    name: "map-district"
  }, {
    path: "/map/map-leaflet",
    component: _327080ef,
    meta: {"layout":"master","notFooter":true},
    name: "map-map-leaflet"
  }, {
    path: "/tintuc/thong-tin-kinh-te-xa-hoi",
    component: _8d7a078c,
    meta: {"name":"ThongTinKinhTeXaHoi","layout":"master"},
    name: "tintuc-thong-tin-kinh-te-xa-hoi"
  }, {
    path: "/trangchu/demo",
    component: _63abb214,
    meta: {"name":"TrangChu","layout":"master","props":{"classBoxItem":{"default":""}},"notFooter":true,"styleHeader":"index"},
    name: "trangchu-demo"
  }, {
    path: "/trangchu/demo2",
    component: _119b3da8,
    meta: {"name":"TrangChu","layout":"master","props":{"classBoxItem":{"default":""}},"notFooter":true,"styleHeader":"index"},
    name: "trangchu-demo2"
  }, {
    path: "/uudai/capnuocsach",
    component: _567e7273,
    meta: {"name":"UuDaiCapNuocSach","layout":"master"},
    name: "uudai-capnuocsach"
  }, {
    path: "/uudai/chatthairan",
    component: _83f65384,
    meta: {"name":"uuDaiChatThaiRan","layout":"master"},
    name: "uudai-chatthairan"
  }, {
    path: "/uudai/hoptacxa",
    component: _4d105153,
    meta: {"name":"UuDaiHopTacXa","layout":"master"},
    name: "uudai-hoptacxa"
  }, {
    path: "/uudai/nganhnghe",
    component: _4b7a00cb,
    meta: {"name":"uuDaiNganhNghe","layout":"master"},
    name: "uudai-nganhnghe"
  }, {
    path: "/uudai/nongnghiepnongthon",
    component: _32a82ea9,
    meta: {"name":"UuDaiNongNghiepNongThon","layout":"master"},
    name: "uudai-nongnghiepnongthon"
  }, {
    path: "/uudai/xahoihoa",
    component: _54341e5e,
    meta: {"name":"UuDaiNongNghiepNongThon","layout":"master"},
    name: "uudai-xahoihoa"
  }, {
    path: "/admin/du-an/danh-sach",
    component: _c1437540,
    meta: {"name":"AdminDanhSachDuAn","layout":"master-admin"},
    name: "admin-du-an-danh-sach"
  }, {
    path: "/admin/thu-hut-trong-diem/danh-sach",
    component: _6c6871d2,
    meta: {"name":"DanhSachThuHutTrongDiem","layout":"master-admin"},
    name: "admin-thu-hut-trong-diem-danh-sach"
  }, {
    path: "/admin/thu-hut-trong-diem/them-moi",
    component: _51fdb7f8,
    meta: {"name":"ThemMoiThuHutTrongDiem","layout":"master-admin"},
    name: "admin-thu-hut-trong-diem-them-moi"
  }, {
    path: "/dautu/components/readmore",
    component: _17d5182a,
    meta: {"name":"readMore","props":{}},
    name: "dautu-components-readmore"
  }, {
    path: "/dautu/luachonnhadautu/chitiet",
    component: _665b8ab8,
    meta: {"name":"LuaChonNhaDauTuChiTiet","layout":"master"},
    name: "dautu-luachonnhadautu-chitiet"
  }, {
    path: "/dautu/thongtinquyhoach/quyhoachquangnam",
    component: _137f4472,
    meta: {"name":"QuyHoachQuangNam","layout":"master"},
    name: "dautu-thongtinquyhoach-quyhoachquangnam"
  }, {
    path: "/dautu/thongtinquyhoach/quyhoachvungdong",
    component: _ec9d1442,
    meta: {"name":"QuyHoachQuangNam","layout":"master"},
    name: "dautu-thongtinquyhoach-quyhoachvungdong"
  }, {
    path: "/dautu/thongtinquyhoach/quyhoachvungtinh",
    component: _6b26106c,
    meta: {"name":"QuyHoachQuangNam","layout":"master"},
    name: "dautu-thongtinquyhoach-quyhoachvungtinh"
  }, {
    path: "/dautu/thongtinquyhoach/thongtinchitiet",
    component: _22324d9a,
    meta: {"name":"Thongtinchitiet","layout":"master"},
    name: "dautu-thongtinquyhoach-thongtinchitiet"
  }, {
    path: "/admin/thu-hut-trong-diem/components/vitriduan",
    component: _2a5b548e,
    meta: {"props":{"dataTable":{},"column":{}}},
    name: "admin-thu-hut-trong-diem-components-vitriduan"
  }, {
    path: "/",
    component: _6ea8ada3,
    meta: {"name":"TrangChu","layout":"master","props":{"classBoxItem":{"default":""}},"notFooter":true,"styleHeader":"index"},
    name: "index"
  }, {
    path: "/dautu/nghiencuudautu/:id",
    component: _7eead987,
    meta: {"name":"NghienCuuDauTuChiTiet","layout":"master"},
    name: "dautu-nghiencuudautu-id"
  }, {
    path: "/du-an/:type?",
    component: _23176ecf,
    meta: {"name":"DuAn","layout":"master"},
    name: "du-an-type"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
