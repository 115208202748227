export const state = () => ({
    listJobs: {},
    listJobEngineer:{},
    detailJob:{}

})

export const getters = {
  getCurrentPage(state) {
      return state.listJobEngineer?.data?.listJob?.current_page;
  },
  getTotalPage(state) {
    return state.listJobEngineer?.data?.listJob?.last_page;
  },
  getListJobEngineer(state) {
    return state.listJobEngineer?.data?.listJob?.data;
},
};

export const actions = {

  async listJob({commit}) {
    try {
      const data = await this.$repositories.job.getJob()

      commit('LIST_JOB', data)
    } catch (e) {
      return e;
    }
  },

  async listJobEngineer({commit}, dataSearch) {
    try {
      const data = await this.$repositories.job.getJobAll(dataSearch)
      commit('LIST_JOB_ENGINEER', data)

      return data;

    } catch (e) {
      return e;
    }
  },

  async detailJob({commit},id){
    try {
      const data = await this.$repositories.job.detailJob(id)

      commit('DETAIL_JOB', data)

      return data;

    } catch (e) {
      return e;
    }
  }
}

export const mutations = {
  LIST_JOB(state, job) {
    state.listJobs = job
  },
  LIST_JOB_ENGINEER(state, data) {
    state.listJobEngineer = data
  },
  DETAIL_JOB(state, data) {
    state.detailJob = data
  },
}

