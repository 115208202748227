export default function ({ app, $axios, $api, error: nuxtError, redirect }) {
  $axios.onResponse(response => {
    if (response?.data?.data?.token) {
      $api.setToken(response?.data?.data?.token, 'Bearer')
    }
  });
  $axios.onError(error => {
    app.$auth.setToken('local', false)
      app.$auth.setRefreshToken('local', false);
      app.$auth.setUser('')
    redirect('/');
    return Promise.resolve(error);
  })
}