import { STATUS } from '@/constants/constants'

export default async  function  (context) {
  try {
    const params = context.route.query.token;
    if(params) {
      // eslint-disable-next-line no-use-before-define
      const token = {token: params};
      const data = await context.store.dispatch('engineer/check_token_change_email', token);

      if (data.code === STATUS.UNAUTHORIZED) {
        context.error({statusCode: data.code, message: data.message})
      }
    }
  }catch (e){
    context.redirect('/')
  }
}
