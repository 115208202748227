export default ($api) => (
  {
    duAns: (data) => {
      return $api.get(`du-ans`, { params: data })
    },
    thongKeDuAn: (data) => {
      return $api.get(`thong-ke-du-an`, { params: data })
    },
    mucLucDuAn: (data) => {
      return $api.get(`muc-luc-du-ans`, { params: data})
    },
    loaiDuAn: (data) => {
      return $api.get('loai-du-ans', { params: data})
    }
  })
