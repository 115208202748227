export const state = () => ({
  xLanguage: 'vi_VN',
})

export const getters = {
  xLanguage: (state) => {
    return state.xLanguage
  },
}

export const mutations = {
  SET_RESPONSE_HEADER(state, lang) {
    state.xLanguage = lang
  },
}

/* action */
export const actions = {
  getResponseHeader({ commit }, path) {
    try {
      const client = new XMLHttpRequest()
      client.open('GET', path, false)
      client.send(null)
      const headers = client.getAllResponseHeaders()
      const arr = headers.trim().split(/[\r\n]+/)
      const headerMap = {}
      arr.forEach(function (line) {
        const parts = line.split(': ')
        const header = parts.shift()
        const value = parts.join(': ')
        headerMap[header] = value
      })
      commit('SET_RESPONSE_HEADER', headerMap?.['x-language'] || 'vi_VN')
    } catch (e) {
      commit('SET_RESPONSE_HEADER', 'vi_VN')
    }
  },
}
