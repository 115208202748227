
import '@/assets/css/global.scss'
import compAdminHeader from '@/components/layouts/admin/header.vue'
import compAdminMenu from '@/components/layouts/admin/menu.vue'

export default {
  name: 'MasterAdmin',
  components: {
    compAdminHeader,
    compAdminMenu,
  },
  data() {
    return {
      collapse: false,
    }
  },
  methods: {
    toggleMenu() {
      this.collapse = !this.collapse
    },
    goAdmin() {
      this.$router.push('/admin')
    },
  },
}
