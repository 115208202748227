const VI_VN = {
  home: 'Trang chủ',
  name: 'Quảng Nam',
  slogan: 'Vùng đất giàu tiềm năng',
  gis: 'Gis dự án',
  tiemNang: 'Tiềm năng và cơ hội đầu tư',
  quyTrinh: 'Quy trình',
  dauTu: 'đầu tư',
  gisKhu: 'GIS khu, cụm công nghiệp',
  congNghiep: 'Công nghiệp',
  quyHoach: 'Quy hoạch',
  duAnThuHutDauTu: 'Dự án thu hút đầu tư',
  kinhTe: 'Kinh tế',
  XaHoi:'xã hội',
  kinhTeXaHoi: 'Kinh tế - Xã hội',
  uuDaiDauTu: 'Ưu đãi đầu tư',
  hoTro: 'Hỗ trợ',
  NhaDauTu: 'nhà đầu tư',
  soKeHoach: 'SỞ KẾ HOẠCH VÀ ĐẦU TƯ',
  phienBanThuNghiem: 'Phiên bản thử nghiệm',
  banQuyenSo: 'Bản quyền thuộc Sở kế hoạch và Đầu tư',
  trungTamXucTienDauTu: 'TRUNG TÂM XÚC TIẾN ĐẦU TƯ VÀ HỖ TRỢ KHỞI NGHIỆP',
  diaChi: 'Địa chỉ: 02 Trần Phú, Tam Kỳ, Quảng Nam',
  email: 'Email: htdn.skhdt@gmail.com',
  dienThoai: 'Điện thoại: 0235 247 4567',
  title: 'Cổng thông tin xúc tiến đầu tư',
  province: 'Tỉnh Quảng Nam',
  lienKetNhanh: 'Liên kết nhanh',
  menuGis: 'GIS dự án đầu tư',
  menuDANguyenCuu: 'Dự án nghiên cứu đầu tư',
  menuDAThuHut: 'Dự án thu hút đầu tư',
  menuGisKhu: 'GIS Khu, Cụm CN',
  menuDauTu: 'Đầu tư vào QN',
  menuTiemNang: 'Tiềm năng và cơ hội đầu tư',
  menuDanhMucDAThuHut: 'Danh mục dự án thu hút đầu tư',
  menuDMDALuaChon: 'Dự án công bố lựa chọn nhà đầu tư',
  menuDMDANghienCuu: 'Dự án nghiên cứu đầu tư',
  menuDMDAKeuGoi: 'Dự án kêu gọi đầu tư',
  DMDALuaChon: 'Danh mục dự án công bố lựa chọn nhà đầu tư',
  DMDANghienCuu: 'Danh mục dự án nghiên cứu đầu tư',
  DMDANghienCuuDeXuat: 'Danh mục dự án nghiên cứu đề xuất đầu tư',
  DMDAKeuGoi: 'Danh mục dự án kêu gọi đầu tư',
  menuQuyTrinh: 'Quy trình đầu tư',
  menuTrinhTu: 'Trình tự, thủ tục đầu tư',
  menuTrinhTuLuaChon: 'Trình tự, thủ tục lựa chọn nhà đầu tư',
  menuUuDai: 'Ưu đãi đầu tư',
  menuNganhNghe: 'Ngành nghề',
  menuNuocSach: 'Nước sạch',
  menuChatThaiRan: 'Chất thải rắn',
  menuXaHoiHoa: 'Xã hội hóa',
  menuHopTacXa: 'Hợp tác xã',
  menuNongNghiep: 'Nông nghiệp nông thôn',
  menuCacThuongHieu: 'Các thương hiệu đầu tư vào QN',
  menuHoTroNhaDauTu: 'Hỗ trợ nhà đầu tư',
  menuThuTuc: 'Thủ tục hành chính',
  menuMotCua: 'Một cửa điện tử',
  menuDichVuCong: 'Dịch vụ công',
  menuCongThongTinDienTuQN: 'Cổng thông tin điện tử tỉnh Quảng Nam',
  menuCongThongTinDienTuSoKHDTQN: 'Cổng thông tin điện tử Sở Kế hoạch và đầu tư Quảng Nam',
  menuCongThongTinDienTuBoKHDTQN: 'Cổng thông tin điện tử Bộ kế hoạch và đầu tư Quảng Nam',
  menuLienHe: 'Liên hệ',
  menuPhapLy: 'Vấn đề pháp lý',
  menuThongTinQuyHoach: 'Thông tin quy hoạch',
  menuThongTinKTXH: 'Thông tin KTXH',
  menuTaiLieuXucTien: 'Tài liệu xúc tiến đầu tư',
  menuCamNang: 'Cẩm nang đầu tư',
  menuThuVienAnh: 'Thư viện hình ảnh và video',
  menuGisKhuCN: 'GIS Khu công nghiệp',
  menuGisCumCN: 'GIS Cụm công nghiệp',
  menuKhuCN: 'Thông tin Khu công nghiệp',
  menuCumCN: 'Thông tin Cụm công nghiệp',
  diaDiem: 'Địa điểm',
  dienTich: 'Diện tích',
  viTriDiaLy: 'Vị trí địa lý',
  mucTieu: 'Mục tiêu',
  thongTinDuAn: 'Thông tin dự án',
  hienTrangSuDungDat: 'Hiện trạng sử dụng đất',
  danCu: 'Dân cư',
  loiTheDauTu: 'Những lợi thế khi đầu tư',
  suPhuHopVoiHoSoQuyHoach: 'Sự phù hợp với các hồ sơ quy hoạch, định hướng phát triển kinh tế - xã hội của ngành, địa phương',
  hieuQua: 'Hiệu quả của dự án',
  ghiChu: 'Ghi chú',
  duAn: 'Dự án',
  loaiBanDo: 'Loại bản đồ',
  loaiNen: 'Loại nền',
  banDo: 'Bản đồ',
  veTinh: 'Vệ tinh',
  thuongMai: 'Thương mại',
  thoiGian: 'Thời gian',
  khoangCach: 'Khoảng cách',
  thongTinKhuCongNghiep: 'Thông tin KCN',
  cumCongNghiep: 'Thông tin CCN',
  giskhuCongNghiep: 'GIS KCN',
  gisCumCongNghiep: 'GIS CCN',
  banQuyen: '© 2022 Bản quyền thuộc về Sở Kế hoạch và Đầu tư tỉnh Quảng Nam',

  // Tiềm năng và cơ hội
  tiemNangVaCoHoi: 'Tiềm năng và Cơ hội đầu tư',
  gioiThieuQuangNam: 'Quảng Nam nằm ở trung tâm vùng kinh tế trọng điểm miền Trung Việt Nam; là một trong các đầu mối về giao thông vận tải, giao thương và giao lưu quốc tế quan trọng của khu vực miền Trung và Tây nguyên',
  dienTichQuangNam: '10,438 km2',
  danSo: 'Khoảng 1,5 triệu',
  danSoChu: 'Dân số',
  donViHanhChinh: '18 đơn vị hành chính cấp huyện',
  donViHanhChinhChiTiet: 'gồm 2 thành phố, 1 thị xã và 15 huyện. Tỉnh lỵ của Quảng Nam đặt tại thành phố Tam Kỳ.',
  // Tiềm năng và cơ hội - Vị trí địa lý
  vitriDiaLy: 'Vị trí địa lý,',
  dieuKienTuNhien: 'điều kiện tự nhiên',
  cuaNgo: 'Cửa ngõ kết nối ra Biển Đông của Đông Tây, Nam Lào, Đông Bắc Thái Lan, Camphuchia.',
  khoangCachThuDo: 'Cách thủ đô Hà Nội 820 km về phía Bắc, cách thành phố Hồ Chí Minh 900 km về phía Nam theo đường Quốc lộ 1A.',
  giapTinhThanh: 'Phía Bắc giáp thành phố Đà Nẵng và tỉnh Thừa Thiên - Huế, phía Nam giáp tỉnh Quảng Ngãi và tỉnh Kon Tum, phía Tây giáp biên giới Lào, tỉnh Sekong (Cộng hòa Dân chủ Nhân dân Lào), phía Đông giáp Biển Đông.',
  gioBay: 'Trong 4 - 5 giờ bay sẽ tiếp cận đến tất cả các vùng kinh tế năng động nhất khu vực Châu Á như Hàn Quốc, Trung Quốc, Nhật Bản.',
  // Tiềm năng và cơ hội - Hạ tầng
  haTang: 'Hạ tầng',
  sanBayChuLai: 'Có sân bay Chu Lai được quy hoạch thành Cảng hàng không quốc tế cấp 4F, cảng Chu Lai đang được quy hoạch tiếp nhận tàu 50.000 tấn; gần sân bay quốc tế Đà Nẵng, cảng Tiên Sa - Đà Nẵng (tiếp nhận tàu 50.000 tấn)',
  tuyenBacNam: 'Tuyến Bắc Nam: QL 1A, Cao tốc Đà Nẵng - Quảng Ngãi; đường ven biển Hội An - Chu Lai; đường sắt Bắc Nam',
  tuyenDongTay: 'Tuyến Đông Tây: QL 14B, 14E. 14G, 40B, ĐT.617',
  dienNuocVienThong: 'Điện, nước, viễn thông: được đầu tư hiện đại, tới ranh giới dự án',
  // Tiềm năng và cơ hội - Danh lam
  danhLam: 'Danh lam thắng cảnh',
  boBien: 'Bờ biển dài 125 km, ven biển có nhiều bãi tắm đẹp, nổi tiếng, như: Hà My, An Bàng (Điện Bàn), Cửa Đại (Hội An), Bình Minh (Thăng Bình), Tam Thanh (Tam Kỳ), Bãi Rạng (Núi Thành),...',
  diSanVanHoa: 'Có 2 Di sản Văn hóa thế giới Hội An, Mỹ Sơn và Khu Dự trữ sinh quyển thế giới Cù Lao Chàm - Hội An',
  diSanPhiVatThe: 'Có 1 Di sản văn hóa phi vật thể đại diện của nhân loại là Bài Chòi và 9 Di sản văn hóa phi vật thể đại diện quốc gia',
  diTichLichSu: 'Với hơn 300 di tích lịch sử, văn hoá, danh thắng, nhiều làng nghề truyền thống, lễ hội văn hoá dân gian có lịch sử trên 500 năm',
  hoPhuNinh: 'Có Hồ Phú Ninh được mệnh danh là Vịnh Hạ Long thu nhỏ với quần thể 30 đảo lớn nhỏ như: Đảo Rùa, Ông Khỉ, Đảo Su, ….mỗi hòn đảo đều được trồng rất nhiều cây xanh, tạo nên một khung cảnh thiên nhiên hùng vĩ',
  heThongSongLon: 'Có nhiều hệ thống sông lớn như sông Vu Gia, sông Cổ Cò, sông Thu Bồn, sông Trường Giang….tạo mối liên kết giữa các địa phương và thúc đẩy phát triển du lịch ven sông',
  datQuangNam: 'Quảng Nam hiện có 680.249,67ha đất có rừng, gồm 463.356,77ha rừng tự nhiên và 216.829,94ha rừng trồng, độ che phủ rừng toàn tỉnh đạt 58,61%, trong đó độ che phủ rừng tự nhiên đạt 43,82%. Rừng có nhiều dược liệu quý hiếm như Sâm Ngọc Linh, Quế Trà My, Ba Kích, Đẳng Sâm,….',
  monAnNgon: 'Có nhiều món ăn nổi tiếng: Mỳ Quảng, bê thui cầu Mống, Cao lầu, bánh ít lá gai, cơm gà… là đặc sản Quảng Nam trong top 50 món ngon Việt Nam.',
  soLuongLaoDong: 'Hơn 900 nghìn người trong độ tuổi lao động (khoảng 60% dân số)',
  truongDaiHocCaoDang: '9 trường đại học và cao đẳng',
  truongTrungCap: '4 trường trung cấp',
  trungTamGiaoDuc: '17 trung tâm giáo dục nghề nghiệp và cơ sở đào tạo nghề.',
  nguonNhanLuc: 'Nguồn nhân lực chất lượng cao',
  khuKTvaKhuCN: 'Khu Kinh tế và Khu Công nghiệp',
  cacKhuKT: 'Các Khu Kinh tế và Khu công nghiệp tỉnh Quảng Nam với tổng diện tích tự nhiên khoảng 60.000 ha, hoạt động đa ngành, đa lĩnh vực; một trong những hạt nhân, trung tâm phát triển lớn của vùng kinh tế trọng điểm miền Trung, bao gồm: khu phi thuế quan gắn với sân bay Chu Lai; khu phi thuế quan gắn với khu bến cảng; các khu cảng và logistic; các Khu công nghiệp, khu đô thị, khu du lịch và khu kinh tế cửa khẩu quốc tế Nam Giang. Trong đó:',
  khuKTChuLai: 'Khu Kinh tế mở Chu Lai',
  khuKTChuLaiChiTiet: 'Khu Kinh tế mở Chu Lai với tổng diện tích tự nhiên là 27.040 ha, là trung tâm công nghiệp ô tô và công nghiệp phụ trợ ngành ô tô, hàng không; trung tâm khí - điện và sản phẩm hóa dầu; công nghiệp dệt may và phụ trợ ngành dệt may, các loại hình công nghiệp khác ứng dụng công nghệ cao và có hàm lượng chất xám cao; trung tâm sản xuất nông nghiệp hữu cơ, nông nghiệp ứng dụng công nghệ cao; khu phi thuế quan và logistic gắn với việc khai thác sân bay Chu Lai và hệ thống cảng Chu Lai - Kỳ Hà; có các khu du lịch, dịch vụ tập trung với tổng diện tích khoảng 2.475 ha; có tổng diện tích đất phát triển đô thị khoảng 10.205 ha bao gồm Khu đô thị Núi Thành, Tam Anh, Đông Tam Kỳ, Đông Nam Thăng Bình,…',
  khuKTNamGiang: 'Khu kinh tế cửa khẩu Quốc tế Nam Giang',
  khuKTNamGiangChiTiet: 'Khu kinh tế cửa khẩu quốc tế Nam Giang nằm trên tuyến hành lang kinh tế Đà Nẵng - Nam Lào và Đông bắc Thái Lan. Định hướng phát triển là khu vực thương mại dịch vụ, logictis, khu phi thuế quan kết hợp phát triển nông, lâm nghiệp, du lịch, … với tổng diện tích tự nhiên 31.060 ha; gắn liền với tuyến đường QL 14D, là trục kết nối khu vực với các đầu mối giao thông liên vùng.',
  cacKhuCN: 'Các Khu công nghiệp',
  cacKhuCNChiTiet: 'Trên địa bàn tỉnh Quảng Nam có 18 khu công nghiệp với tổng diện tích 4.702,65ha; trong đó có 11 khu công nghiệp thuộc Khu kinh tế mở Chu Lai đã được cấp phép với diện tích là 2.959,89 ha (diện tích đất công nghiệp còn lại là 1990,11ha), 03 kkhu công nghiệp nằm ngoài Khu kinh tế mở Chu Lai đã được cấp phép với tổng diện tích 716,76 ha (diện tích đất công nghiệp còn lại là 435,24ha) và có 04 KCN đang xin chủ trường đầu tư tổng diện tích là 1.026ha. Tỷ lệ lấp đầy đối với các KCN đang hoạt động là 42%. Định hướng thu hút đầu tư: Ưu tiên thu hút các dự án có công nghệ tiên tiến, công nghệ mới, công nghệ cao, quản trị hiện đại, có giá trị gia tăng cao, có tác động lan tỏa kết nối chuỗi sản xuất và cung ứng toàn cầu.',
  cacKhuCnHoatDong: 'Các Khu công nghiệp đã đi vào hoạt động',
  khuCNChuLai: 'Khu công nghiệp cơ khí ô tô Chu Lai - Trường Hải - 243, 3ha',
  khuCNDienNam: 'Khu công nghiệp Điện Nam - Điện Ngọc - 357,08ha',
  khuCNTamThang: 'Khu công nghiệp Tam Thăng - 197,1ha',
  khuCNBacChuLaiDienTich: 'Khu công nghiệp Bắc Chu Lai - 361,4ha',
  khuCNBenCang: 'Khu công nghiệp cảng và hậu cần cảng Chu Lai - Trường Hải - 142,33ha',
  khuCNTamHiep: 'Khu công nghiệp và hậu cần cảng Tam Hiệp - 417ha',
  khuCNDongQueSon: 'Khu công nghiệp Đông Quế Sơn - 211,26ha',
  khuCNThuanYen: 'Khu công nghiệp Thuận Yên - 148,42ha',
  khuCNTamThang2: 'Khu công nghiệp Tam Thăng 2 - 103ha',
  khuCNTamAnh: 'Khu công nghiệp Tam Anh - Hàn Quốc - 193,05ha',
  cacKNCDangTrienKhai: 'Các khu công nghiệp đang triển khai đầu tư:',
  khuCNDTKThaco: 'Khu công nghiệp Thaco - Chu Lai - 415,86ha',
  khuCNDTKTamAnh: 'Khu công nghiệp Tam Anh - An An Hòa - 435,8ha',
  khuCNDTKTamAnh1: 'Khu công nghiệp Tam Anh 1 - 167,05ha',
  khuCNDTKTamThang: 'Khu công nghiệp Tam Thăng mở rộng - 248ha.',
  cacKCNDangXinChuTruong: 'Các Khu công nghiệp đang xin chủ trương đầu tư',
  khuCNDXCTNamThangBinh: 'Khu công nghiệp Nam Thăng Bình - dự kiến khoảng 499ha',
  khuCNDXCTBacThangBinh: 'Khu công nghiệp Bắc Thăng Bình - dự kiến khoảng 239ha',
  khuCNDXCTPhuXuan: 'Khu công nghiệp Phú Xuân - dự kiến khoảng 108ha',
  khuCNDXCTTamAnh3: 'Khu công nghiệp Tam Anh 3 - dự kiến khoảng 190ha',
  cacKCN: 'Các khu công nghiệp',
  cacKCNChiTiet: 'Hiện nay địa bàn tỉnh quy hoạch 93 CCN với tổng diện tích 2.759,82 ha.  Đã có quyết định thành lập 59 CCN với tổng diện tích 1.678,58 ha, diện tích đất công nghiệp 1.223,12 ha; trong đó có 51 CCN đã được thành lập, được QHCT và đi vào hoạt động với tổng diện tích đất công nghiệp đã cho thuê 493,85ha, diện tích còn lại chưa cho thuê 577,58ha; tỷ lệ lấp đầy đạt 48,28% (493,85ha/1.022,79ha).',
  dinhHuongThuHutDauTu: 'Định hướng thu hút đầu tư',
  dinhHuongDauTu: 'Các CCN đã đầu tư và đi vào hoạt động: định hướng tiếp tục thu hút các nhà đầu tư thứ cấp nhằm lấp đầy 100% CCN.',
  dinhHuongDauTuXayDung: 'Các CCN chưa thực hiện đầu tư xây dựng: định hướng thu hút doanh nghiệp làm chủ đầu tư kinh doanh hạ tầng kỹ thuật.',
  dinhHuongPhatTrien: 'ĐỊNH HƯỚNG PHÁT TRIỂN, THU HÚT ĐẦU TƯ',
  vaoQuangNam: 'VÀO QUẢNG NAM',
  dinhHuongPhatTrienChiTiet1: 'Những năm qua, Quảng Nam trở thành điểm sáng về thu hút đầu tư ở miền Trung nói riêng và cả nước nói chung, khi dòng vốn đầu tư của doanh nghiệp trong nước và nước ngoài lần lượt tiếp cận với quy mô ngày càng lớn. Đây là tiền đề tạo động lực phát triển to lớn cho tỉnh trong giai đoạn tới, góp phần đảm bảo phát triển nhanh và bền vững, đặc biệt trong bối cảnh thích ứng với dịch bệnh và các điều kiện kinh tế - xã hội mới.',
  dinhHuongPhatTrienChiTiet2: 'Hiện nay, Quảng Nam tập trung thu hút, xúc tiến các ngành, lĩnh vực mà tỉnh có ưu thế, gắn kết với các địa phương lân cận vùng kinh tế trọng điểm miền Trung và đạt được lợi ích lớn từ việc hội nhập kinh tế quốc tế, đặc biệt là thu hút đầu tư vào Khu Kinh tế mở Chu Lai và Khu Kinh tế cửa khẩu Nam Giang nhằm khai thác tiềm năng và thế mạnh của Hành lang Kinh tế Đông Tây. Kết hợp hoạt động xúc tiến đầu tư với các hoạt động xúc tiến thương mại, du lịch và các chương trình tuyên truyền đối ngoại nhằm tận dụng hiệu quả các nguồn lực, thời cơ để quảng bá, xúc tiến đầu tư. Chú trọng các hoạt động đối thoại hằng tháng với doanh nghiệp, nhà đầu tư để kịp thời giải quyết khó khăn, vướng mắc, đề xuất, kiến nghị của nhà đầu tư. Tích cực hỗ trợ các dự án, nhất là các dự án trọng điểm có quy mô lớn, mang tính động lực để tạo sức lan tỏa cho các dự án khác cùng phát triển.',
  veCongNghiep: 'Về công nghiệp',
  veCongnghiepChiTiet: 'Để khai thác tiềm năng phát triển công nghiệp, Quảng Nam đã và đang quy hoạch, định hướng đầu tư sản xuất theo cụm ngành với công nghệ hiện đại, công nghiệp xanh ít sử dụng lao động; định hướng hình thành hệ thống khu công nghiệp sinh thái, trên cơ sở các tiêu chí, quy định của Chính phủ và bộ tiêu chí riêng của Quảng Nam về công nghiệp sinh thái; phát triển mạnh ngành công nghiệp ô tô, ngành công nghiệp hàng không để sớm hình thành trung tâm cơ khí đa dụng miền Trung, bên cạnh đó là các cụm ngành công nghiệp điện khí, điện tử, công nghiệp phụ trợ ngành dệt may, công nghiệp thực phẩm, đồ uống. Thu hút đầu tư vào các khu công nghiệp phải là những ngành nghề tiên tiến, đóng góp ngân sách nhiều, hiệu quả cao trên một đơn vị diện tích sử dụng đất. Các ngành khai thác, chế biến khoáng sản phải đổi mới công nghệ, chế biến sâu, không làm tổn hại môi trường; kiên quyết từ chối thu hút các dự án có nguy cơ gây ô nhiễm môi trường cao, khai thác lãng phí nguồn tài nguyên, công nghệ lạc hậu.',
  veDuLich: 'Về du lịch, dịch vụ',
  veDuLichChiTiet1: 'Quảng Nam có lợi thế và tiềm năng phát triển to lớn xuất phát từ vị trí địa lý, điều kiện tự nhiên, các di sản văn hóa và tinh hoa, truyền thống, con người đất Quảng; với hơn 300 di tích lịch sử, văn hoá, danh thắng, nhiều làng nghề truyền thống, lễ hội văn hoá dân gian có lịch sử trên 500 năm, đặc biệt có 02 di sản Di sản văn hóa thế giới Phố cổ Hội An, Thánh địa Mỹ Sơn và Khu Dự trữ sinh quyển thế giới Cù lao Chàm; bên cạnh đó là 125 km bờ biển kéo dài từ Điện Ngọc đến giáp vịnh Dung Quất, nhiều bãi tắm hoang sơ và sạch đẹp. Có thể nói du lịch Quảng Nam có sự tổng hòa của những yếu tố địa lý của vùng lục địa hải đảo là núi - đồi - châu thổ - biển. Vì vậy định hướng phát triển du lịch của Quảng Nam là du lịch xanh; xây dựng Quảng Nam trở thành trung tâm dịch vụ du lịch của miền Trung và cả nước; xây dựng thương hiệu, sản phẩm dịch vụ du lịch tầm quốc gia và quốc tế; chú trọng phát triển du lịch về phía Nam và phía Tây của tỉnh; từng bước chuyển đổi số trong ngành du lịch, phát triển đa dạng hóa thị trường khách du lịch, trong đó phát triển mạnh thị trường khách du lịch nội địa, hợp tác liên kết vùng, đảm bảo thích ứng với những tiêu cực từ các cuộc khủng hoảng kinh tế hoặc dịch bệnh toàn cầu.',
  veDuLichChiTiet2: 'Đối với dịch vụ, ưu tiên phát triển các ngành dịch vụ du lịch, y tế, chăm sóc sức khỏe, đào tạo nghề, logisctics; hình thành các khu phi thuế quan, các sàn giao dịch mang tầm cỡ quốc tế; phấn đấu đưa Quảng Nam trở thành trung tâm đào tạo nghề chất lượng cao của khu vực miền Trung. Đặc biệt, với vị trí địa lý và điều kiện tự nhiên thuận lợi, Quảng Nam có cơ hội rất lớn để phát triển mạnh dịch vụ vận tải cả về đường bộ, đường sắt, đường biển, đường sông và đường không. Với đặc điểm hội tụ riêng có của mình, Chu Lai hoàn toàn có thể hình thành một trung tâm vận tải đa phương thức trọng điểm của quốc gia.',
  veNongNghiep: 'Về nông nghiệp',
  veNongNghiepChiTiet1: 'Quảng Nam không có điều kiện tự nhiên phù hợp để phát triển các loại cây lương thực và cây công nghiệp quy mô lớn theo phương thức truyền thống, nuôi trồng thủy, hải sản gần bờ; ngoài ra mặc dù tỉnh sở hữu nhiều loại sản vật quý, đặc biệt là các loại dược liệu có giá trị cao, tuy nhiên ngoại trừ sâm Ngọc Linh được đánh giá là tương đối thành công và có chiều hướng phát triển tốt khi thu hút nhiều doanh nghiệp đầu tư, thì hầu hết đặc sản khác dẫu có tiếng vẫn chưa được đầu tư tương xứng. Chương trình OCOP - mỗi xã một sản phẩm có được hiệu ứng khá tốt, tuy nhiên để trở thành những sản phẩm cho chiến lược kinh tế phát triển vẫn còn một quãng đường khá dài.',
  veNongNghiepChiTiet2: 'Vì vậy, định hướng phù hợp và hiệu quả nhất đối với nông nghiệp Quảng Nam là phát triển nông nghiệp công nghệ cao (cả trồng trọt, chăn nuôi, lâm nghiệp, thủy sản), phát triển liên kết chuỗi giá trị sản xuất - chế biến - tiêu thụ bền vững; đảm bảo thích ứng với biến đổi khí hậu; có chính sách thu hút mạnh mẽ các doanh nghiệp đầu tư vào sản xuất, chế biến sâu sản phẩm nông nghiệp để làm nòng cốt tổ chức lại sản xuất trên cơ sở tạo mối liên kết bền chặt, hiệu quả với các hợp tác xã và hộ nông dân, hình thành các vùng chuyên canh lớn, các trang trại chăn nuôi quy mô công nghiệp, bán công nghiệp; chuyển đổi mạnh rừng trồng gỗ nhỏ sang rừng trồng gỗ lớn, cây ăn trái và một số loại cây dược liệu chủ lực, gắn với chế biến sâu để tạo giá trị gia tăng lớn; giảm tỉ trọng nuôi trồng thủy sản, tăng tỉ trọng đánh bắt xa bờ gắn với chế biến sâu và hạ tầng nghề cá đồng bộ. Khuyến khích và hỗ trợ phát triển vượt bậc về hoạt động khởi nghiệp gắn với đẩy mạnh sản xuất các sản phẩm OCOP đạt chất lượng cao và qui mô lớn để cung cấp cho các thị trường trọng điểm trong nước.',

  // Ngành nghề
  doiTuongApDung: 'Đối tượng áp dụng',
  duAnDauTu: 'Dự án đầu tư thuộc ngành, nghề ưu đãi đầu tư',
  nganhNghe: 'Ngành, nghề',
  nganhNgheDacBietUuDai: 'Ngành nghề đặc biệt ưu đãi',
  phuLuc: '(Phụ Lục II, Nghị định số 31/2021/NĐ-CP Quy định chi tiết và hướng dẫn thi hành một số điều của Luật đầu tư)',
  duAnDTCacDiaBanUuDai: 'Dự án đầu tư tại các địa bàn ưu đãi đầu tư',
  diaBanKhoKhan: 'Địa bàn có điều kiện kinh tế - xã hội khó khăn.',
  diaBanDacBietKhoKhan: 'Địa bàn có điều kiện kinh tế - xã hội đặc biệt khó khăn.',
  khuKinhTe: 'Khu kinh tế, khu công nghệ cao, khu công nghiệp, khu chế xuất.',
  chinhSachUuDaiDauTu: 'CHÍNH SÁCH ƯU ĐÃI ĐẦU TƯ VÀO KCN, KKT',
  uuDaiThueThuNhap: 'Ưu đãi thuế thu nhập doanh nghiệp',
  soUuDaiThueThuNhap: '(Các NĐ số 218/2013/NĐ- CP, 91/2014/NĐ-CP, 12/2015/NĐ-CP, QĐ số 29/2021/QĐ-TTG)',
  thueThuNhapDoanhNghiep: 'Thuế thu nhập doanh nghiệp',
  linhVucDiaBan: 'Lĩnh vực, địa bàn đầu tư',
  thueThuNhap: 'Thuế thu nhập doanh nghiệp',
  thueSuat: 'Thuế suất',
  thoiGianMienGiam: 'Thời gian miễn, giảm',
  diaBanKTXHKhoKhan: 'Địa bàn có điều kiện kinh tế - xã hội khó khăn',
  muoiNam: '17% (10 Năm)',
  mienGiam: 'Miễn 2 năm và giảm 50% trong 4 năm tiếp theo',
  diaBanKTKhoKhan: 'Địa bàn có điều kiện kinh tế - xã hội đặc biệt khó khăn',
  muoiLamNam: '10% (15 năm)',
  mienBonNam: 'Miễn 4 năm, giảm 50% cho 9 năm tiếp theo',
  uuDaiThueNhapKhau: 'Ưu đãi thuế nhập khẩu',
  soUuDaiThueNhapKhau: '(NĐ số 134/2016/NĐ-CP)',
  hangHoaNhapKhau: 'Hàng hóa nhập khẩu để tạo tài sản cố định của một dự án đầu tư có phần dự án thuộc đối tượng ưu đãi đầu tư được miễn thuế nhập khẩu đối với hàng hóa nhập khẩu được phân bổ, hạch toán riêng để sử dụng trực tiếp cho phần dự án thuộc đối tượng ưu đãi đầu tư.',
  duAnDauTuKhoKhan: 'Dự án đầu tư nằm ở địa bàn có điều kiện kinh tế xã hội đặc biệt khó khăn được hưởng ưu đãi miễn thuế nhập khẩu đối với nguyên liệu, vật tư, linh kiện trong nước chưa sản xuất được nhập khẩu để sản xuất của dự án trong thời hạn 05 năm, kể từ ngày bắt đầu sản xuất.',
  uuDaiDatDai: 'Ưu đãi đất đai',
  soUuDauDatDai: '(NĐ số 135/2016/NĐ-CP)',
  duAnDauTuHaTangKCN1: 'Dự án đầu tư hạ tầng KCN, khu chức năng trong KKT được miễn tiền thuê đất sau thời gian xây dựng cơ bản 15 năm nếu đầu tư tại địa bàn cấp huyện có điều kiện kinh tế xã hội khó khăn;',
  duAnDauTuHaTangKCN2: 'Dự án đầu tư hạ tầng KCN, khu chức năng trong KKT được miễn tiền thuê đất sau thời gian xây dựng cơ bản toàn bộ thời gian thuê đất nếu đầu tư tại địa bàn cấp huyện có điều kiện kinh tế xã hội đặc biệt khó khăn.',
  hoTroNhaNuoc: 'Hỗ trợ của nhà nước về Ưu đãi tín dụng',
  soHoSoHoTroNhaNuoc: '(NĐ số 32/2017/NĐ-CP)',
  duAnDauTuNhaNuoc: 'Dự án đầu tư tổ chức thực hiện tại địa bàn kinh tế xã hội khó khăn, đặc biệt khó khăn thuộc đối tượng cho vay tín dụng đầu tư của Nhà nước',
  chinhSachDauTuKKT: 'CHÍNH SÁCH ƯU ĐÃI ĐẦU TƯ VÀO KKT, KCN TẠI QUẢNG NAM',
  duAnDauTuKinhDoanh: 'Dự án đầu tư kinh doanh kết cấu hạ tầng KCN',
  coSoPhapLy: 'Cơ sở pháp lý',
  nghiDinh1: 'Nghị định số 46/2014/NĐ-CP ngày 15/5/2014',
  nghiDinh2: 'Nghị định 135/2016/NĐ-CP ngày 09/9/2016',
  nghiDinh3: 'Nghị định 35/2017/NĐ-CP ngày 03/4/2017 (đối với kcn trong kkt)',
  diaBanKhuKTXHKhoKhan: 'Địa bàn KTXH đặc biệt khó khăn',
  mienThueDat: 'Miễn tiền thuê đất trong thời gian xây dựng cơ bản (tối đa không quá 03 năm kể từ ngày có quyết định cho thuê đất của cơ quan nhà nước có thẩm quyền).',
  mienToanBo: 'Miễn toàn bộ thời gian thuê đất',
  diaBanKTXHKhoKhan2: 'Địa bàn KTXH khó khăn',
  mienTienThueDat: 'Miễn tiền thuê đất 11 năm sau thời gian miễn tiền thuê đất trong thời gian xây dựng cơ bản',
  mienTienThue15Nam: 'Miễn tiền thuê đất 15 năm sau thời gian miễn tiền thuê đất trong thời gian xây dựng cơ bản',
  khongThucDiaBanDauTu: 'Không thuộc địa bàn ưu đãi đầu tư',
  danhMucDiaBanUuDaiDauTu: 'Danh mục địa bàn ưu đãi đầu tư (khó khăn, đặc biệt khó khăn) theo quy định tại Nghị định 31/NĐ-CP ngày 26/3/2021',
  duAnThuCap: 'Dự án thứ cấp tại các KCN',
  nhom: 'Nhóm',
  khuCongNghiep: 'Khu công nghiệp',
  cumCongNghiepTag: 'Cụm công nghiệp',
  uuDai: 'ƯU ĐÃI',
  khuCNBacChuLai: 'KCN Bắc Chu Lai',
  uuDaiThueDoanhNghiep: 'Ưu đãi về thuế thu nhập doanh nghiệp',
  uuDaiThueDoanhNghiepChiTiet1: 'Thuế suất 10% trong thời gian 15 năm (có thể được kéo dài đến tối đa 30 năm khi được Thủ tướng Chính phủ cho phép đối với dự án đặc biệt thu hút đầu tư, có quy mô lớn, công nghệ cao),',
  uuDaiThueDoanhNghiepChiTiet2: 'Miễn thuế 4 năm, giảm 50% số thuế phải nộp trong 9 năm.',
  uuDaiThueNhapKhauChiTiet1: 'Miễn thuế nhập khẩu đối với hàng hóa nhập khẩu để tạo tài sản cố định.',
  uuDaiThueNhapKhauChiTiet2: 'Miễn thuế nhập khẩu đối với nguyên liệu, vật tư, linh kiện trong thời hạn 05 năm.',
  khongCo: 'Không có',
  kcnOToChuLaiTruongHai: 'KCN cơ khí ô tô Chu Lai Trường Hải',
  kcnCangTamHiep: 'KCN cảng và hậu cần cảng Tam Hiệp',
  kcnTamAnhHanQuoc: 'KCN Tam Anh Hàn Quốc',
  kcnThaCoChuLai: 'KCN Thaco Chu Lai',
  kcnTamAnhAnHoa: 'KCN Tam Anh An An Hòa',
  kcnTamAnh1: 'KCN Tam Anh 1',
  kcnTamThang: 'KCN Tam Thăng',
  uuDaiThueThuNhapDoanhNghiep: 'Ưu đãi về thuế thu nhập doanh nghiệp: Miễn thuế 2 năm, giảm 50% trong 4 năm (thuế suất 20%)',
  uuDaiThueNhapKhauChiTiet: 'Ưu đãi về thuế nhập khẩu: Miễn thuế nhập khẩu đối với hàng hóa nhập khẩu để tạo tài sản cố định',
  uuDaiVeDatDai: 'Ưu đãi về đất đai',
  kcnTamThang2: 'KCN Tam Thăng 2',
  kcnTamThangMoRong: 'KCN Tam Thăng mở rộng',
  kcnDienNgoc: 'KCN Điện Nam Điện Ngọc',
  kcnQueSon: 'KCN Đông Quế Sơn',
  kcnThuanYen: 'KCN Thuận Yên',
  kcnThuanYenChiTiet1: 'Miễn tiền thuê đất trong thời gian xây dựng cơ bản kể từ khi được giao đất, tối đa là 3 năm.',
  kcnThuanYenChiTiet2: 'Ba (3) năm đối với dự án thuộc Danh mục lĩnh vực ưu đãi đầu tư.',
  kcnThuanYenChiTiet3: 'Mười một (11) năm đối với dự án đầu tư  thuộc Danh mục lĩnh vực đặc biệt ưu đãi đầu tư',
  chuDauTu: 'Chủ đầu tư',
  linhVucDauTu: 'Lĩnh vực đầu tư',
  tongDienTich: 'Tổng diện tích',
  dienTichChoThue: 'Diện tích cho thuê',

  // TRÌNH TỰ THỬ TỤC ĐẦU TƯ
  trinhTuThuTucDauTuTTitle: 'TRÌNH TỰ, THỦ TỤC ĐẦU TƯ TẠI QUẢNG NAM',
  titleCacBuocTTTTDauTu: 'Các bước thực hiện dự án đầu tư',
  hinhAnhBuocThucHien: 'buoc-thuc-hien.png',
  titleTTTTDauTu: [
    'I. Quy định chung',
    'II. Dự án đầu tư thuộc thẩm quyền chấp thuận chủ trương đầu tư của UBND tỉnh'
  ],
  subTitleQDChung: [
    '1. Đối tượng áp dụng',
    '2. Cơ quan đăng ký, hướng dẫn, hỗ trợ, giám sát và đánh giá đầu tư',
    '3. Hình thức lựa chọn nhà đầu tư',
    '4. Các bước thực hiện dự án đầu tư'
  ],
  dSDoiTuongTTTTDauTu: [
    'Nhà đầu tư thực hiện dự án đầu tư tại Quảng Nam',
    'Các cơ quan nhà nước có liên quan đến hoạt động đầu tư của các nhà đầu tư tại Quảng Nam',
    'Cá nhân, tổ chức khác có liên quan',
    'Các dự án đầu tư PPP'
  ],
  coQuanDauTu: [
    'a. Cơ quan đăng ký đầu tư:',
    'b. Cơ quan hướng dẫn, hỗ trợ, giám sát và đánh giá đầu tư:'
  ],
  dSCoQuanDKDauTu: [
    'Sở Kế hoạch và Đầu tư',
    'Ban Quản lý các KKT và KCN tỉnh Quảng Nam'
  ],
  dSCoQuanHuongDanTTTTDauTu: [
    {
      tenCQ: 'Sở Kế hoạch và Đầu tư',
      motaCQ: 'Cơ quan đầu mối, tổng hợp trong việc hướng dẫn, hỗ trợ, giám sát và đánh giá hoạt động đầu tư.'
    },
    {
      tenCQ: 'Ban Quản lý các KKT và KCN tỉnh Quảng Nam',
      motaCQ: 'Hướng dẫn, hỗ trợ, giám sát và đánh giá hoạt động đầu tư tại các khu kinh tế và các khu công nghiệp trên địa bàn tỉnh.'
    },
    {
      tenCQ: 'Các Sở, ngành',
      motaCQ: 'Hướng dẫn, hỗ trợ, giám sát và đánh giá hoạt động đầu tư theo chức năng, nhiệm vụ quản lý Nhà nước của ngành.'
    },
    {
      tenCQ: 'UBND cấp huyện',
      motaCQ: 'Hướng dẫn, hỗ trợ, giám sát và đánh giá hoạt động đầu tư trên địa bàn quản lý.'
    }
  ],
  dSHinhThucLuaChonNhaDT: [
    'Đấu giá quyền sử dụng đất theo quy định của pháp luật về đất đai.',
    'Đấu thầu lựa chọn nhà đầu tư theo quy định của pháp luật về đấu thầu.',
    'Lựa chọn nhà đầu tư theo tiêu chí.',
    'Chấp thuận chủ trương đầu tư đồng thời chấp thuận nhà đầu tư.'
  ],
  motaCacBuocThucHienDADT: 'Dự án đầu tư của nhà đầu tư thực hiện trên địa bàn tỉnh Quảng Nam được triển khai thực hiện theo các bước sau:',
  dSCacBuocThucHienDADT: [
    'Lập, phê duyệt danh mục dự án đầu tư hoặc thỏa thuận nguyên tắc địa điểm đầu tư.',
    'Chấp thuận chủ trương đầu tư, lựa chọn nhà đầu tư đối với dự án có đề nghị Nhà nước giao đất, cho thuê đất, cho phép chuyển mục đích sử dụng đất.',
    'Giấy chứng nhận đăng ký đầu tư (nếu có).',
    'Thực hiện thủ tục bảo đảm thực hiện dự án đầu tư (nếu có).',
    'Thẩm định, phê duyệt quy hoạch chi tiết xây dựng, công bố quy hoạch, thỏa thuận đấu nối dự án vào hệ thống đường bộ (nếu có).',
    'Thực hiện thủ tục về môi trường.',
    'Thông báo thu hồi đất, quyết định thu hồi đất, thực hiện công tác giải phóng mặt bằng.',
    'Thẩm định thiết kế cơ sở, chấp thuận thiết kế đấu nối hạ tầng liên quan.',
    'Thực hiện thủ tục về đất đai.',
    'Thẩm định, thiết kế bản vẽ thi công, giấy phép xây dựng, giấy phép thi công trong hành lang đường bộ, giấy phép đấu nối vào hệ thống giao thông (nếu có).',
    'Triển khai xây dựng, nghiệm thu đưa công trình vào hoạt động theo quy định của pháp luật (nếu có).'
  ],
  subTitleDADTTinh: [
    '1. Dự án thuộc thẩm quyền chấp thuận chủ trương đầu tư của UBND tỉnh',
    '2. Cấp Giấy chứng nhận đăng ký đầu tư',
    '3. Bảo đảm thực hiện dự án đầu tư',
    '4. Dự án thuộc thẩm quyền chấp thuận chủ trương đầu tư của Quốc hội',
    '5. Dự án thuộc thẩm quyền chấp thuận chủ trương đầu tư của Thủ tướng Chính phủ'
  ],
  dSDuAnThuocTinh: [
    'Dự án đầu tư có đề nghị Nhà nước giao đất, cho thuê đất không thông qua đấu giá quyền sử dụng đất, không thông qua đấu thầu lựa chọn nhà đầu tư.',
    'Dự án đầu tư có đề nghị Nhà nước giao đất, cho thuê đất của hộ gia đình, cá nhân thuộc diện phải có văn bản chấp thuận của UBND tỉnh theo quy định của pháp luật về đất đai.',
    'Dự án đầu tư có đề nghị Nhà nước giao đất, cho thuê đất không thuộc trường hợp nhận chuyển nhượng quyền sử dụng đất, tài sản gắn liền với đất.',
    'Dự án đầu tư có đề nghị chuyển mục đích sử dụng đất thuộc diện phải có văn bản cho phép chuyển mục đích sử dụng đất của cơ quan Nhà nước có thẩm quyền theo quy định của pháp luật về đất đai, trừ trường hợp chuyển mục đích sử dụng đất của hộ gia đình, cá nhân không thuộc diện chấp thuận của UBND tỉnh theo quy định của pháp luật về đất đai.',
    'Dự án đầu tư xây dựng nhà ở (để bán, cho thuê, cho thuê mua), khu đô thị trong các trường hợp: dự án đầu tư có quy mô sử dụng đất dưới 50 ha và có quy mô dân số dưới 15.000 người tại khu vực đô thị; dự án đầu tư có quy mô sử dụng đất dưới 100 ha và có quy mô dân số dưới 10.000 người tại khu vực không phải là đô thị; dự án đầu tư không phân biệt quy mô diện tích đất, dân số thuộc khu vực hạn chế phát triển hoặc nội đô lịch sử (được xác định trong đồ án quy hoạch đô thị) của đô thị loại đặc biệt.',
    'Dự án đầu tư xây dựng và kinh doanh sân gôn (golf).',
    'Dự án của nhà đầu tư nước ngoài, tổ chức kinh tế có vốn đầu tư nước ngoài có đề nghị Nhà nước giao đất, cho thuê đất, cho phép chuyển mục đích sử dụng đất tại đảo và xã, phường, thị trấn biên giới; xã, phường, thị trấn ven biển; khu vực khác có ảnh hưởng đến quốc phòng, an ninh được xác định theo quy định tại khoản 8 Điều 2 và điểm đ khoản 2 Điều 98 Nghị định số 31/2021/NĐ-CP ngày 26/3/2021 của Chính phủ hoặc ý kiến của Bộ Quốc phòng, Bộ Công an theo quy định tại điểm đ khoản 2 Điều 98 Nghị định số 31/2021/NĐ-CP ngày 26/3/2021 của Chính phủ.',
  ],
  capGiayCNDKDT: [
    '2.1. Các trường hợp thực hiện thủ tục cấp Giấy chứng nhận đăng ký đầu tư:',
    '2.2. Trình tự thực hiện thủ tục cấp Giấy chứng nhận đăng ký đầu tư'
  ],
  dSTHCapGiayCNDKDT: [
    'Dự án đầu tư của nhà đầu tư nước ngoài và tổ chức kinh tế có vốn đầu tư nước ngoài quy định tại khoản 1 Điều 23 Luật Đầu tư có đề nghị Nhà nước giao đất, cho thuê đất, cho phép chuyển mục đích sử dụng đất đã được cấp có thẩm quyền chấp thuận chủ trương đầu tư.',
    'Dự án đầu tư của nhà đầu tư nước ngoài và tổ chức kinh tế có vốn đầu tư nước ngoài quy định tại khoản 1 Điều 23 Luật Đầu tư không đề nghị Nhà nước giao đất, cho thuê đất, cho phép chuyển mục đích sử dụng đất (thuê mặt bằng, thuê địa điểm của tổ chức, cá nhân).',
    'Dự án của nhà đầu tư trong nước, nhà đầu tư nước ngoài và tổ chức kinh tế có vốn đầu tư nước ngoài quy định tại khoản 1 Điều 23 Luật Đầu tư thực hiện trong các khu công nghiệp, cụm công nghiệp đã có nhà đầu tư xây dựng, kinh doanh hạ tầng.',
    'Dự án của nhà đầu tư trong nước đã được Nhà nước giao đất, cho thuê đất mà nhà đầu tư có nhu cầu được cấp Giấy chứng nhận đăng ký đầu tư.',
    'Các trường hợp khác phải thực hiện thủ tục cấp Giấy chứng nhận đăng ký đầu tư theo quy định của pháp luật.',
  ],
  trinhTuTHTTCapGiayCNDKDT: [
    'a) Đối với trường hợp quy định tại điểm a mục 2.1:',
    'b) Đối với trường hợp quy định tại điểm b, c, d và đ mục 2.1, nhà đầu tư nộp hồ sơ đề nghị cấp Giấy chứng nhận đăng ký đầu tư theo quy định tại khoản 1 Điều 33 Luật Đầu tư cho cơ quan đăng ký đầu tư; trong thời hạn 15 ngày làm việc kể từ ngày cơ quan đăng ký đầu tư nhận được hồ sơ hợp lệ của nhà đầu tư, cơ quan đăng ký đầu tư cấp Giấy chứng nhận đăng ký đầu tư.'
  ],
  dSTrinhTuTHTTCapGiayTaiDiemA: [
    'Đối với dự án đầu tư được chấp thuận chủ trương đầu tư đồng thời với chấp thuận nhà đầu tư, căn cứ Quyết định chấp thuận chủ trương đầu tư đồng thời chấp thuận nhà đầu tư, cơ quan đăng ký đầu tư cấp Giấy chứng nhận đăng ký đầu tư trong thời hạn 05 ngày làm việc kể từ ngày nhận được Quyết định chấp thuận chủ trương đầu tư đồng thời chấp thuận nhà đầu tư.',
    'Đối với dự án đầu tư đã được chấp thuận nhà đầu tư, dự án mà nhà đầu tư đã trúng đấu giá, trúng đấu thầu, nhà đầu tư nộp văn bản đề nghị cấp Giấy chứng nhận đăng ký đầu tư theo mẫu A.I.6 Phụ lục ban hành kèm theo Thông tư số 03/2021/TT-BKHĐT ngày 09/4/2021 của Bộ trưởng Bộ Kế hoạch và Đầu tư cho cơ quan đăng ký đầu tư; trong thời hạn 05 ngày làm việc kể từ ngày nhận được văn bản đề nghị của nhà đầu tư, cơ quan đăng ký đầu tư cấp Giấy chứng nhận đăng ký đầu tư.',
    'Đối với dự án đầu tư thuộc trường hợp Ban Quản lý các Khu kinh tế và Khu công nghiệp tỉnh Quảng Nam chấp thuận nhà đầu tư, Ban Quản lý các Khu kinh tế và Khu công nghiệp tỉnh Quảng Nam quyết định chấp thuận nhà đầu tư đồng thời với cấp Giấy chứng nhận đăng ký đầu tư.'
  ],
  dSBaoDamTHDADT: [
    'Các trường hợp phải thực hiện thủ tục bảo đảm thực hiện dự án đầu tư quy định tại khoản 1 Điều 43 Luật Đầu tư.',
    'Thủ tục bảo đảm thực hiện dự án đầu tư thực hiện theo quy định tại Điều 26 Nghị định số 31/2021/NĐ-CP ngày 26/3/2021 của Chính phủ.'
  ],
  dSLoaiDADTQuocHoi: [
    'a) Dự án đầu tư ảnh hưởng lớn đến môi trường hoặc tiềm ẩn khả năng ảnh hưởng nghiêm trọng đến môi trường, bao gồm:',
    'b) Dự án đầu tư có yêu cầu chuyển mục đích sử dụng đất trồng lúa nước từ 02 vụ trở lên với quy mô từ 500 ha trở lên.',
    'c) Dự án đầu tư có yêu cầu di dân tái định cư từ 20.000 người trở lên ở miền núi, từ 50.000 người trở lên ở vùng khác.',
    'd) Dự án đầu tư có yêu cầu áp dụng cơ chế, chính sách đặc biệt cần được Quốc hội quyết định.'
  ],
  dSDADTQuocHoiLoaiMot: [
    'Nhà máy điện hạt nhân.',
    'Dự án đầu tư có yêu cầu chuyển mục đích sử dụng đất rừng đặc dụng, rừng phòng hộ đầu nguồn, rừng phòng hộ biên giới từ 50 ha trở lên; rừng phòng hộ chắn gió, chắn cát bay và rừng phòng hộ chắn sóng, lấn biển từ 500 ha trở lên; rừng sản xuất từ 1.000 ha trở lên.'
  ],
  moTaDADTChinhPhu: 'Trừ các dự án đầu tư thuộc thẩm quyền chấp thuận chủ trương đầu tư của Quốc hội, Thủ tướng Chính phủ chấp thuận chủ trương đầu tư đối với các dự án đầu tư sau đây:',
  dSLoaiDADTChinhPhu: [
    'a) Dự án đầu tư không phân biệt nguồn vốn thuộc một trong các trường hợp sau đây:',
    'b) Dự án đầu tư của nhà đầu tư nước ngoài trong lĩnh vực kinh doanh dịch vụ viễn thông có hạ tầng mạng, trồng rừng, xuất bản, báo chí.',
    'c) Dự án đầu tư đồng thời thuộc thẩm quyền chấp thuận chủ trương đầu tư của từ 02 Ủy ban nhân dân cấp tỉnh trở lên.',
    'd) Dự án đầu tư khác thuộc thẩm quyền chấp thuận chủ trương đầu tư hoặc quyết định đầu tư của Thủ tướng Chính phủ theo quy định của pháp luật.'
  ],
  dSDADTChinhPhuLoaiMot: [
    'Dự án đầu tư có yêu cầu di dân tái định cư từ 10.000 người trở lên ở miền núi, từ 20.000 người trở lên ở vùng khác.',
    'Dự án đầu tư xây dựng mới: cảng hàng không, sân bay; đường cất hạ cánh của cảng hàng không, sân bay; nhà ga hành khách của cảng hàng không quốc tế; nhà ga hàng hoá của cảng hàng không, sân bay có công suất từ 01 triệu tấn/năm trở lên.',
    'Dự án đầu tư mới kinh doanh vận chuyển hành khách bằng đường hàng không.',
    'Dự án đầu tư xây dựng mới: bến cảng, khu bến cảng thuộc cảng biển đặc biệt; bến cảng, khu bến cảng có quy mô vốn đầu tư từ 2.300 tỷ đồng trở lên thuộc cảng biển loại I.',
    'Dự án đầu tư chế biến dầu khí.',
    'Dự án đầu tư có kinh doanh đặt cược, ca-si-nô (casino), trừ kinh doanh trò chơi điện tử có thưởng dành cho người nước ngoài.',
    'Dự án đầu tư xây dựng nhà ở (để bán, cho thuê, cho thuê mua), khu đô thị trong các trường hợp: dự án đầu tư có quy mô sử dụng đất từ 50 ha trở lên hoặc có quy mô dưới 50 ha nhưng quy mô dân số từ 15.000 người trở lên tại khu vực đô thị; dự án đầu tư có quy mô sử dụng đất từ 100 ha trở lên hoặc có quy mô dưới 100 ha nhưng quy mô dân số từ 10.000 người trở lên tại khu vực không phải là đô thị; dự án đầu tư không phân biệt quy mô diện tích đất, dân số thuộc phạm vi bảo vệ của di tích được cấp có thẩm quyền công nhận là di tích quốc gia, di tích quốc gia đặc biệt.',
    'Dự án đầu tư xây dựng và kinh doanh kết cấu hạ tầng khu công nghiệp, khu chế xuất.',
  ],

  // XEM THÊM
  thuGonBtn: 'Thu gọn',
  xemThemBtn: 'Xem thêm',
  xemTatCaBtn: 'Xem tất cả',

  // TRÌNH TỰ, THỦ TỤC LỰA CHỌN ĐẦU TƯ
  mainTitleLCDT: 'Trình tự , thủ tục lựa chọn nhà đầu tư thực hiện dự án đầu tư trên địa bàn tỉnh quảng nam',
  mainSubTitleLCDT: 'Quyết định số 2132/QĐ-UBND ngày 30/07/2021 của UBND tỉnh Quảng Nam',
  menuLCDT: 'Nội dung chính',
  listMenuLCDT: [
    {
      id: 'duancosudungdat',
      ten: 'I. Dự án đầu tư có sử dụng đất'
    },
    {
      id: 'duantheoluat',
      ten: 'II. Dự án theo Luật chuyên ngành, pháp luật xã hội hoá'
    },
    {
      id: 'tieuchidautu',
      ten: 'III. Dự án lựa chọn nhà đầu tư theo tiêu chí'
    }
  ],
  subListMenuLCDT: [
    '1. Phạm vi điều chỉnh, đối tượng áp dụng',
    '2. Điều kiện xác định dự án',
    '3. Lập, phê duyệt và công bố danh mục dự án',
    '4. Chuẩn bị, nộp hồ sơ đăng ký thực hiện dự án',
    '3. Xây dựng, công bố nội dung kêu gọi đầu tư dự án và bộ tiêu chí lựa chọn nhà đầu tư'
  ],
  duAnCoSDDat: {
    listPhamViDCDT: {
      mota: 'Dự án đầu tư có sử dụng đất để xây dựng khu dân cư, khu đô thị; xây dựng công trình dân dụng có một hoặc nhiều công năng gồm: nhà ở thương mại; trụ sở văn phòng làm việc; công trình thương mại, dịch vụ (gọi tắt là "dự án đầu tư có sử dụng đất thuộc trường hợp Nhà nước thu hồi đất"), trong đó:',
      listPV: [
        `Nhà ở thương mại: là nhà ở được đầu tư xây dựng để bán, cho thuê, cho thuê mua theo cơ chế thị trường.`,
        `Trụ sở, văn phòng làm việc: các tòa nhà sử dụng làm trụ sở, văn phòng làm việc của các doanh nghiệp và các tổ chức, cá nhân khác; các tòa nhà sử dụng làm văn phòng kết hợp lưu trú.`,
        `Công trình thương mại: Trung tâm thương mại, siêu thị, chợ, cửa hàng; nhà hàng, cửa hàng ăn uống, giải khát và các công trình thương mại khác.`,
        `Công trình dịch vụ: khách sạn, nhà khách, nhà nghỉ; cơ sở nghỉ dưỡng; biệt thự lưu trú, căn hộ lưu trú và các cơ sở dịch vụ lưu trú khác.`
      ]
    },
    listDieuKienXDDA: {
      mota: 'Dự án đầu tư có sử dụng đất thuộc trường hợp Nhà nước thu hồi đất phải lựa chọn nhà đầu tư khi đáp ứng các điều kiện sau đây:',
      listDK: [
        'Thuộc các dự án quy định tại Mục 1 như trên mà không sử dụng nguồn vốn đầu tư công để xây dựng công trình. Dự án xây dựng khu dân cư, khu đô thị, nhà ở thương mại; trụ sở văn phòng làm việc; công trình thương mại, dịch vụ được phân loại theo quy định của pháp luật về xây dựng. Trường hợp dự án có nhiều công năng, dự án đầu tư có sử dụng đất được xác định căn cứ công trình chính của dự án hoặc công trình chính có cấp cao nhất trong trường hợp dự án có nhiều công trình chính theo quy định của pháp luật về xây dựng.',
        'Thuộc danh mục dự án cần thu hồi đất được duyệt theo quy định của pháp luật về đất đai, thuộc khu đất do Nhà nước đang quản lý, sử dụng mà sẽ được UBND cấp tỉnh hoặc Ban Quản lý các Khu kinh tế và Khu công nghiệp giao đất, cho thuê đất cho nhà đầu tư được chấp thuận.',
        'Thuộc chương trình, kế hoạch phát triển nhà ở theo quy định của pháp luật về nhà ở; chương trình phát triển đô thị (nếu có) theo quy định của pháp luật về phát triển đô thị.',
        'Phù hợp với kế hoạch phát triển kinh tế - xã hội, quy hoạch xây dựng có tỷ lệ 1/2.000 hoặc tỷ lệ 1/500 (nếu có) hoặc quy hoạch phân khu đô thị có tỷ lệ 1/2.000 hoặc tỷ lệ 1/5.000 theo quy định của pháp luật.',
        'Không đủ điều kiện để tổ chức thực hiện đấu giá quyền sử dụng đất theo quy định của pháp luật về đất đai.',
        'Không thuộc trường hợp quy định tại điểm b khoản 4 Điều 22 và Điều 26 của Luật Đấu thầu.'
      ]
    },
    listChuanBiHoSoDKTH: [
      'Nhà đầu tư chịu trách nhiệm chuẩn bị và nộp hồ sơ đăng ký thực hiện dự án trên Hệ thống mạng đấu thầu quốc gia căn cứ nội dung thông tin được công bố theo quy định tại Điểm c, Mục 3.3. Hồ sơ đăng ký thực hiện dự án của nhà đầu tư bao gồm: Văn bản đăng ký thực hiện dự án; hồ sơ về tư cách pháp lý, năng lực, kinh nghiệm của nhà đầu tư; các tài liệu khác có liên quan (nếu có).',
      'Thời gian tối thiểu để nhà đầu tư chuẩn bị hồ sơ năng lực, kinh nghiệm và đăng ký thực hiện dự án là 30 ngày kể từ ngày danh mục dự án được đăng tải. Hết thời hạn đăng ký thực hiện dự án, Sở Kế hoạch và Đầu tư hoặc Ban Quản lý các Khu kinh tế và Khu công nghiệp (đối với dự án thực hiện tại các địa bàn thuộc phạm vi quản lý của Ban Quản lý các Khu kinh tế và Khu công nghiệp) tổ chức đánh giá sơ bộ năng lực, kinh nghiệm của các nhà đầu tư đã nộp hồ sơ đăng ký thực hiện dự án trên Hệ thống mạng đấu thầu quốc gia.'
    ]
  },
  duAnTheoLuat: {
    listPhamViDCDT: {
      mota: 'Dự án không thuộc quy định tại Mục 1, phần I nhưng phải tổ chức lựa chọn nhà đầu tư theo quy định của luật chuyên ngành, pháp luật về xã hội hóa (gọi tắt là "dự án theo luật chuyên ngành, pháp luật xã hội hóa"'
    },
    listDieuKienXDDA: {
      listDK: [
        'Dự án theo quy định của luật chuyên ngành cần phải tổ chức đấu thầu lựa chọn nhà đầu tư.',
        'Dự án được hưởng chính sách khuyến khích xã hội hóa theo quy định tại Nghị định số 69/2008/NĐ-CP ngày 30/05/2008, Nghị định số 59/2014/NĐ-CP ngày 16/06/2014 của Chính phủ thuộc danh mục các loại hình, tiêu chí quy mô, tiêu chuẩn do Thủ tướng Chính phủ ban hành tại các Quyết định số 1466/QĐ-TTg ngày 10/10/2008, số 693/QĐ-TTg ngày 06/5/2013, số 1470/QĐ-TTg ngày 27/02/2016.'
      ]
    },
    listChuanBiHoSoDKTH: [
      '4.1. Căn cứ nội dung thông tin được công bố theo quy định tại Điểm b, Mục 3.3, nhà đầu tư chuẩn bị và nộp hồ sơ đăng ký thực hiện dự án trực tiếp tại Sở Kế hoạch và Đầu tư hoặc Ban Quản lý các Khu kinh tế và Khu công nghiệp (đối với dự án thuộc diện chấp thuận chủ trương đầu tư thực hiện tại các địa bàn thuộc phạm vi quản lý của Ban Quản lý các Khu kinh tế và Khu công nghiệp). Hồ sơ đăng ký thực hiện dự án của nhà đầu tư bao gồm: Văn bản đăng ký thực hiện dự án; hồ sơ về tư cách pháp lý, năng lực kinh nghiệm của nhà đầu tư; các tài liệu khác có liên quan (nếu có).',
      '4.2. Thời gian tối thiểu để nhà đầu tư chuẩn bị hồ sơ năng lực, kinh nghiệm và đăng ký thực hiện dự án là 30 ngày kể từ ngày danh mục dự án được đăng tải. Hết thời hạn đăng ký thực hiện dự án, Sở Kế hoạch và Đầu tư hoặc Ban Quản lý các Khu kinh tế và Khu công nghiệp (đối với dự án thuộc diện chấp thuận chủ trương đầu tư thực hiện tại các địa bàn thuộc phạm vi quản lý của Ban Quản lý các Khu kinh tế và Khu công nghiệp) tổ chức đánh giá sơ bộ năng lực, kinh nghiệm của các nhà đầu tư đã gửi hồ sơ đăng ký thực hiện dự án.',
    ],
  },
  tieuChiDauTu: {
    listPhamViDCDT: {
      mota: 'Dự án không thuộc quy định tại Mục 1, Phần I và Mục 1, Phần II nhưng phải tổ chức lựa chọn nhà đầu tư theo tiêu chí (gọi tắt là “dự án lựa chọn nhà đầu tư theo tiêu chí "), trừ các trường hợp sau đây:',
      listPV: [
        `Dự án đầu tư trong lĩnh vực nông nghiệp gồm: trồng, chăm sóc, nuôi dưỡng, bảo vệ và phát triển rừng; nuôi trồng, bảo quản nông, lâm, thủy sản; sản xuất, nhân và lai tạo giống cây trồng, giống vật nuôi, giống cây lâm nghiệp, giống thủy sản;`,
        `Dự án đầu tư vào các khu công nghiệp, khu chế xuất, khu phi thuế quan, cụm công nghiệp;`,
        `Dự án mà nhà đầu tư có quyền sử dụng đất đối với toàn bộ khu đất thực hiện dự án.`
      ]
    },
    listDieuKienXDDA: {
      listDK: [
        'Dự án không thuộc trường hợp phải đấu giá quyền sử dụng đất; dự án không thuộc trường hợp đấu thầu dự án đầu tư có sử dụng đất, đấu thầu theo quy định của luật chuyên ngành, pháp luật xã hội hóa.',
        'Dự án thực hiện trên khu đất không có hoặc có một phần đất do Nhà nước đang quản lý, sử dụng (trừ trường hợp nhà đầu tư có quyền sử dụng đất phù hợp với quy hoạch được duyệt).'
      ]
    },
    listChuanBiHoSoDKTH: [
      '4.1. Căn cứ nội dung thông tin được công bố theo quy định tại Điểm b Mục 2.2, Nhà đầu tư quan tâm chuẩn bị và nộp hồ sơ đề xuất dự án tới Sở Kế hoạch và Đầu tư hoặc Ban Quản lý các Khu kinh tế và Khu công nghiệp (01 bản gốc và 05 bản sao). Thời gian để nhà đầu tư chuẩn bị và nộp hồ sơ đề xuất dự án là 30 ngày kể từ ngày danh mục và thông tin dự án được đăng tải.',
      '4.2. Hồ sơ đề xuất thực hiện dự án của nhà đầu tư bao gồm: Văn bản đăng ký thực hiện dự án; đề xuất dự án; hồ sơ chứng minh về tư cách pháp lý, năng lực, kinh nghiệm của nhà đầu tư; các tài liệu, hồ sơ để đánh giá theo yêu cầu của bộ tiêu chí lựa chọn nhà đầu tư và các tài liệu khác có liên quan (nếu có).'
    ]
  },
  listLapPheDuyetDMDA: {
    deMucLoai1: [
      {
        ten: '3.1. Đối với dự án thuộc diện chấp thuận chủ trương đầu tư:',
        duAnCoSDDat: [
          'Quyết định chấp thuận chủ trương đầu tư đồng thời là văn bản Quyết định phê duyệt danh mục dự án.',
          'Căn cứ Quyết định chấp thuận chủ trương đầu tư của dự án, Sở Kế hoạch và Đầu tư phối hợp với các cơ quan có liên quan xác định yêu cầu sơ bộ về năng lực, kinh nghiệm của nhà đầu tư và phê duyệt bằng văn bản trước khi công bố danh mục dự án. Đối với dự án thực hiện tại các địa bàn thuộc phạm vi quản lý của Ban Quản lý các Khu kinh tế và Khu công nghiệp, Ban Quản lý các Khu kinh tế và Khu công nghiệp tổ chức xác định, phê duyệt yêu cầu sơ bộ về năng lực, kinh nghiệm của nhà đầu tư trước khi công bố danh mục dự án.'
        ],
        duAnTheoLuat: [
          'Quyết định chấp thuận chủ trương đầu tư đồng thời là văn bản Quyết định phê duyệt danh mục dự án.',
          'Căn cứ Quyết định chấp thuận chủ trương đầu tư dự án, Sở Kế hoạch và Đầu tư phối hợp với các cơ quan có liên quan xác định yêu cầu sơ bộ về năng lực, kinh nghiệm của nhà đầu tư và phê duyệt bằng văn bản trước khi công bố danh mục dự án. Đối với dự án thực hiện tại các địa bàn thuộc phạm vi quản lý của Ban Quản lý các Khu kinh tế và Khu công nghiệp, Ban Quản lý các Khu kinh tế và Khu công nghiệp tổ chức xác định, phê duyệt yêu cầu sơ bộ về năng lực, kinh nghiệm của nhà đầu tư trước khi công bố danh mục dự án.'
        ]
      },
      {
        ten: '3.2. Đối với dự án không thuộc diện chấp thuận chủ trương đầu tư:',
        duAnCoSDDat: [
          'a) Cơ quan chuyên môn thuộc UBND tỉnh, cơ quan trực thuộc UBND tỉnh, UBND cấp huyện lập đề xuất dự án đầu tư có sử dụng đất gửi Sở Kế hoạch và Đầu tư tổng hợp. Nội dung đề xuất bao gồm: tên dự án; mục tiêu đầu tư; quy mô đầu tư; sơ bộ tổng chi phí thực hiện dự án, vốn đầu tư; phương án huy động vốn; thời hạn, tiến độ đầu tư; thông tin về hiện trạng sử dụng đất tại địa điểm thực hiện dự án, dự kiến nhu cầu sử dụng đất (nếu có); sơ bộ chi phí bồi thường, giải phóng mặt bằng; mục đích sử dụng đất; các chỉ tiêu quy hoạch được duyệt; ngôn ngữ đăng tải danh mục dự án; phân tích hiệu quả kinh tế - xã hội của dự án, bảo đảm quốc phòng, an ninh, phát triển bền vững của dự án đầu tư và yêu cầu sơ bộ về năng lực, kinh nghiệm của nhà đầu tư',
          'b) Nhà đầu tư được đề xuất thực hiện dự án đầu tư có sử dụng đất ngoài danh mục dự án đầu tư có sử dụng đất được Chủ tịch UBND tỉnh phê duyệt. Nhà đầu tư gửi hồ sơ đề xuất dự án đến UBND cấp huyện nơi thực hiện dự án để tổ chức rà soát, lập đề xuất dự án theo các nội dung quy định tại điểm a khoản này gửi Sở Kế hoạch và Đầu tư tổng hợp.',
          'c) Phê duyệt danh mục dự án đầu tư có sử dụng đất:'
        ],
        pheDuyetDuAnDTCoSDDat: [
          'Trong thời hạn 30 ngày kể từ ngày nhận được đề xuất dự án quy định tại điểm a hoặc điểm b khoản này, Sở Kế hoạch và Đầu tư phối hợp với các cơ quan liên quan tổng hợp danh mục dự án; đánh giá việc đáp ứng đầy đủ điều kiện xác định dự án đầu tư có sử dụng đất quy định tại Mục 2; xác định yêu cầu sơ bộ về năng lực, kinh nghiệm của nhà đầu tư, báo cáo Chủ tịch UBND tỉnh. Trường hợp cần thiết, thời gian thực hiện có thể được gia hạn theo yêu cầu thực tế.',
          'Trong thời hạn 10 ngày kể từ ngày nhận được báo cáo của Sở Kế hoạch và Đầu tư, Chủ tịch UBND tỉnh xem xét, phê duyệt danh mục dự án đầu tư có sử dụng đất; trong đó bao gồm yêu cầu sơ bộ về năng lực, kinh nghiệm của nhà đầu tư.'
        ],
        duAnTheoLuat: [
          'Cơ quan chuyên môn thuộc UBND tỉnh, cơ quan trực thuộc UBND tỉnh, UBND cấp huyện lập đề xuất dự án theo luật chuyên ngành, pháp luật xã hội hóa gửi Sở Kế hoạch và Đầu tư tổng hợp. Nội dung đề xuất bao gồm: tên dự án; mục tiêu đầu tư; quy mô đầu tư; sơ bộ tổng chi phí thực hiện dự án; sơ bộ chi phí bồi thường, giải phóng mặt bằng; vốn đầu tư; phương án huy động vốn; thời hạn, tiến độ đầu tư; thông tin về hiện trạng sử dụng đất tại địa điểm thực hiện dự án, dự kiến nhu cầu sử dụng đất (nếu có); mục đích sử dụng đất; phân tích hiệu quả kinh tế - xã hội của dự án, bảo đảm quốc phòng, an ninh, phát triển bền vững của dự án đầu tư và yêu cầu sơ bộ về năng lực, kinh nghiệm của nhà đầu tư.',
          'Sở Kế hoạch và Đầu tư phối hợp với các cơ quan có liên quan tổng hợp danh mục dự án theo luật chuyên ngành, pháp luật xã hội hóa; đánh giá việc đáp ứng đầy đủ điều kiện xác định dự án theo luật chuyên ngành, pháp luật xã hội hóa quy định tại Mục 2; xác định yêu cầu sơ bộ về năng lực, kinh nghiệm của nhà đầu tư, báo cáo Chủ tịch UBND tỉnh.',
          'Trên cơ sở báo cáo của Sở Kế hoạch và Đầu tư, Chủ tịch UBND tỉnh xem xét, phê duyệt danh mục dự án theo luật chuyên ngành, pháp luật xã hội hóa; trong đó bao gồm yêu cầu sơ bộ về năng lực, kinh nghiệm của nhà đầu tư.'
        ]
      },
      {
        ten: '3.3. Công bố danh mục dự án:',
        duAnCoSDDat: [
          'a) Căn cứ Quyết định chấp thuận chủ trương đầu tư theo quy định của pháp luật về đầu tư hoặc danh mục dự án đầu tư có sử dụng đất được phê duyệt, Sở Kế hoạch và Đầu tư hoặc Ban Quản lý các Khu kinh tế và Khu công nghiệp (đối với dự án thực hiện tại các địa bàn thuộc phạm vi quản lý của Ban Quản lý các Khu kinh tế và Khu công nghiệp) công bố danh mục dự án theo quy định tại điểm c khoản 1 Điều 4 Nghị định số 25/2020/NĐ-CP không muộn hơn 07 ngày làm việc, kể từ ngày văn bản được ban hành.',
          'b) Đối với dự án có sơ bộ tổng chi phí thực hiện dự án (không bao gồm chi phí bồi thường, hỗ trợ, tái định cư, tiền sử dụng đất, tiền thuê đất) từ 800.000.000.000 đồng (Tám trăm tỷ đồng) trở lên, ngoài việc đăng tải thông tin quy định tại Điểm a, Mục 3.3, thông tin dự án phải được đăng tải với ngôn ngữ bằng tiếng Anh và tiếng Việt trên trang thông tin điện tử của UBND tỉnh. Quy định này không áp dụng đối với dự án thuộc ngành, nghề chưa được tiếp cận thị trường đối với nhà đầu tư nước ngoài theo quy định của pháp luật về đầu tư hoặc dự án có yêu cầu về bảo đảm quốc phòng, an ninh do Thủ tướng Chính phủ quyết định.',
          'c) Nội dung công bố thông tin:'
        ],
        noiDungCongBoTT: [
          'Tên dự án; mục tiêu đầu tư; quy mô đầu tư; sơ bộ tổng chi phí thực hiện dự án; vốn đầu tư; phương án huy động vốn; thời hạn, tiến độ đầu tư;',
          'Thông tin về hiện trạng sử dụng đất tại địa điểm thực hiện dự án, diện tích khu đất thực hiện dự án; mục đích sử dụng đất; các chỉ tiêu quy hoạch được duyệt;',
          'Yêu cầu sơ bộ về năng lực, kinh nghiệm của nhà đầu tư;',
          'Thời hạn để nhà đầu tư nộp hồ sơ đăng ký thực hiện dự án;',
          'Nhà đầu tư nộp hồ sơ đăng ký thực hiện dự án trên Hệ thống mạng đấu thầu quốc gia;',
          'Các thông tin khác (nếu cần thiết).'
        ],
        duAnTheoLuat: {
          mota: 'Căn cứ Quyết định chấp thuận chủ trương đầu tư theo quy định của pháp luật về đầu tư hoặc Danh mục dự án theo luật chuyên ngành, pháp luật xã hội hóa được phê duyệt, Sở Kế hoạch và Đầu tư hoặc Ban Quản lý các Khu kinh tế và Khu công nghiệp (đối với dự án thuộc diện chấp thuận chủ trương đầu tư thực hiện tại các địa bàn thuộc phạm vi quản lý của Ban Quản lý các Khu kinh tế và Khu công nghiệp) có trách nhiệm đăng tải thông tin dự án lên Hệ thống mạng đấu thầu quốc gia không muộn hơn 07 ngày làm việc, kể từ ngày danh mục dự án được phê duyệt.',
          noiDung: {
            ten: 'Nội dung công bố thông tin:',
            dSNoiDung: [
              'Tên dự án; mục tiêu đầu tư; quy mô đầu tư; sơ bộ tổng chi phí thực hiện dự án; sơ bộ chi phí bồi thường, giải phóng mặt bằng; vốn đầu tư; phương án huy động vốn; thời hạn, tiến độ đầu tư;',
              'Thông tin về hiện trạng sử dụng đất tại địa điểm thực hiện dự án, diện tích khu đất thực hiện dự án; mục đích sử dụng đất;',
              'Yêu cầu sơ bộ về năng lực, kinh nghiệm của nhà đầu tư;',
              'Thời hạn để nhà đầu tư nộp hồ sơ đăng ký thực hiện dự án;',
              'Địa chỉ, số điện thoại, số fax của Sở Kế hoạch và Đầu tư;',
              'Các thông tin khác (nếu cần thiết).'
            ]
          }
        }
      }
    ],
    deMucLoai2: [
      {
        ten: '3.1. Xây dựng nội dung kêu gọi đầu tư dự án và bộ tiêu chí lựa chọn nhà đầu tư:',
        deMucCon: [
          'a) Căn cứ quy hoạch, định hướng phát triển kinh tế - xã hội, nhu cầu thu hút đầu tư theo ngành, lĩnh vực, địa bàn quản lý và sự quan tâm của các nhà đầu tư (Nhà đầu tư được chủ động tiếp cận thông tin đề xuất nội dung kêu gọi đầu tư dự án); cơ quan chuyên môn, đơn vị trực thuộc UBND tỉnh, UBND cấp huyện xây dựng nội dung kêu gọi đầu tư dự án và bộ tiêu chí để lựa chọn nhà đầu tư theo nội dung quy định tại Điểm b,c Mục 3.1 này.',
          'b) Nội dung kêu gọi đầu tư dự án bao gồm các thông tin cơ bản: tên dự án; mục tiêu đầu tư; quy mô đầu tư; dự kiến tổng chi phí thực hiện dự án, vốn đầu tư; phương án huy động vốn; địa điểm, thời hạn, tiến độ đầu tư; thông tin về hiện trạng sử dụng đất, dự kiến nhu cầu sử dụng đất (nếu có); mục đích sử dụng đất; đề xuất hưởng ưu đãi đầu tư; phân tích hiệu quả kinh tế - xã hội của dự án, bảo đảm quốc phòng, an ninh, phát triển bền vững của dự án đầu tư, đánh giá sơ bộ tác động môi trường (nếu có) theo quy định của pháp luật về bảo vệ môi trường và bộ tiêu chí lựa chọn nhà đầu tư.',
          'c) Nội dung bộ tiêu chí lựa chọn nhà đầu tư, bao gồm:',
          'd) Trên cơ sở tổng hợp nội dung kêu gọi đầu tư dự án của các địa phương, đơn vị, Sở Kế hoạch và Đầu tư, Ban Quản lý các Khu kinh tế và Khu công nghiệp chủ trì phối hợp với cơ quan liên quan hoàn thiện nội dung kêu gọi đầu tư dự án và thực hiện:'
        ],
        dSNoiDung: [
          'Tiêu chí về điều kiện tiên quyết;',
          'Tiêu chí đánh giá theo thang điểm;',
          'Phương pháp đánh giá và lựa chọn nhà đầu tư.'
        ],
        tongHopNoiDung: [
          'Đối với dự án có ý nghĩa chính trị - xã hội quan trọng; dự án liên quan đến quốc phòng, an ninh, đối ngoại, tôn giáo, di tích lịch sử văn hóa cấp tỉnh và cấp quốc gia, bảo vệ rừng; dự án thủy điện; dự án có tác động lớn đến môi trường, cảnh quan và kinh tế xã hội; dự án thuộc khu vực hạn chế phát triển, sản xuất chất độc hại, chất nổ; hạ tầng khu kinh tế, khu công nghiệp, khu công nghệ cao; dự án sử dụng quỹ đất mà trong lòng đất có tài nguyên khoáng sản hoặc có ảnh hưởng đến an ninh chính trị, trật tự, an toàn xã hội; dự án có diện tích sử dụng đất từ 05 ha trở lên đối với khu vực đô thị, từ 10 ha trở lên đối với khu vực nông thôn; dự án phải bồi thường, giải phóng mặt bằng, tái định cư từ 100 hộ dân trở lên; Sở Kế hoạch và Đầu tư, Ban Quản lý các Khu kinh tế và Khu công nghiệp (đối với dự án thực hiện tại các địa bàn thuộc phạm vi quản lý của Ban Quản lý các Khu kinh tế và Khu công nghiệp) trình UBND tỉnh phê duyệt nội dung kêu gọi đầu tư dự án. Căn cứ quyết định phê duyệt nội dung kêu gọi đầu tư dự án của UBND tỉnh, Sở Kế hoạch và Đầu tư, Ban Quản lý các Khu kinh tế và Khu công nghiệp hoàn thiện và phê duyệt bộ tiêu chí lựa chọn nhà đầu tư bằng văn bản;',
          'Đối với những dự án còn lại; Sở Kế hoạch và Đầu tư, Ban Quản lý các Khu kinh tế và Khu công nghiệp phê duyệt nội dung kêu gọi đầu tư dự án và bộ tiêu chí lựa chọn nhà đầu tư bằng văn bản.'
        ]
      },
      {
        ten: '3.2. Công bố dự án và bộ tiêu chí lựa chọn nhà đầu tư:',
        deMucCon: [
          'a) Sở Kế hoạch và Đầu tư hoặc Ban Quản lý các Khu kinh tế và Khu công nghiệp chịu trách nhiệm công bố, đăng tải lên cổng thông tin điện tử của đơn vị nội dung kêu gọi đầu tư dự án và bộ tiêu chí lựa chọn nhà đầu tư.',
          'b) Nội dung công bố bao gồm các thông tin:'
        ],
        dSNoiDung: [
          'Tên dự án; mục tiêu đầu tư, quy mô đầu tư của dự án; sơ bộ tổng chi phí thực hiện dự án; tóm tắt các yêu cầu cơ bản của dự án; thời hạn, tiến độ đầu tư;',
          'Địa điểm thực hiện dự án, diện tích khu đất, mục đích sử dụng đất; hiện trạng khu đất;',
          'Bộ tiêu chí lựa chọn nhà đầu tư;',
          'Phương pháp đánh giá bộ tiêu chí;',
          'Thời hạn để nhà đầu tư nộp hồ sơ đăng ký thực hiện dự án;',
          'Địa chỉ, số điện thoại, số fax của Sở Kế hoạch và Đầu tư hoặc Ban Quản lý các Khu kinh tế và Khu công nghiệp.'
        ]
      }
    ]
  },

  // ƯU ĐÃI CẤP NƯỚC SẠCH
  tieuDeChinhCapNuocSach: 'Cơ chế khuyến khích, hỗ trợ đầu tư xây dựng các công trình cấp nước sạch tập trung trên địa bàn tỉnh giai đoạn 2021-2025',
  canCuCapNuocSach: 'Căn cứ quy định tại Nghị quyết số 30/2021/NQ-HĐND ngày 26/9/2021 của HĐND tỉnh và Quyết định số 2969/QĐ-UBND ngày 20/10/2021 của UBND tỉnh.',
  // Ưu đãi cấp nước sạch - Đề mục
  doiTuongCapNuocSach : 'I. Đối tượng áp dụng',
  dieuKienNguyenTacCNS: 'II. Điều kiện, nguyên tắc áp dụng ưu đãi',
  noiDungMucHoTroNhaDauTuCNS: 'III. Nội dung và mức hỗ trợ nhà đầu tư',
  // Ưu đãi cấp nước sạch - Đối tượng
  noiDungDoiTuongCNS: 'Các địa phương, doanh nghiệp, đơn vị sự nghiệp công lập, hợp tác xã, tổ hợp tác thực hiện các hoạt động đầu tư xây dựng các công trình cấp nước sạch tập trung trên địa bàn',
  giaiDoanCNS: 'GIAI ĐOẠN',
  namGiaiDoanCNS: '2021-2025',
  // Ưu đãi cấp nước sạch - Điều kiện
  dSDieuKienNguyenTacCNS: [
    'Các dự án đầu tư xây dựng công trình cấp nước sạch tập trung phải phù hợp với quy hoạch, kế hoạch cấp nước (ưu tiên các dự án đầu tư xây dựng nhà máy nước tại các khu vực chưa có nhà máy nước và phát triển mạng lưới cấp nước đấu nối vào hệ thống cấp nước hiện có), thuộc danh mục đầu tư được Ủy ban nhân dân tỉnh phê duyệt, Ủy ban nhân dân tỉnh quyết định chủ trương đầu tư hoặc thống nhất chủ trương đầu tư.',
    'Nhà đầu tư là doanh nghiệp, hợp tác xã, tổ hợp tác có phương án tổ chức sản xuất kinh doanh, cung cấp dịch vụ; có năng lực tài chính, năng lực quản lý, khai thác và cam kết cung cấp dịch vụ nước sạch phục vụ người dân trên địa bàn tỉnh theo dự án được Ủy ban nhân dân tỉnh quyết định chủ trương đầu tư.'
  ],
  // Ưu đãi cấp nước sạch - Nội dung
  quyDinhPhanVungCNS: {
    tieuDe: '1. Quy định phân vùng',
    dSNoiDung: [
      {
        loai: 'Vùng',
        ten: '01',
        noiDung: 'Gồm các xã thuộc thành phố Tam Kỳ, thành phố Hội An, thị xã Điện Bàn, huyện Núi Thành (trừ xã đảo, xã miền núi) và các thị trấn của các huyện đồng bằng.'
      },
      {
        loai: 'Vùng',
        ten: '02',
        noiDung: 'Gồm các xã thuộc các huyện: Quế Sơn, Đại Lộc, Duy Xuyên, Thăng Bình, Phú Ninh, trừ các xã đặc biệt khó khăn vùng bãi ngang ven biển.',
      },
      {
        loai: 'Vùng',
        ten: '03',
        noiDung: 'Gồm xã miền núi của huyện Núi Thành; các thị trấn, xã trung tâm 4 của các huyện miền núi và các xã còn lại (trừ các xã thuộc Vùng 4).'
      },
      {
        loai: 'Vùng',
        ten: '04',
        noiDung: 'Gồm các xã thuộc vùng đồng bào dân tộc thiểu số và miền núi, các xã đặc biệt khó khăn vùng bãi ngang ven biển, các xã biên giới và xã đảo.'
      },
    ]
  },
  mucHoTroCNS: {
    tieuDe: '2. Mức hỗ trợ nhà đầu tư',
    dSNoiDung: [
      'a) Hỗ trợ nguồn vốn đầu tư cho các doanh nghiệp được giao làm chủ đầu tư các dự án đầu tư xây dựng các công trình cấp nước sạch tập trung:',
      'b) Giải pháp hoàn trả ngân sách:'
    ]
  },
  // Ưu đãi cấp nước sạch - Nội dung -- Mức hỗ trợ CNS
  hoTroDoiVoiCongTrinhXDCNS: 'Đối với công trình xây dựng nhà máy, hệ thống xử lý nước sạch (ưu tiên xây dựng mới tại các khu vực chưa có nhà máy nước; nâng cấp, cải tạo các nhà máy nước thuộc tài sản nhà nước), mức hỗ trợ như sau:',
  bangLoaiHinhXDCNS: 'Loại hình xây dựng',
  bangMucHoTroCNS: 'Mức hỗ trợ đầu tư (triệu đồng/m3/ngày đêm)',
  bangLoaiVungHoTroCNS: [
    'Vùng 1',
    'Vùng 2',
    'Vùng 3',
    'Vùng 4'
  ],
  xayDungMoiCNS: [
    'Xây dựng mới',
    '1,5',
    '2,0',
    '2,5',
    '3,0'
  ],
  nangCapCaiTaoCNS: [
    'Nâng cấp, cải tạo',
    '1,0',
    '1,33',
    '1,67',
    '2,0'
  ],
  hoTroDoiVoiDuAnXDMoi: 'Đối với dự án xây dựng mới, mở rộng mạng lưới đường ống cấp nước sạch: Hỗ trợ đầu tư tùy thuộc vào từng vùng, từng loại đường ống và điều kiện kinh tế - xã hội địa phương theo bảng mức hỗ trợ đầu tư như sau:',
  bangVungCNS: 'Vùng',
  doiVoiDuongOng: [
    {
      ten: 'Đối với đường ống cấp I',
      noiDung: 'Hỗ trợ 100% chi phí đầu tư theo Bảng'
    },
    {
      ten: 'Đối với đường ống cấp II',
      noiDung: 'Hỗ trợ 100% chi phí đầu tư theo Bảng đối với các dự án đầu tư tại các địa phương thuộc vùng 3 và vùng 4; hỗ trợ 50% chi phí đầu tư theo Bảng đối với dự án đầu tư tại các địa phương thuộc vùng 1 và vùng 2.'
    },
    {
      ten: 'Đối với đường ống cấp III',
      noiDung: 'Hỗ trợ 100% chi phí đầu tư theo Bảng đối với các dự án đầu tư tại các địa phương thuộc vùng 3 và vùng 4.'
    },
    {
      ten: 'Hỗ trợ chi phí đấu nối hộ gia đình theo dự án được phê duyệt, với mức hỗ trợ như sau:',
      noiDung: 'Hỗ trợ 1,5 triệu đồng/đấu nối/hộ đối với các địa phương thuộc vùng 3 và vùng 4; hỗ trợ 1,0 triệu đồng/đấu nối/hộ đối với các địa phương thuộc vùng 1 và vùng 2.'
    }
  ],
  noiDungHoanTraNganSachCNS: 'Phần kinh phí hỗ trợ từ ngân sách Nhà nước để đầu tư xây dựng công trình cấp nước sạch tập trung tại các địa phương không thuộc địa bàn kinh tế - xã hội khó khăn, có khả năng thu đủ chi phí được hạch toán đầy đủ vào nguyên giá tài sản cố định và được hoàn trả ngân sách Nhà nước bằng khấu hao tài sản cố định theo quy định hiện hành, trừ chi phí đấu nối hộ gia đình.',

  // ƯU ĐÃI CHẤT THẢI RẮN
  tieuDeChinhChatThaiRan: 'Cơ chế khuyến khích đầu tư, hỗ trợ khu xử lý chất thải rắn sinh hoạt tập trung trên địa bàn tỉnh Quảng Nam giai đoạn 2020 – 2030',
  canCuChatThaiRan: 'Căn cứ quy định tại Nghị quyết số 01/2020/NQ-HĐND ngày 21/4/2020 của HĐND tỉnh, Quyết định số 1331/QĐ-UBND ngày 20/5/2020 của UBND tỉnh và văn bản số 42/VP-TH ngày 29/4/2020 của Văn phòng HĐND tỉnh.',
  // Ưu đãi chất thải rắn - Đề mục
  doiTuongChatThaiRan: 'I. Đối tượng áp dụng',
  dieuKienNguyenTacCTR: 'II. Điều kiện, nguyên tắc áp dụng ưu đãi',
  noiDungMucHoTroNhaDauTuCTR: 'III. Nội dung và mức hỗ trợ nhà đầu tư',
  // Ưu đãi chất thải rắn - Đối tượng
  noiDungDoiTuongCTR: [
    'Các tổ chức, cá nhân, doanh nghiệp, hợp tác xã trong và ngoài nước đảm bảo năng lực tài chính, kỹ thuật, công nghệ và kinh nghiệm tham gia đầu tư khu xử lý rác thải.',
    'Các xã, phường, thị trấn có khu xử lý rác thải và xã, phường, thị trấn giáp ranh khu xử lý rác thải.'
  ],
  // Ưu đãi chất thải rắn - Điều kiện
  veCongNgheXuLyCTR: 'Về công nghệ xử lý',
  dSCongNgheXuLyCTR: [
    'Áp dụng công nghệ chôn lấp hợp vệ sinh đối với khu xử lý rác thải có công suất tối đa 50 tấn/ngày đêm.',
    'Áp dụng công nghệ chôn lấp hợp vệ sinh, công nghệ đốt hoặc công nghệ tiên tiến khác đối với các khu xử lý rác thải có công suất trên 50 tấn/ngày đêm.'
  ],
  truongHopCoNhieuNhaDauTuCTR: 'Trường hợp có nhiều nhà đầu tư đáp ứng các điều kiện theo quy định thì ưu tiên hỗ trợ nhà đầu tư áp dụng công nghệ tiên tiến, có kinh nghiệm hơn.',
  phuongThucHoTroNhaDauTuCTR: 'Phương thức hỗ trợ đối với nhà đầu tư: Ngân sách nhà nước hỗ trợ sau khi hoàn thành các nội dung công việc và được cơ quan có thẩm quyền xác nhận hoặc căn cứ khối lượng nghiệm thu, quyết toán theo hồ sơ thiết kế, dự toán được cơ quan có thẩm quyền phê duyệt (hỗ trợ sau đầu tư).',
  altBgXuLyCTR: 'Ưu đãi doanh nghiệp',
  dSNoiDungMucHoTroCTR: [
    {
      thuTu: '01',
      mucHoTro: 'Hỗ trợ 100%',
      noiDung: 'Kinh phí thực hiện công tác bồi thường, hỗ trợ tái định cư cho các hộ dân theo quy định hiện hành của nhà nước để tạo mặt bằng sạch cho khu vực xây dựng khu xử lý rác thải (tính đến hàng rào dự án).'
    },
    {
      thuTu: '02',
      mucHoTro: 'Hỗ trợ 100%',
      noiDung: 'Kinh phí thực hiện công tác bồi thường, hỗ trợ tái định cư khu vực nhà ở cho các hộ dân trong phạm vi bán kính an toàn (vùng đệm) của khu xử lý rác thải (phạm vi bán kính 1.000 mét đối với trường hợp áp dụng công nghệ chôn lấp; bán kính 500 mét đối với trường hợp áp dụng công nghệ đốt hoặc công nghệ tiên tiến khác; trong đó bán kính được tính từ ranh giới hoặc hàng rào của dự án).'
    },
    {
      thuTu: '03',
      mucHoTro: 'Hỗ trợ 50%',
      noiDung: 'Kinh phí xây dựng các tuyến đường vào khu xử lý rác thải theo tiêu chuẩn đường đạt chuẩn nông thôn mới đối với khu xử lý cấp huyện; theo tiêu chuẩn đường ĐH đối với khu xử lý cấp tỉnh. Trong đó, ưu tiên chọn tuyến đường dẫn không đi qua khu dân cư hoặc có dân cư thưa thớt.'
    },
    {
      thuTu: '04',
      mucHoTro: 'Hỗ trợ 100%',
      noiDung: 'Kinh phí đầu tư xây dựng công trình cấp điện (tính đến hàng rào) khu xử lý rác thải.'
    }
  ],

  // ƯU ĐÃI HỢP TÁC XÃ
  tieuDeChinhHopTacXa: 'Cơ chế, chính sách hỗ trợ phát triển kinh tế tập thể, hợp tác xã tỉnh Quảng Nam giai đoạn 2021 – 2025',
  canCuHopTacXa: 'Căn cứ quy định tại Nghị quyết 25/2021/NQ-HĐND ngày 22/7/2021 của HĐND tỉnh và Quyết định số 2406/QĐ-UBND ngày 23/8/2021 của UBND tỉnh.',
  // Ưu đãi hợp tác xã - Đề mục
  doiTuongHTX: 'I. Đối tượng áp dụng',
  dieuKienNguyenTacHTX: 'II. Điều kiện, nguyên tắc áp dụng ưu đãi',
  noiDungMucHoTroNhaDauTuHTX: 'III. Nội dung và mức hỗ trợ nhà đầu tư',
  noiDungDoiTuongHTX: 'Hợp tác xã, liên hiệp hợp tác xã (trường hợp không có quy định riêng, sau đây gọi chung là hợp tác xã và viết tắt là HTX) đáp ứng các tiêu chí của từng nội dung hỗ trợ được quy định.',
  giaiDoanHTX: 'GIAI ĐOẠN',
  // Ưu đãi hợp tác xã - Điều kiện
  noiDungDieuKienApDungHTX: 'HTX tổ chức hoạt động theo quy định của Luật HTX năm 2012; HTX chấp hành nghiêm chế độ thông tin, báo cáo; phân loại, đánh giá HTX theo quy định của pháp luật; HTX có nhu cầu được hỗ trợ, đáp ứng các tiêu chí của từng nội dung hỗ trợ được quy định.',
  vePhanChiaDiaBanHTX: 'Về phân chia địa bàn:',
  tenCotBangPCDBHTX: [
    'Khu vực',
    'Mức hỗ trợ đầu tư (triệu đồng/m3/ngày đêm)'
  ],
  noiDungBangPCDBHTX: [
    {
      ten: 'Khu vực miền núi cao',
      noiDung: 'Huyện Bắc Trà My, huyện Nam Trà My, huyện Nam Giang, huyện Tây Giang, huyện Đông Giang và huyện Phước Sơn'
    },
    {
      ten: 'Khu vực miền núi thấp',
      noiDung: 'Huyện Tiên Phước, huyện Hiệp Đức và huyện Nông Sơn'
    },
    {
      ten: 'Khu vực đồng bằng',
      noiDung: 'Thành phố Tam Kỳ, thành phố Hội An, thị xã Điện Bàn, huyện Duy Xuyên, huyện Quế Sơn, huyện Thăng Bình, huyện Đại Lộc, huyện Núi Thành và huyện Phú Ninh'
    }
  ],
  altBgHopTacXa: 'Ưu đãi đầu tư',
  // Ưu đãi Hợp tác xã - Nội dung
  dSHoTroHTX: [
    {
      idHoTro: 'ht1',
      tieuDe: '1. Hỗ trợ từ ngân sách Trung ương hỗ trợ đầu tư kết cấu hạ tầng và chế biến sản phẩm',
      noiDung: [
        {
          idND: 'nd01',
          ten: 'Đối tượng:',
          chiTiet: 'Các HTX hoạt động trong lĩnh vực nông, lâm, ngư, diêm nghiệp.'
        },
        {
          idND: 'nd02',
          ten: 'Điều kiện hỗ trợ:',
          chiTiet: [
            'HTX đã đi vào hoạt động ổn định tối thiểu 01 năm',
            'HTX thuộc địa bàn các huyện miền núi cao phải có từ 20 thành viên trở lên; thuộc địa bàn các huyện miền núi thấp phải có từ 30 thành viên trở lên; thuộc địa bàn các huyện, thị xã, thành phố khu vực đồng bằng phải có từ 50 thành viên trở lên',
            'Dự án đầu tư kết cấu hạ tầng của HTX phải phù hợp với nhu cầu sản xuất kinh doanh, dịch vụ của HTX hoạt động trong lĩnh vực nông, lâm, ngư, diêm nghiệp',
            'Mỗi hạng mục chỉ được hỗ trợ 01 lần',
            'HTX căn cứ nhu cầu cần hỗ trợ đầu tư dự án xây dựng kết cấu hạ tầng hoặc chế biến sản phẩm có đơn đề nghị gửi cấp chính quyền địa phương nơi HTX tổ chức sản xuất, kinh doanh',
            'Ưu tiên xem xét hỗ trợ các HTX tham gia sản xuất theo chuỗi; chương trình mỗi xã một sản phẩm (OCOP); sản xuất sản phẩm an toàn theo tiêu chuẩn VietGAP; Global GAP,…; HTX nông nghiệp chuyển đổi theo Luật HTX năm 2012; HTX cung ứng các sản phẩm, dịch vụ phục vụ cho đa số thành viên; HTX chưa được hỗ trợ theo Quyết định số 2261/QĐ-TTg ngày 15/12/2014 của Thủ tướng Chính phủ phê duyệt chương trình hỗ trợ phát triển hợp tác xã giai đoạn 2016-2020'
          ]
        },
        {
          idND: 'nd03',
          ten: 'Nội dung hỗ trợ:',
          chiTiet: [
            'Xây dựng nhà kho, xưởng phân loại và đóng gói sản phẩm, xưởng sơ chế - chế biến và mua sắm trang thiết bị phục vụ cho hoạt động sản xuất kinh doanh nhằm mang lại lợi ích cho cộng đồng thành viên',
            'Xây dựng công trình thủy lợi, giao thông nội đồng trong lĩnh vực trồng trọt, lâm nghiệp, diêm nghiệp, bao gồm: Cống, trạm bơm, giếng, đường ống dẫn nước, đập dâng, kênh, bể chứa nước, công trình trên kênh và bờ bao các loại, hệ thống cấp nước đầu mối phục vụ tưới tiết kiệm; đường trục chính giao thông nội đồng, đường ranh cản lửa, đường lâm nghiệp',
            'Xây dựng công trình kết cấu hạ tầng vùng nuôi trồng thủy, hải sản, bao gồm: Hệ thống cấp thoát nước đầu mối (ao, bể chứa, cống, kênh, đường ống cấp, tiêu nước, trạm bơm), đê bao, kè, đường giao thông, công trình xử lý nước thải chung đối với vùng nuôi trồng thủy sản; hệ thống phao tiêu, đèn báo ranh giới khu vực nuôi, hệ thống neo lồng bè; nâng cấp và phát triển lồng bè nuôi trồng hải sản tập trung của hợp tác xã nông nghiệp trên biển.'
          ]
        },
        {
          idND: 'nd04',
          ten: 'Mức hỗ trợ:',
          moTa: 'Tổng mức hỗ trợ cho tất cả các nội dung hỗ trợ nêu trên tối đa 3.000 triệu đồng/HTX; trong đó, hỗ trợ xây dựng nhà kho, xưởng phân loại và đóng gói sản phẩm, xưởng sơ chế - chế biến và mua sắm trang thiết bị phục vụ cho hoạt động sản xuất kinh doanh có mức hỗ trợ tối đa 600 triệu đồng/HTX.',
          chiTiet: [
            'HTX thuộc địa bàn các huyện khu vực miền núi cao, mức hỗ trợ tối đa 90% kinh phí đầu tư của dự án;',
            'HTX thuộc địa bàn các huyện khu vực miền núi thấp, mức hỗ trợ tối đa 80% kinh phí đầu tư của dự án;',
            'HTX thuộc địa bàn các huyện, thị xã, thành phố khu vực đồng bằng, mức hỗ trợ tối đa 70% kinh phí đầu tư của dự án;',
            'Căn cứ vào tính chất của dự án, mức vốn ngân sách trung ương hỗ trợ, vốn đề xuất đối ứng của hợp tác xã, vốn từ các nguồn hợp pháp khác; Hội đồng nhân dân cấp huyện quy định tỷ lệ hỗ trợ cụ thể của ngân sách địa phương và chỉ đạo thực hiện các giải pháp huy động đóng góp HTX, bố trí lồng ghép các nguồn vốn để thực hiện, đảm bảo tổng kinh phí hỗ trợ từ ngân sách Nhà nước cho dự án không quá 100% tổng mức đầu tư đã được cấp có thẩm quyền phê duyệt.'
          ]
        },
      ]
    },
    {
      idHoTro: 'ht2',
      tieuDe: '2. Hỗ trợ từ ngân sách tỉnh hỗ trợ thành lập mới, củng cố tổ chức HTX',
      noiDung: [
        {
          idND: 'nd11',
          ten: 'Đối tượng:',
          chiTiet: [
            'Các HTX thành lập mới;',
            'Các HTX sắp xếp lại do hợp nhất, sáp nhập, tái cấu trúc.'
          ]
        },
        {
          idND: 'nd12',
          ten: 'Điều kiện hỗ trợ:',
          chiTiet: [
            'Có văn bản đề nghị được hỗ trợ thành lập mới HTX; tổ chức, sắp xếp lại hoạt động của HTX',
            'Đảm bảo các điều kiện về thành lập, hợp nhất, sáp nhập theo quy định của Luật HTX và các văn bản pháp luật liên quan',
            'Các HTX có thời gian hoạt động tối thiểu 05 năm, liên hiệp HTX có thời gian hoạt động tối thiểu 03 năm trước khi hợp nhất, sáp nhập',
          ]
        },
        {
          idND: 'nd13',
          ten: 'Nội dung hỗ trợ:',
          chiTiet: [
            'Cung cấp thông tin, tư vấn, tập huấn, phổ biến quy định pháp luật về HTX trước khi thành lập cho sáng lập viên',
            'Tư vấn, hỗ trợ xây dựng điều lệ, phương án sản xuất kinh doanh; hướng dẫn và thực hiện các thủ tục thành lập, đăng ký và tổ chức hoạt động của HTX',
            'Tư vấn, hỗ trợ xây dựng điều lệ và tổ chức hoạt động của HTX sau khi hợp nhất, sáp nhập'
          ]
        },
        {
          idND: 'nd14',
          ten: 'Mức hỗ trợ:',
          moTa: 'Tổng mức hỗ trợ cho tất cả các nội dung hỗ trợ nêu trên tối đa 3.000 triệu đồng/HTX; trong đó, hỗ trợ xây dựng nhà kho, xưởng phân loại và đóng gói sản phẩm, xưởng sơ chế - chế biến và mua sắm trang thiết bị phục vụ cho hoạt động sản xuất kinh doanh có mức hỗ trợ tối đa 600 triệu đồng/HTX.',
          chiTiet: [
            'Hỗ trợ theo chi phí thực tế phát sinh (có hóa đơn, chứng từ chi hợp pháp, hợp lệ). Cụ thể: (1) Thành lập mới: Mức hỗ trợ tối đa: 30 triệu đồng/hợp tác xã đối với các HTX thuộc địa bàn các huyện khu vực miền núi cao; 25 triệu đồng/hợp tác xã đối với các HTX thuộc địa bàn các huyện khu vực miền núi thấp; 20 triệu đồng/hợp 5 tác xã đối với các HTX thuộc địa bàn các huyện, thị xã, thành phố khu vực đồng bằng; 40 triệu đồng/liên hiệp hợp tác xã; (2) Hợp nhất, sáp nhập: Mức hỗ trợ tối đa: 20 triệu đồng/01 trường hợp đối với các HTX thuộc địa bàn các huyện khu vực miền núi cao; 15 triệu đồng/01 trường hợp đối với các HTX thuộc địa bàn các huyện khu vực miền núi thấp; 10 triệu đồng/01 trường hợp đối với các HTX thuộc địa bàn các huyện, thị xã, thành phố khu vực đồng bằng; 30 triệu đồng/01 trường hợp đối với liên hiệp hợp tác xã.',
            'Quy mô thực hiện: Giai đoạn 2021 - 2025 hỗ trợ thành lập mới khoảng 200 HTX, 05 liên hiệp HTX; hợp nhất, sáp nhập khoảng 25 HTX, 02 liên hiệp HTX.',
          ]
        },
      ]
    },
    {
      idHoTro: 'ht3',
      tieuDe: '3. Hỗ trợ ngân sách tỉnh liên quan đến chính sách tín dụng',
      noiDung: [
        {
          idND: 'nd21',
          ten: 'Đối tượng:',
          chiTiet: 'HTX nông nghiệp có nhu cầu, có dự án vay vốn để đầu tư phát triển sản xuất, kinh doanh dịch vụ.'
        },
        {
          idND: 'nd22',
          ten: 'Điều kiện hỗ trợ:',
          chiTiet: 'Đã hoàn thành thủ tục vay vốn theo quy định của Nhà nước tại các Ngân hàng thương mại.'
        },
        {
          idND: 'nd23',
          ten: 'Nội dung hỗ trợ:',
          chiTiet: 'Hỗ trợ lãi suất phần chênh lệch giữa lãi suất thực tế cho vay của Ngân hàng thương mại với lãi suất cho vay của Quỹ Hỗ trợ phát triển hợp tác xã.'
        },
        {
          idND: 'nd24',
          ten: 'Mức hỗ trợ:',
          chiTiet: [
            'Mức hỗ trợ bằng chênh lệch lãi suất thực tế cho vay trong hạn của Ngân hàng thương mại so với lãi suất cho vay của Quỹ hỗ trợ phát triển HTX tỉnh; hạn mức vay vốn được hỗ trợ lãi suất tính trên số dư nợ thực tế tại thời điểm xem xét hồ sơ hỗ trợ nhưng tối đa không quá 02 tỷ đồng/HTX.',
            'Thời gian được hỗ trợ (tính từ ngày bắt đầu giải ngân theo hợp đồng tín dụng với Ngân hàng thương mại) tối đa là 05 năm đối với các dự án vay đầu tư sản xuất và tối đa 03 năm đối với dự án vay đầu tư kinh doanh dịch vụ.',
          ]
        },
        {
          idND: 'nd25',
          ten: 'Quy mô thực hiện:',
          chiTiet: 'Giai đoạn 2021 - 2025 hỗ trợ lãi suất cho khoảng 35 HTX.'
        }
      ]
    },
    {
      idHoTro: 'ht4',
      tieuDe: '4. Hỗ trợ ngân sách tỉnh đầu tư kết cấu hạ tầng kỹ thuật',
      noiDung: [
        {
          idND: 'nd31',
          ten: 'Đối tượng:',
          chiTiet: 'Các HTX hoạt động trong lĩnh vực nông, lâm, ngư, diêm nghiệp.'
        },
        {
          idND: 'nd32',
          ten: 'Điều kiện hỗ trợ:',
          chiTiet: [
            'HTX đã đi vào hoạt động ổn định tối thiểu 01 năm',
            'HTX thuộc địa bàn các huyện miền núi cao phải có từ 20 thành viên trở lên; thuộc địa bàn các huyện miền núi thấp phải có từ 30 thành viên trở lên; thuộc địa bàn các huyện, thị xã, thành phố khu vực đồng bằng phải có từ 50 thành viên trở lên',
            'Dự án đầu tư kết cấu hạ tầng của HTX phải phù hợp với nhu cầu sản xuất kinh doanh, dịch vụ của HTX hoạt động trong lĩnh vực nông, lâm, ngư, diêm nghiệp',
            'Mỗi hạng mục chỉ được hỗ trợ 01 lần',
            'HTX căn cứ nhu cầu cần hỗ trợ đầu tư dự án xây dựng kết cấu hạ tầng hoặc chế biến sản phẩm có đơn đề nghị gửi cấp chính quyền địa phương nơi HTX tổ chức sản xuất, kinh doanh',
            'Ưu tiên xem xét hỗ trợ các HTX tham gia sản xuất theo chuỗi; chương trình mỗi xã một sản phẩm (OCOP); sản xuất sản phẩm an toàn theo tiêu chuẩn VietGAP; Global GAP,…; HTX nông nghiệp chuyển đổi theo Luật HTX năm 2012; HTX cung ứng các sản phẩm, dịch vụ phục vụ cho đa số thành viên; HTX chưa được hỗ trợ theo Quyết định số 2261/QĐ-TTg ngày 15/12/2014 của Thủ tướng Chính phủ phê duyệt chương trình hỗ trợ phát triển hợp tác xã giai đoạn 2016-2020'
          ]
        },
        {
          idND: 'nd33',
          ten: 'Mức hỗ trợ:',
          moTa: 'Tối đa 600 triệu đồng/HTX, trong đó:',
          chiTiet: [
            'HTX thuộc địa bàn các huyện khu vực miền núi cao, mức hỗ trợ tối đa 90% kinh phí đầu tư của dự án;',
            'HTX thuộc địa bàn các huyện khu vực miền núi thấp, mức hỗ trợ tối đa 80% kinh phí đầu tư của dự án;',
            'HTX thuộc địa bàn các huyện, thị xã, thành phố khu vực đồng bằng, mức hỗ trợ tối đa 70% kinh phí đầu tư của dự án.',
            'Căn cứ vào tính chất của dự án, mức vốn ngân sách tỉnh hỗ trợ, vốn đề xuất đối ứng của hợp tác xã, vốn từ các nguồn hợp pháp khác; Hội đồng nhân dân cấp huyện quy định tỷ lệ hỗ trợ cụ thể của ngân sách địa phương và chỉ đạo thực hiện các giải pháp huy động đóng góp HTX, bố trí lồng ghép các nguồn vốn để thực hiện, đảm bảo tổng kinh phí hỗ trợ từ ngân sách Nhà nước cho dự án không quá 100% tổng mức đầu tư đã được cấp có thẩm quyền phê duyệt.'
          ]
        },
        {
          idND: 'nd34',
          ten: 'Quy mô thực hiện:',
          chiTiet: 'Giai đoạn 2021 - 2025 hỗ trợ đầu tư hạ tầng kỹ thuật cho khoảng 60 HTX.'
        }
      ]
    },
    {
      idHoTro: 'ht5',
      tieuDe: '5. Hỗ trợ ngân sách tỉnh cho HTX khởi nghiệp',
      noiDung: [
        {
          idND: 'nd41',
          ten: 'Đối tượng:',
          chiTiet: 'HTX do người tốt nghiệp từ cao đẳng trở lên sáng lập hoặc đồng sáng lập ra HTX và giữ vị trí lãnh đạo, quản lý HTX.'
        },
        {
          idND: 'nd42',
          ten: 'Điều kiện hỗ trợ:',
          chiTiet: [
            'Các thành viên sáng lập hoặc đồng sáng lập giữ vị trí lãnh đạo, quản lý HTX không quá 40 tuổi và lần đầu tham gia hình thành tổ chức để hoạt động kinh tế',
            'Hoạt động của HTX có tác động trực tiếp vào việc hỗ trợ phát triển sản phẩm địa phương, giải quyết việc làm cho 10 lao động trở lên',
            'Ưu tiên HTX có tham gia chuỗi giá trị sản phẩm hàng hóa, sản xuất sản phẩm sạch, ứng dụng công nghệ cao'
          ]
        },
        {
          idND: 'nd43',
          ten: 'Nội dung hỗ trợ:',
          chiTiet: 'Đầu tư cơ sở vật chất thiết yếu, máy móc, trang thiết bị ban đầu để phục vụ hoạt động của HTX.'
        },
        {
          idND: 'nd44',
          ten: 'Mức hỗ trợ:',
          chiTiet: 'Tối đa 70% kinh phí thực hiện nhưng không quá 200 triệu đồng/HTX.'
        },
        {
          idND: 'nd45',
          ten: 'Quy mô thực hiện:',
          chiTiet: 'Giai đoạn 2021 - 2025 hỗ trợ khoảng 50 HTX.'
        }
      ]
    }
  ],

  // ƯU ĐÃI XÃ HỘI HÓA
  tieuDeChinhXaHoiHoa: 'Chính sách đẩy mạnh xã hội hóa đầu tư trong một số lĩnh vực trên địa bàn tỉnh Quảng Nam giai đoạn 2022-2025',
  canCuXaHoiHoa: `Căn cứ quy định tại Nghị quyết 06/2022/NQ-HĐND ngày 12/01/2022 của HĐND tỉnh.`,
  // Ưu đãi xã hội hóa - Đề mục
  doiTuongXHH: 'I. Đối tượng áp dụng',
  dieuKienNguyenTacXHH: 'II. Điều kiện, nguyên tắc áp dụng ưu đãi',
  noiDungMucHoTroNhaDauTuXHH: 'III. Nội dung và mức hỗ trợ nhà đầu tư',
  // Ưu đãi xã hội hóa - Đối tượng
  noiDungDoiTuongXHH: [
    'Cơ sở thực hiện xã hội hóa theo quy định của Chính phủ.',
    'Cơ quan, đơn vị, tổ chức, cá nhân có liên quan đến các chính sách khuyến khích xã hội hóa đầu tư trong các lĩnh vực giáo dục - đào tạo, dạy nghề, y tế, văn hóa, thể dục thể thao, môi trường, giám định tư pháp trên địa bàn tỉnh Quảng Nam giai đoạn 2022-2025.'
  ],
  // Ưu đãi xã hội hóa - Điều kiện
  dSDieuKienNguyenTacXHH: [
    {
      thuTu: '01',
      noiDung: 'Dự án thuộc danh mục các loại hình khuyến khích và đáp ứng các tiêu chí quy mô, tiêu chuẩn theo quy định tại các Quyết định của Thủ tướng Chính phủ: số 1466/QĐ-TTg ngày 10 tháng 10 năm 2008, số 693/QĐ-TTg ngày 06 tháng 5 năm 2013, số 1470/QĐ-TTg ngày 22 tháng 7 năm 2016; có trong danh mục và phù hợp tiêu chí thu hút đầu tư xã hội hóa được cấp có thẩm quyền phê duyệt.'
    },
    {
      thuTu: '02',
      noiDung: 'Dự án phải phù hợp với quy hoạch phát triển của tỉnh, đáp ứng nhu cầu cấp thiết của địa phương; phù hợp với quy hoạch, kế hoạch sử dụng đất được cấp có thẩm quyền phê duyệt và được Ủy ban nhân dân tỉnh quyết định chấp thuận chủ trương đầu tư; quyết định chấp thuận nhà đầu tư (hoặc quyết định kết quả lựa chọn nhà đầu tư) theo quy định.'
    },
    {
      thuTu: '03',
      noiDung: 'Dự án thực hiện xã hội hóa phải đảm bảo thực hiện đúng tiến độ, quy mô theo phương án đầu tư đã đăng ký; thực hiện dự án xã hội hóa đúng mục đích, có hiệu quả.'
    }
  ],
  altBgXuLyXHH: 'Ưu đãi doanh nghiệp',
  // Ưu đãi xã hội hóa - Nội dung
  dSNoiDungMucHoTroXHH: [
    {
      id: 'nd1',
      ten: '1. Chính sách miễn tiền thuê đất',
      noiDung: [
        'Miễn 100% tiền thuê đất cho cả thời gian thuê đất thực hiện dự án (tối đa 50 năm) đối với tất cả các dự án thuộc các lĩnh vực xã hội hóa theo quy định của Chính phủ.',
        'Các dự án đầu tư thuộc các lĩnh vực xã hội hóa trên địa bàn các thành phố: Tam Kỳ, Hội An và thị xã Điện Bàn được miễn tiền thuê đất theo các mức tương ứng: miễn tối đa 50 năm, 25 năm, 20 năm, 15 năm tiền thuê đất của dự án tùy vào loại hình ưu tiên thu hút trong giai đoạn 2022-2025.',
        'Trong mỗi dự án chỉ miễn tiền thuê đất đối với diện tích đất sử dụng đúng mục đích xã hội hóa, phần diện tích đất sử dụng không đúng mục đích xã hội hóa không được miễn.'
      ]
    },
    {
      id: 'nd2',
      ten: '2. Chính sách hỗ trợ kinh phí bồi thường, giải phóng mặt bằng',
      noiDung: [
        'Ngân sách tỉnh bố trí 100% kinh phí thực hiện bồi thường, giải phóng mặt bằng đối với các dự án thực hiện trên địa bàn có điều kiện kinh tế - xã hội đặc biệt khó khăn của tỉnh, được quy định tại Phụ lục III kèm theo Nghị định số 31/2021/NĐ-CP ngày 26 tháng 3 năm 2021 của Chính phủ quy định chi tiết và hướng dẫn thi hành một số điều của Luật Đầu tư, gồm các huyện: Đông Giang, Tây Giang, Nam Giang, Phước Sơn, Bắc Trà My, Nam Trà My, Hiệp Đức, Tiên Phước, Nông Sơn, Núi Thành (gồm các xã: Tam Sơn, Tam Thạnh, Tam Mỹ Đông, Tam Mỹ Tây, Tam Trà, Tam Hải), Thăng Bình (gồm các xã: Bình Lãnh, Bình Trị, Bình Định Bắc, Bình Định Nam, Bình Quý, Bình Phú, Bình Chánh, Bình Quế) và xã đảo Tân Hiệp, thành phố Hội An.',
        'Ngân sách tỉnh hỗ trợ 50%, nhà đầu tư đóng góp 50% kinh phí thực hiện bồi thường, giải phóng mặt bằng đối với các dự án thực hiện trên địa bàn có điều kiện kinh tế - xã hội khó khăn của tỉnh, được quy định tại Phụ lục III kèm theo Nghị định số 31/2021/NĐ-CP ngày 26 tháng 3 năm 2021 của Chính phủ, gồm các huyện: Đại Lộc, Quế Sơn, Phú Ninh, Duy Xuyên (gồm các xã: Duy Châu, Duy Hòa, Duy Phú, Duy Sơn, Duy Tân, Duy Thu, Duy Trinh, Duy Trung), Núi Thành (gồm các xã: Tam Xuân I, Tam Xuân II, Tam Anh Bắc, Tam Anh Nam), Thăng Bình (gồm các xã: Bình Nguyên, Bình Tú, Bình An, Bình Trung). Trường hợp dự án xã hội hóa nằm trên địa bàn liên huyện/xã thì ngân sách tỉnh hỗ trợ theo diện tích/kinh phí phát sinh trên địa bàn của từng địa phương trong dự án.',
        'Đối với dự án thực hiện trên địa bàn còn lại: Ngân sách tỉnh không hỗ trợ kinh phí thực hiện bồi thường, giải phóng mặt bằng.',
        'Phần diện tích đất được sử dụng đúng mục đích xã hội hóa được hưởng chính sách hỗ trợ kinh phí bồi thường, giải phóng mặt bằng; phần diện tích đất sử dụng không liên quan đến lĩnh vực xã hội hóa không được hỗ trợ kinh phí bồi thường, giải phóng mặt bằng.'
      ]
    },
    {
      id: 'nd3',
      ten: '3. Chính sách về cho thuê, xây dựng cơ sở vật chất',
      noiDung: [
        'Cơ sở thực hiện xã hội hóa được ưu tiên thuê cơ sở hạ tầng, công trình xã hội hóa để cung cấp các sản phẩm dịch vụ trong lĩnh vực xã hội hóa theo quy định pháp luật về xã hội hóa.',
        'Khung giá cho thuê cơ sở hạ tầng, công trình xã hội hóa bao gồm giá thuê tối thiểu và giá thuê tối đa được xác định theo quy định tại khoản 2 Điều 1 Thông tư số 156/2014/TT-BTC ngày 23 tháng 10 năm 2014 của Bộ trưởng Bộ Tài chính. Chủ dự án thực hiện xã hội hóa được áp dụng giá cho thuê ưu đãi bằng giá thuê tối thiểu theo quy định (trong trường hợp không đấu giá cho thuê tài sản); trường hợp thực hiện đấu giá cho thuê tài sản, giá khởi điểm đấu giá được xác định 4 bằng giá thuê tối thiểu theo quy định. Thời gian hưởng ưu đãi chỉ áp dụng trong 50% thời gian đầu của hợp đồng thuê và không quá 10 năm.',
      ]
    },
    {
      ten: '4. Chính sách ưu đãi về tín dụng',
      noiDung: [
        'Cơ sở thực hiện xã hội hóa, đơn vị sự nghiệp công lập hoạt động trong lĩnh vực xã hội hóa được vay vốn tín dụng đầu tư của nhà nước theo quy định tại Nghị định số 32/2017/NĐ-CP ngày 31 tháng 3 năm 2017 của Chính phủ về tín dụng đầu tư của Nhà nước; được xem xét trong danh mục ưu tiên vay vốn với lãi suất ưu đãi tại Quỹ Đầu tư phát triển Quảng Nam (dự án phải đảm bảo các điều kiện quy định tại Điều lệ tổ chức và hoạt động của Quỹ Đầu tư phát triển Quảng Nam, phù hợp với danh mục lĩnh vực đầu tư, cho vay của Quỹ Đầu tư phát triển Quảng Nam giai đoạn 2021-2025 được quy định tại Nghị quyết số 14/NQ-HĐND ngày 16 tháng 3 năm 2021 của Hội đồng nhân dân tỉnh về danh mục lĩnh vực đầu tư, cho vay của Quỹ Đầu tư phát triển Quảng Nam, giai đoạn 2021-2025).',
      ]
    },
  ],

  // ƯU ĐÃI NÔNG NGHIỆP NÔNG THÔN
  tieuDeChinhNongNghiepNongThon: 'Cơ chế, chính sách khuyến khích doanh nghiệp đầu tư vào nông nghiệp, nông thôn trên địa bàn tỉnh Quảng Nam',
  canCuNongNghiepNongThon: 'Căn cứ quy định tại Nghị quyết số 45/2018/NQ-HĐND ngày 06/12/2018 của HĐND tỉnh và Quyết định số 522/QĐ-UBND ngày 25/02/2021 của UBND tỉnh.',
  // Ưu đãi nông nghiệp nông thôn - Đề mục
  doiTuongNNNT: 'I. Đối tượng áp dụng',
  dieuKienNguyenTacNNNT: 'II. Điều kiện, nguyên tắc áp dụng ưu đãi',
  noiDungMucHoTroNhaDauTuNNNT: 'III. Nội dung và mức hỗ trợ nhà đầu tư',
  // Ưu đãi nông nghiệp nông thôn - Đối tượng
  dSDoiTuongNNNT: [
    'Doanh nghiệp được thành lập, đăng ký, hoạt động theo Luật doanh nghiệp và có dự án đầu tư là Dự án nông nghiệp đặc biệt ưu đãi đầu tư, Dự án nông nghiệp ưu đãi đầu tư và Dự án nông nghiệp khuyến khích đầu tư theo quy định tại Nghị định 57/2018/NĐ-CP ngày 17/4/2018 của Chính phủ.',
    'Hợp tác xã, liên hiệp hợp tác xã thành lập mới hoặc đã chuyển đổi theo Luật Hợp tác xã năm 2012 thực hiện đầu tư vào nông nghiệp, nông thôn trên địa bàn tỉnh Quảng Nam.'
  ],
  // Ưu đãi nông nghiệp nông thôn - Điều kiện
  dSDieuKienNguyenTacNNNT: [
    'Nhà nước ưu đãi đầu tư thông qua miễn, giảm thuế, phí, lệ phí và giảm một số thủ tục hành chính cho doanh nghiệp.',
    'Nhà nước hỗ trợ đầu tư bằng hình thức hỗ trợ một phần kinh phí đầu tư hoặc cấp bù chênh lệch lãi suất cho doanh nghiệp.',
    'Doanh nghiệp có dự án nông nghiệp đặc biệt ưu đãi đầu tư, dự án nông nghiệp ưu đãi đầu tư, dự án nông nghiệp khuyến khích đầu tư được hưởng các ưu đãi đầu tư, hỗ trợ đầu tư theo quy định của Nghị định này.',
    'Ưu tiên hỗ trợ đầu tư cho doanh nghiệp nhỏ và vừa khởi nghiệp sáng tạo quy định tại khoản 2 Điều 3 Luật Hỗ trợ doanh nghiệp vừa và nhỏ; doanh nghiệp xây dựng vùng nguyên liệu có hợp đồng liên kết với nông dân.',
    'Trong cùng một thời gian, nếu dự án đầu tư được hưởng ưu đãi, hỗ trợ đầu tư có các mức ưu đãi, hỗ trợ khác nhau thì doanh nghiệp được lựa chọn áp dụng mức ưu đãi, hỗ trợ đầu tư có lợi nhất.',
    'Doanh nghiệp tự bỏ vốn, huy động vốn để thực hiện dự án đầu tư vào ngành, nghề ưu đãi đầu tư trong nông nghiệp, nông thôn. Nhà nước hỗ trợ sau đầu tư theo định mức hỗ trợ đối với từng loại hạng mục, công trình do Ủy ban nhân dân cấp tỉnh ban hành.',
    'Trường hợp cùng một nội dung, doanh nghiệp, hợp tác xã, liên hiệp hợp tác xã nhận hỗ trợ từ ngân sách Trung ương thì không được hưởng chính sách hỗ trợ từ ngân sách tỉnh.'
  ],
  altBgNongNghiepNongThon: 'Ưu đãi doanh nghiệp',
  // Ưu đãi nông nghiệp nông thôn - Nội dung
  dsNoiDungMucHoTroNNNT: [
    {
      thuTu: '01',
      tieuDe: 'Hỗ trợ đầu tư hạ tầng vùng tích tụ, tập trung đất đai',
      noiDung: [
        'Hỗ trợ 60% kinh phí đầu tư về giao thông nội đồng, thuỷ lợi, các kết cấu hạ tầng khác và trang thiết bị phục vụ sản xuất trong khu vực được tích tụ, tập trung đất đai nhưng không quá 03 tỷ đồng/dự án đối với các dự án đầu tư sản xuất nông nghiệp tập trung (trồng các loại rau, củ, quả, dược liệu, nuôi trồng thủy sản) có hợp đồng liên kết sản xuất giữa hợp tác xã, liên hiệp hợp tác xã với doanh nghiệp và quy mô diện tích từ 05 ha trở lên.'
      ]
    },
    {
      thuTu: '02',
      tieuDe: 'Hỗ trợ đầu tư cải tạo, nâng cấp cơ sở giết mổ gia súc, gia cầm tập trung',
      noiDung: [
        'Các doanh nghiệp, hợp tác xã, liên hiệp hợp tác xã có dự án cải tạo, nâng cấp cơ sở giết mổ gia súc, gia cầm tập trung, áp dụng công nghệ tiên tiến, có hệ thống quản lý chất thải rắn và lỏng theo đúng quy định được hỗ trợ 60% tổng mức đầu tư nhưng không quá 01 tỷ đồng/dự án để cải tạo, nâng cấp kết cấu hạ tầng về giao thông, điện, nước, nhà xưởng, xử lý chất thải và mua thiết bị trong hàng rào dự án.'
      ]
    },
    {
      thuTu: '03',
      tieuDe: 'Hỗ trợ đầu tư cơ sở chăn nuôi gia súc (lợn, dê) tập trung',
      noiDung: [
        'Doanh nghiệp, hợp tác xã, liên hiệp hợp tác xã có dự án đầu tư cơ sở chăn nuôi lợn từ 1.000 con hoặc dê từ 500 con trở lên, phù hợp quy hoạch chăn nuôi gia súc, gia cầm tập trung được UBND tỉnh phê duyệt hoặc phù hợp quy hoạch xây dựng nông thôn mới, thực hiện phương án bảo vệ môi trường theo quy định (đối với lợn, yêu cầu xây dựng hệ thống chuồng kín, công nghệ xử lý chất thải tiên tiến, có hầm biogas; đối với dê, yêu cầu có chuồng trại phù hợp, có công nghệ xử lý chất thải đáp ứng yêu cầu bảo vệ môi trường) được nhà nước hỗ trợ 60% tổng mức đầu tư nhưng không quá 02 tỷ đồng/dự án để xây dựng kết cấu hạ tầng về giao thông, điện, cấp thoát nước, xử lý chất thải trong hàng rào dự án.'
      ]
    },
    {
      thuTu: '04',
      tieuDe: 'Hỗ trợ trồng cây dược liệu',
      moTa: 'Doanh nghiệp, hợp tác xã, liên hiệp hợp tác xã có dự án đầu tư trồng các loại cây dược liệu được hỗ trợ như sau:',
      noiDung: [
        '1. Dự án có quy mô diện tích tập trung từ 05 ha trở lên, hỗ trợ 25 triệu đồng/ha chi phí cây giống, nhưng tối đa không quá 01 tỷ đồng/dự án.',
        '2. Dự án có quy mô diện tích tập trung từ 10 ha trở lên, ngoài hỗ trợ chi phí giống được hỗ trợ 60% tổng mức đầu tư nhưng tối đa không quá 02 tỷ đồng/dự án để đầu tư kết cấu hạ tầng về giao thông, điện, hệ thống cấp nước trong hàng rào dự án.'
      ],
      noiDungThem: 'Doanh nghiệp, hợp tác xã, liên hiệp hợp tác xã có dự án đầu tư sản xuất giống sâm Ngọc Linh có quy mô từ 50 ngàn cây giống sâm Ngọc Linh/năm trở lên được hỗ trợ 80% chi phí để xây dựng cơ sở hạ tầng, thiết bị và xử lý môi trường, lập vườn giống gốc trong hàng rào dự án nhưng không quá 08 tỷ đồng/dự án.'
    },
    {
      thuTu: '05',
      tieuDe: 'Hỗ trợ đầu tư sản xuất rau, củ, quả, nấm an toàn theo hướng nông nghiệp hữu cơ hoặc thực hành nông nghiệp tốt (GAP)',
      moTa: 'Doanh nghiệp, hợp tác xã, liên hiệp hợp tác xã có dự án đầu tư sản xuất rau, củ, quả, nấm an toàn theo hướng nông nghiệp hữu cơ hoặc thực hành nông nghiệp tốt (GAP) ứng dụng công nghệ cao, có quy mô diện tích tập trung từ 03 ha đất canh tác trở lên, hoặc 2.000m2 trở lên đối với diện tích nhà trồng cây (nhà kính, nhà lưới) xây kiên cố và từ 300m2 trở lên đối với diện tích nhà trồng nấm được hỗ trợ như sau:',
      noiDung: [
        '1. Hỗ trợ một lần, tối đa 50 triệu đồng/ha để thuê tổ chức đánh giá và cấp giấy chứng nhận sản phẩm an toàn theo tiêu chuẩn nông nghiệp hữu cơ, GAP.',
        '2. Hỗ trợ 60% tổng mức đầu tư nhưng không quá 02 tỷ đồng/dự án để đầu tư khai hoang, xây dựng giao thông, điện, xử lý chất thải, hệ thống cấp, thoát nước; hệ thống nhà lưới, nhà kính và các trang thiết bị đi kèm như tưới nước tiết kiệm, điều chỉnh nhiệt độ… trong hàng rào dự án.',
        '3. Hỗ trợ 30 triệu đồng/ha trong năm đầu tiên để chuyển đổi từ sản xuất khác sang sản xuất sản phẩm nông nghiệp hữu cơ.'
      ]
    },
  ],
  // VẤN ĐỀ PHÁP LÝ
    noiDungChinhVDPL: 'Nội dung chính',
    thuTucDauTuVDPL:'Thủ tục đầu tư',
    nganhNgheKinhDoanhVaNguyenTacVDPL:'Ngành, nghề kinh doanh và Nguyên tắc tiếp cận thị trường',
    laoDongVDPL:'Lao động',
    kiemTraVaGiamSatVDPL:'Kiểm tra, giám sát hải quan đối với doanh nghiệp chế xuất',
    cacVanDeKhacVDPL:'Các vấn đề khác',
    duAnDauTuMoiVDPL:'Dự án đầu tư mới',
    gopVonMuaCoPhanVDPL:'Góp vốn, mua cổ phần/phần vốn góp:',
    yeuCauThucHienThuTucDangKyGopVonVDPL: 'Yêu cầu thực hiện thủ tục Đăng ký góp vốn, mua cổ phần, mua phần vốn góp:',
    subThuTucDauTuVDPL:'Tổ chức kinh tế có Giấy chứng nhận quyền sử dụng đất tại đảo và xã, phường, thị trấn biên giới; xã, phường, thị trấn ven biển; khu vực khác có ảnh hưởng đến quốc phòng, an ninh.',
    nganhNgheKinhDoanhVDPL:'Ngành nghề kinh doanh',
    nganhCamVDPL:'Ngành, nghề cấm đầu tư kinh doanh',
    nganhKinhDoanhCoDieuKienVDPL:'Ngành, nghề đầu tư kinh doanh có điều kiện',
    contentNganhCamVDPL:'8 ngành, nghề (bao gồm kinh doanh các chất ma túy, các loại hóa chất, khoáng vật, kinh doanh pháo nổ, kinh doanh dịch vụ đòi nợ,...)',
    contentNganhKinhDoanhCoDieuKienVDPL:'227 ngành, nghề (chủ yếu trong lĩnh vực kinh doanh dịch vụ và mua bán hàng hóa nhạy cảm) và các điều kiện áp dụng được đăng tải công khai tại Cổng thông tin Quốc gia về Đăng ký doanh nghiệp',
    nguyenTacTiepCanDauTuNuocNgoaiVDPL:'Nguyên tắc tiếp cận thị trường đối với nhà đầu tư nước ngoài',
    nguyenTacTiepCanThiTruongVDPL:'Nguyên tắc tiếp cận thị trường',
    tbContentNganhNgheKinhDoanhMotVDPL:'Danh mục ngành, nghề chưa được tiếp cận thị trường (25 ngành, nghề)',
    tbContentNganhNgheKinhDoanhMotAVDPL:'(Nhóm A, Phụ lục I, Nghị định 31/2021/NĐ-CP ngày 26/3/2021 của Chính phủ)',
    tbContentNganhNgheKinhDoanhHaiVDPL:'Danh mục ngành, nghề tiếp cận thị trường có điều kiện (59 ngành, nghề)',
    tbContentNganhNgheKinhDoanhHaiBVDPL:'(Nhóm B, Phụ lục I, Nghị định 31/2021/NĐ-CP ngày 26/3/2021 của Chính phủ)',
    tbContentNganhNgheKinhDoanhBaVDPL:'Chưa có cam kết của Chính phủ Việt Nam về tiếp cận thị trường đối với nhà đầu tư nước ngoài',
    tbContentNguyenTacTiepCanThiTruongMotVDPL:'Nhà đầu tư nước ngoài không được phép đầu tư',
    tbContentNguyenTacTiepCanThiTruongHaiVDPL:'Nhà đầu tư nước ngoài được phép đầu tư nếu đáp ứng đủ các điều kiện theo quy định',
    tbContentNguyenTacTiepCanThiTruongBaAVDPL:'Nếu đã có quy định về hạn chế tiếp cận thị trường của nhà đầu tư nước ngoài theo quy định của pháp luật Việt Nam -> áp dụng quy định của pháp luật Việt Nam',
    tbContentNguyenTacTiepCanThiTruongBaBVDPL:'Nếu không có quy định về hạn chế tiếp cận thị trường của nhà đầu tư nước ngoài theo quy định của pháp luật Việt Nam Nhà đầu tư nước ngoài được tiếp cận thị trường như quy định đối với nhà đầu tư trong nước',
    dieuKienKiemTraHaiQuanVDPL:'Điều kiện kiểm tra giám sát hải quan đối với Doanh nghiệp chế xuất',
  // Thư viện
    thuVienAnh:'Thư viện ảnh',
    thuVienVideo:'Thư viện video',
    anh: 'ảnh',
  // Dự án
  timkiemDA: 'Tìm kiếm',
  linhVucDA: 'Lĩnh vực',
  nhapCCNDA: 'Nhập tên cụm công nghiệp',
  nhapKCNDA:'Nhập tên khu công nghiệp',

  // Nghiên cứu đầu tư
  timKiemNKDT: 'Tìm kiếm',
  THU_HUT_DAU_TU: 'Thu hút đầu tư',
  NGHIEN_CUU_DAU_TU: 'Nghiên cứu đầu tư',
  duAnDoThi: 'Dự án Đô thị',
  confirm: {
    viTriDangCapNhat: 'Vị trí đang được cập nhật',
    close: 'Đóng'
  },
  form: {
    search: 'Nhập địa chỉ tìm kiếm...'
  },

  // DỰ ÁN ĐẦU TƯ NỔI BẬT
  duAnDTNB: 'Dự án đầu tư nổi bật',
  thacoBusDTNB:'Dự án nhà máy sản xuất xe Bus Thaco của Công ty TNHH MTV Sản xuất Xe Bus Thaco',
  vinpearlHoiAnDTNB:'Dự án Khu phức hợp du lịch nghỉ dưỡng Vinpearl Nam Hội An của Công ty Cổ phần Vinpearl',
  khuNghiDuongNamHoiAnDTNB:'Dự án Khu nghỉ dưỡng Nam Hội An của Công ty TNHH Phát triển Nam Hội An',
  ctyMaiDoanDTNB:'Dự án Khu du lịch sinh thái biển Cát Vàng Chu Lai của Công ty Cổ phần Mai Đoàn',
  heinekenDTNB:'Dự án vận hành nhà máy bia Quảng Nam để sản xuất, tiếp thị và phân phối các loại bia; kinh doanh nước...',
  grozDTNB:'Dự án sản xuất thiết bị và công cụ sản xuất cho ngành dệt và các ngành liên quan ngành dệt của Công ty...',
  fvgtravelDTNB:'Dự án Khu du lịch sinh thái Cổng trời Đông Giang của Công ty CP Du lịch sinh thái Hang Gợp',
  pankoDTNB:'Dự án đầu tư xây dựng nhà máy dệt, may, nhuộm và phụ liệu dệt may của Công ty TNHH Panko Tam Thăng',
  hanacanDTNB:'Dự án Sản xuất vỏ lon và lon hoàn thiện đối với các lon giải khát bằng nhôm của Công ty CP Hanacans...',
  envDTNB:'DỰ ÁN THỦY ĐIỆN SÔNG BUNG 4 của TỔNG CÔNG TY PHÁT ĐIỆN 2 – CÔNG TY TNHH MTV',
  quangNamDTNB:'Quảng Nam',
  subQuangNamDTNB:'là tỉnh thuộc Vùng kinh tế trọng điểm miền Trung, là một trong 6 tỉnh, thành phố có diện tích tự nhiên lớn nhất nước, nằm trong Hành lang kinh tế Đông - Tây, có nguồn tài nguyên phong phú cùng với vị trí chiến lược thuận lợi để kết nối với các tỉnh, thành phố khác của Việt Nam, các quốc gia trong khu vực và thế giới.',
  fiveYearsDTNB:'5 NĂM',
  datDTNB:'ĐẠT ',
  topDTNB:'TOP 10',
  thanhtichDTNB:'5 NĂM ĐẠT TOP 10 CHỈ SỐ NĂNG LỰC CẠNH TRANH CẤP TỈNH (CPI)',
  ThongTinKinhTeXaHoiDTNB:'THÔNG TIN KINH TẾ - XÃ HỘI',
  tinTucDTNB:'TIN TỨC',

  // DANH MỤC DỰ ÁN CÔNG BỐ LỰA CHỌN NHÀ ĐẦU TƯ
  danhMucDuAn: 'Danh mục dự án',
  luaChonNhaDauTu: 'công bố lựa chọn nhà đầu tư',
  chiTietThongTin: 'Chi tiết thông tin',
  noiDungFileDinhKem: 'Nội dung cụ thể theo các file đính kèm:',

  // DANH MỤC DỰ ÁN NGHIÊN CỨU ĐẦU TƯ
  nghienCuuDauTu: 'nghiên cứu đầu tư',
  dauTuQuangNam: 'Đầu tư quảng nam',
  taiLieuXucTien: 'Tài liệu xúc tiến đầu tư',

  // CỤM HOẶC KHU CÔNG NGHIỆP
  tongVonDauTu: 'Tổng vốn đầu tư',
  tong: 'Tổng',
  tatCa: 'Tất cả',
  lienHe: 'Liên hệ',
  dienThoaiCKCN: 'Điện thoại',
  nguoiPhuTrach: 'Người phụ trách',
  tepDinhKem: 'Tệp đính kèm',
  dienTichVaGiaiDoanPhatTrienCKCN: 'Diện tích & Giai đoạn phát triển',
  thongSo: 'Thông số',
  giaiDoanPhatTrien: 'Giai đoạn phát triển',
  giaiDoan: 'Giao đoạn',
  tyLeLapDay: 'Tỷ lệ lấp đầy',
  coSoHaTang: 'Cơ sở Hạ tầng',
  donGia: 'Đơn giá',
  dien: 'Điện',
  nuocSach: 'Nước sạch',
  xuLyNuocThai: 'Xử lý nước thải',
  xuLyRacThai: 'Xử lý rác thải',
  gasChatDot: 'Gas, chất đốt',
  khac: 'Khác',
  maSoDuAn: 'Mã số dự án',
  tenDuAn: 'Tên dự án',
  quocGia: 'Quốc gia',
  linhVucHoatDong: 'Lĩnh vực hoạt động',
  nguonDien: 'Nguồn điện',
  nguonNuoc: 'Nguồn nước',
  heThongThoatNuocXuLyNuocVaChatThai: 'Hệ thống thoát nước, hệ thống xử lý nước thải và chất thải',
  vienThong: 'Viễn thông',
  haTangXaHoiVaDichVu: 'Hạ tầng xã hội và dịch vụ',
  giaChoThueVaPhiQLBD: 'Giá cho thuê lại đất, phí dịch vụ quản lý và bảo dưỡng hạ tầng',
  timKiemThongTin: 'Tìm kiếm thông tin',
  duAnTrongCCN: 'Dự án trong cụm công nghiệp',

  // Liên Hệ
  delailoinhanLH: 'Để lại lời nhắn',
  fileLH:'Đính kèm file',
  hoTenLH:'Họ tên',
  nhapHoTenLH: 'Nhập họ tên',
  sdtLH: 'Số điện thoại',
  nhapSdtLH: 'Nhập số điện thoại',
  nhapEmail: 'Nhập địa chỉ email',
  chuDeLH:'Chủ đề',
  nhapChuDeLH: 'Nhập chủ đề',
  noiDungLH: 'Nội dung',
  nhapNoiDungLH: 'Nhập nội dung',



  // LÔ ĐẤT CCN VÀ KCN
  kiHieuLoDat: 'Kí hiệu lô đất',
  loaiDat: 'Loại đất',
  matDoXayDung: 'Mật độ xây dựng',
  tangCaoXayDungTrungBinh: 'Tầng cao xây dựng trung bình',

  // THƯƠNG HIỆU ĐÀU TƯ
  mucLuc: 'Mục lục',
  linhVuc: 'Lĩnh vực',
  nongNghiep: 'Nông nghiệp',
  duLich: 'Du lịch',
  doThi: 'Đô thị',

  // MAP LEAFLET
  hienThiKetQuaCho: 'Hiển thị kết quả cho',
  tatCaLinhVuc: 'Tất cả lĩnh vực',
  tatCaTPHuyen: 'Tất cả TP/ Huyện',

  // DANH SÁCH DỰ ÁN
  tPHuyen: 'TP/ Huyện',
  loaiBanDoCum: 'Cụm công nghiệp',
}

export default VI_VN
