import { render, staticRenderFns } from "./master.vue?vue&type=template&id=05047238&"
import script from "./master.vue?vue&type=script&lang=js&"
export * from "./master.vue?vue&type=script&lang=js&"
import style0 from "./master.vue?vue&type=style&index=0&id=05047238&prod&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('D:/xtdt-qna-fe/components/Header/Header.vue').default,Footer: require('D:/xtdt-qna-fe/components/Footer/Footer.vue').default})
