export const state = () => ({
  dataTinKinhTe: [],
  dataThongTinQuyHoachHuyen: []
})

export const getters = {
  dataTinKinhTe: (state) => {
    return state.dataTinKinhTe
  },
  dataThongTinQuyHoachHuyen: (state) => {
    return state.dataThongTinQuyHoachHuyen
  },
}

export const mutations = {
  SET_RESPONSE_TIN_KINH_TE(state, data) {
    state.dataTinKinhTe = data
  },
}

/* action */
export const actions = {
  async getTinKinhTe({ commit }, formData) {
    try {
      const data = await this.$repositories.baiviet.tinKinhTe(formData);
      return commit('SET_RESPONSE_TIN_KINH_TE', data?.content)
    } catch (e) {
      return commit('SET_RESPONSE_TIN_KINH_TE', [])
    }
  },
  async getThongTinQuyHoachHuyen({ commit }, formData) {
    try {
      const data = await this.$repositories.baiviet.thongTinQuyHoachHuyen(formData)
      return data?.content || []
    } catch (e) {
      return []
    }
  },
}
