
import compAdminMenuItem from '@/components/layouts/admin/menu-item.vue'
export default {
  name: 'compAdminMenuItem',
  components: {
    compAdminMenuItem,
  },
  props: {
    data: null,
  },
  data() {
    return {
      activeMenu: '/',
      isShow: false,
    }
  },
  methods: {
    toggleChild() {
      this.isShow = !this.isShow
    },
  },
  mounted() {
    const current = this.$router.history.current
    this.activeMenu = current.path
  },
}
