export const state = () => ({
  dataDuAnLuaChonNhaDauTu: [],
  dataChiTietDuAnLuaChonNhaDauTu: []
})

export const getters = {
  dataDuAnLuaChonNhaDauTu: (state) => {
    return state.dataDuAnLuaChonNhaDauTu
  },
  dataChiTietDuAnLuaChonNhaDauTu: (state) => {
    return state.dataChiTietDuAnLuaChonNhaDauTu
  }
}

/* action */
export const actions = {
  async getDuAnLuaChonNhaDauTu({ commit }, formData) {
    try {
      const data = await this.$repositories.duAnLuaChonNhaDauTu.luaChonNhaDauTu(formData)
      return data || []
    } catch (e) {
      return []
    }
  },
  async getChiTietDuAnLuaChonNhaDauTu({ commit }, id) {
    try {
      const data = await this.$repositories.duAnLuaChonNhaDauTu.chiTietLuaChonNhaDauTu(id)
      return data || []
    } catch (e) {
      return []
    }
  }
}
