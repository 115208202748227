import { STATUS } from '@/constants/constants';


export default function ({ $axios, $createElement, $config, app, redirect, error: nuxtError, store }, inject) {
    // Create a custom axios instance

    const api = $axios.create({
        baseURL: $config.baseUrl,
        retry: { retries: 2 }
    })

    // Add a request interceptor
    api.onRequest(function (config) {
        try {
            // check token of multi tabs
            if ((app.$auth.getToken('local')) || localStorage.getItem('auth._token.local')) {
                const token = process.client ? localStorage.getItem('auth._token.local').split(" ") : app.$auth.getToken('local').split(" ");
                config.headers.Authorization = `Bearer ${token[1]}`
            } else {
                config.headers.Authorization = null
            }
            // Do something before request is sent
            return config;
        } catch (error) {
            config.headers.Authorization = null
            return config;
        }

    }, function (error) {
        // Do something with request error
        return Promise.reject(error);
    });

    // Add a response interceptor
    api.onResponse(function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        if (response && response.data) return response.data
        return response;
    });

    api.onError((error) => {
        if (error.response.data.code === STATUS.UNAUTHORIZED) {
            app.$auth.setToken('local', false)
            app.$auth.setRefreshToken('local', false);
            app.$auth.setUser('')
            redirect('/');
        }
        return Promise.reject(error.response.data);
    })
    // Inject to context as $api
    inject('api', api)
}
