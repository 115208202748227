export const state = () => ({
  dataDuAnNghienCuuDeXuatDauTu: []
})

export const getters = {
  dataDuAnNghienCuuDeXuatDauTu: (state) => {
    return state.dataDuAnNghienCuuDeXuatDauTu
  }
}

/* action */
export const actions = {
  async getDuAnNghienCuuDeXuatDauTu({ commit }, formData) {
    try {
      const data = await this.$repositories.duAnNghienCuuDeXuatDauTu.nghienCuuDeXuatDauTu(formData)
      return data || []
    } catch (e) {
      return []
    }
  }
}
