/* action */

export const actions = {
  async khuCongNghiep({commit},dataForm){
    try {
      const data = await this.$repositories.khuCongNghiep.khuCongNghiep(dataForm);
      return data
    } catch (e) {
      return e;
    }
  },
}
