export const state = () => ({
  donViHanhChinh: [],
  linhVuc: [],
})

export const getters = {
  donViHanhChinh: (state) => {
    return state.donViHanhChinh
  },
  linhVuc: (state) => {
    return state.linhVuc
  }
}

export const mutations = {
  SET_DON_VI_HANH_CHINH(state, donViHanhChinh) {
    state.donViHanhChinh = donViHanhChinh
  },
  SET_LINH_VUC(state, linhVuc) {
    state.linhVuc = linhVuc
  }
}

/* action */

export const actions = {
  async getDonvihanhchinh({commit},ma){
    try {
      const data = await this.$repositories.donvihanhchinh.donvihanhchinh(ma);
      commit('SET_DON_VI_HANH_CHINH', data[0] && data[0] ? data[0].childrens : [])
      return data
    } catch (e) {
      return e;
    }
  },
  async getLinhVuc({commit},ma){
    try {
      const data = await this.$repositories.donvihanhchinh.linhvuc(ma);
      commit('SET_LINH_VUC', data?.content || [])
      return data
    } catch (e) {
      return e;
    }
  },
}
