/* action */

export const actions = {
  async cumCongNghiep({commit},dataForm){
    try {
      const data = await this.$repositories.cumCongNghiep.cumCongNghiep(dataForm);
      return data
    } catch (e) {
      return e;
    }
  },
  async thongKe({commit},dataForm){
    try {
      return await this.$repositories.cumCongNghiep.thongKe(dataForm);
    } catch (e) {
      return e;
    }
  },
  async cumCongNghiepById({commit},ma){
    try {
      const data = await this.$repositories.cumCongNghiep.cumCongNghiepById(ma);
      return data
    } catch (e) {
      return e;
    }
  },
}
