export default ($api) => (
  {
    cumCongNghiep: (data) => {
      return $api.get(`cum-cong-nghiep`, { params: data })
    },
    thongKe: (data) => {
      return $api.get(`thong-ke-kcn-ccn`, { params: data })
    },
    cumCongNghiepById: (ma) => {
      return $api.get(`thong-ke-kcn-ccn/${ma}`)
    }
  })
