import AuthenticateRepository from '~/repositories/AuthenticateRepository'
import BaiVietRepository from '~/repositories/BaiVietRepository'
import ThongTinDiaPhuongRepository from '~/repositories/ThongTinDiaPhuongRepository'
import MediaPortalRepository from '~/repositories/MediaPortalRepository'
import DuAnRepository from '~/repositories/DuAnRepository'
import DonViHanhChinhRepository from '~/repositories/DonViHanhChinhRepository'
import CumCongNghiepRepository from '~/repositories/CumCongNghiepRepository'
import VideoRepository from '~/repositories/VideoRepository'
import QuyHoachRepository from '~/repositories/QuyHoachRepository'
import KhuCongNghiepRespository from '~/repositories/KhuCongNghiepRespository'
import ThuVienAnhVideoRepository from '~/repositories/ThuVienAnhVideoRepository'
import DuAnNghienCuuDeXuatDauTuRespository from '~/repositories/DuAnNghienCuuDeXuatDauTuResponsitory'
import DuAnLuaChonNhaDauTuRepository from '~/repositories/DuAnLuaChonNhaDauTuRepository'
import LienHeRepository from '~/repositories/LienHeRepository'
import HeThongRepository from '~/repositories/HeThongRepository'

export default ($axios) => ({
  authen: AuthenticateRepository($axios),
  baiviet: BaiVietRepository($axios),
  diaPhuong: ThongTinDiaPhuongRepository($axios),
  mediaPortal: MediaPortalRepository($axios),
  duAns: DuAnRepository($axios),
  donvihanhchinh: DonViHanhChinhRepository($axios),
  cumCongNghiep: CumCongNghiepRepository($axios),
  khuCongNghiep: KhuCongNghiepRespository($axios),
  video: VideoRepository($axios),
  quyHoach: QuyHoachRepository($axios),
  thuVien: ThuVienAnhVideoRepository($axios),
  duAnNghienCuuDeXuatDauTu: DuAnNghienCuuDeXuatDauTuRespository($axios),
  duAnLuaChonNhaDauTu: DuAnLuaChonNhaDauTuRepository($axios),
  lienHe: LienHeRepository($axios),
  heThong: HeThongRepository($axios),
})
