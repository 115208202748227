export const state = () => ({
  dataLoaiQuyHoach: [],
})

export const getters = {
  dataLoaiQuyHoach: (state) => {
    return state.dataLoaiQuyHoach
  },
}

export const mutations = {
  SET_RESPONSE_LOAI_QUY_HOACH(state, data) {
    state.dataLoaiQuyHoach = data
  },
}

/* action */
export const actions = {
  async getLoaiQuyHoach({ commit }, formData) {
    try {
      const data = await this.$repositories.quyHoach.loaiQuyHoach(formData)
      return commit('SET_RESPONSE_LOAI_QUY_HOACH', data)
    } catch (e) {
      return commit('SET_RESPONSE_LOAI_QUY_HOACH', [])
    }
  },
  async getThongTinQuyHoach({ commit }, formData) {
    try {
      const data = await this.$repositories.quyHoach.thongTinQuyHoach(formData)
      return data?.content || []
    } catch (e) {
      return []
    }
  },
}
