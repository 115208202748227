export const state = () => ({
    engineerDetail: {

    }
})

export const getters = {
  getEngineer: (state) => {
    return state.engineerDetail
  }
}

export const mutations = {
    SET_ENGINEER(state, engineer) {
        state.engineerDetail = {...engineer}
    }
}

export const actions = {

  async get_detail({ commit }, id) {
    try{
      const data = await this.$repositories.engineer.getEngineerInfo(id)
      commit('SET_ENGINEER', data.data)
      return data.data
    }catch (e) {
      return e;
    }

    },

  async update_info_basic({ commit }, data) {
    try {
      const update = await this.$repositories.engineer.updateInfoBasic(data)
      return update
    } catch (e) {
      return e;
    }
  },

  async update_avatar({ commit }, data) {
    try {
      const update = await this.$repositories.engineer.updateAvatar(data)
      return update
    } catch (e) {
      return e;
    }
  },

  async update_resume({ commit }, data) {
    try {
      const update = await this.$repositories.engineer.updateResume(data)
      return update
    } catch (e) {
      return e;
    }
  },

    async engineer_update({ commit }, data) {
        try {
            const update = await this.$repositories.engineer.updateEngineer(data)
            return update
        } catch (e) {
            return e;
        }
    },

    async change_password({ commit }, dataForm) {
        try {
            const data = await this.$repositories.engineer.changePassword(dataForm)
            return data
        } catch (e) {
            return e;
        }
    },

    set_engineer({commit, state}, data) {
        try {
          commit('SET_ENGINEER', data)
        }catch (e) {
          return e;
        }
    },

    async change_email({ commit }, dataForm) {
      try {
        const data = await this.$repositories.engineer.changeEmail(dataForm)
        return data
      } catch (e) {
        return e;
      }
    },

  async update_cv({ commit }, data) {
    try {
      const update = await this.$repositories.engineer.updateCv(data)
      return update
    } catch (e) {
      return e;
    }
  },

    async request_change_email({ commit }, dataForm) {
      try {
        const data = await this.$repositories.engineer.requestChangeEmail(dataForm)
        return data
      } catch (e) {
        return e;
      }
    },

  async delete_cv({ commit }, data) {
    try {
      const update = await this.$repositories.engineer.deleteCv(data)
      return update
    } catch (e) {
      return e;
    }
  },

  async update_mst_skill_basic({ commit }, data) {
    try {
      const update = await this.$repositories.engineer.updateMstSkillBasic(data)
      return update
    } catch (e) {
      return e;
    }
  },

  async verified_account({ commit }, dataForm) {
    try {
      const data = await this.$repositories.engineer.verifiedAccount(dataForm)
      return data
    } catch (e) {
      return e;
    }
  },

  async update_file_skill_sheet({ commit }, data) {
    try {
      const update = await this.$repositories.engineer.updateFileSkillSheet(data)
      return update
    } catch (e) {
      return e;
    }
  },

  async delete_file_skill_sheet({ commit }, data) {
    try {
      const update = await this.$repositories.engineer.deleteFileSkillSheet(data)
      return update
    } catch (e) {
      return e;
    }
  },

  async update_test_external_sheet({ commit }, data) {
    try {
      const update = await this.$repositories.engineer.updateFileTestResult(data)
      return update
    } catch (e) {
      return e;
    }
  },

  async delete_file_test_result({ commit }, data) {
    try {
      const update = await this.$repositories.engineer.deleteFileTestResult(data)
      return update
    } catch (e) {
      return e;
    }
  },

  async check_token_change_email({ commit }, data) {
    try {
      const update = await this.$repositories.engineer.checkTokenChangeEmail(data)
      return update
    } catch (e) {
      return e;
    }
  },

}

