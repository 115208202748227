export const state = () => ({
  thongTinDiaPhuong: [],
})

export const getters = {
  thongTinDiaPhuong: (state) => {
    return state.thongTinDiaPhuong
  },
}


/* action */

export const actions = {
  async getThongTinDiaPhuong({commit}, formData){
    try {
      const data = await this.$repositories.diaPhuong.thongtindiaphuong( formData.id, { ngonNgu:formData.ngonNgu });
      return data || []
    } catch (e) {
      return e;
    }
  },
}
