
import compAdminMenuItem from '@/components/layouts/admin/menu-item.vue'
export default {
  name: 'compAdminMenu',
  components: {
    compAdminMenuItem,
  },
  data() {
    return {
      dataMenu: [
        {
          name: 'Trang chủ',
          icon: 'icon-home',
          path: '/admin',
          permission: [],
        },
        {
          name: 'Quản lý dự án',
          icon: 'icon-file-wood-sign',
          path: '/admin/du-an/danh-sach',
          permission: [],
          children: [
            {
              name: 'Thu hút trọng điểm',
              icon: 'icon-home',
              path: '/admin/thu-hut-trong-diem/danh-sach',
              permission: [],
            },
            {
              name: 'Nghiên cứu đầu tư',
              icon: 'icon-home',
              path: '#',
              permission: [],
            }
          ]
        },
        {
          name: 'Quản lý thông tin địa phương',
          icon: 'icon-file-p',
          path: '#',
          permission: [],
        },
        {
          name: 'Quản lý khu công nghiệp',
          icon: 'icon-file',
          path: '#',
          permission: [],
        },
        {
          name: 'Quản lý cụm công nghiệp',
          icon: 'icon-file',
          path: '#',
          permission: [],
        },
        {
          name: 'Quản lý doanh nghiệp ',
          icon: 'icon-file-user',
          path: '#',
          permission: [],
        },
        {
          name: 'Thông tin kinh tế - xã hội',
          icon: 'icon-increase',
          path: '#',
          permission: [],
        },
        {
          name: 'Thông tin quy hoạch',
          icon: 'icon-dashboard',
          path: '#',
          permission: [],
        },
        {
          name: 'Thư viện',
          icon: 'icon-dashboard',
          path: '#',
          permission: [],
        },
        {
          name: 'Báo cáo thống kê',
          icon: 'icon-dashboard',
          path: '#',
          permission: [],
        },
        {
          name: 'Danh mục',
          icon: 'icon-folder',
          path: '#',
          permission: [],
        },
        {
          name: 'Quản trị hệ thống',
          icon: 'icon-setting',
          path: '#',
          permission: [],
        },
      ],
    }
  },
  props: {
    isCollapse: {
      type: Boolean,
    },
  },
}
