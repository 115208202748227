const middleware = {}

middleware['check-token-email'] = require('..\\middleware\\check-token-email.js')
middleware['check-token-email'] = middleware['check-token-email'].default || middleware['check-token-email']

middleware['check-token-verified'] = require('..\\middleware\\check-token-verified.js')
middleware['check-token-verified'] = middleware['check-token-verified'].default || middleware['check-token-verified']

middleware['checkToken'] = require('..\\middleware\\checkToken.js')
middleware['checkToken'] = middleware['checkToken'].default || middleware['checkToken']

middleware['guest'] = require('..\\middleware\\guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['verify'] = require('..\\middleware\\verify.js')
middleware['verify'] = middleware['verify'].default || middleware['verify']

export default middleware
