// Japan
export const locale = {
    /* Message valid */
    M01: '必須項目に入力してください。',
  M02: '空白以外を入力してください。',
  M03: '削除するアカウントを選択してください。',
  M04: '過去の時間は選択できません。',
  M05: '必須項目となります。',
  M06: '終了時間は開始時間より早い。',
  M07: 'ファイルサイズ容量を超えております。',
  M08: '半角の数字、スペース、ダッシュを入力します。',
  M09: 'ステータスを変更してもいかがでしょうか:※ステータスは一度変更したら、戻さないでご注意ください！',
  M10: '日付が間違っています。',
  M11: 'ログインまたは会員登録をしてください。',
  M12: '番号を入力してください。',
  M13: '正しい形式でファイルをアップロードしてください。',
  M14: '削除されました。',
  M16: '保存しました。',
  M17: 'メール形式を正しく入力してください。',
  M18: '半角英数字や記号を使用し6文字以上12文字以内を入力してください。',
  M19: '「パスワード」 と 「パスワード（再入力）」 が一致しません。',
  M20: 'メールアドレスの変更が完了しました。',
  M21: 'メールアドレスが存在しておりません。登録されたメールアドレスを入力してください。',
  M22: 'このメールは既に存在していますが、ブロックや削除されたため、ログインできません。他のメールを入力してください。',
  M23: 'メールアドレスもしくはパスワードが間違っています。',
  M24: '本当にキャンセルしますか?',
  M25: '1つ以上のチェックボックスを選択してください。',
  M27: 'エンジニアがテストしているのでテストを編集することはできません。',
  M271: '管理者が更新しているので、このテストを実行できません。',
  M28: '戻ると入力したデータはなくなりますが、宜しいですか。',
  M29: 'このアイテムは削除できません。',
  M30: '新しいパスワードと既存パスワードが一致できません。もう一度入力してください。',
  M31: 'メールアドレスの変更はまだ完了していません。確認メールに記載のURLから認証をお願いします。24時間以内にメ-ルに記載されたリンクをクリックして手続きを行ってください。メールが届かない場合、迷惑メールフォルダーなどもご確認ください。',
  M32: 'パスワード再設定のメ-ルを送信します。24時間以内にメ-ルに記載されたリンクをクリックして手続きを行ってください。メ-ルが届かない場合、迷惑メールフォルダ-などもご確認ください。',
  M33: 'アカウントの作成に成功しました。',
  M34: '指定アカウントの関連データを全部削除しますが、宜しいですか。',
  M35: 'データが見つかりません。',
  M36: 'エンジニアIDが見つかりません。',
  M37: 'カタカナで入力してください。',
  M38: '目立つになるようにオンラインスキルシートを更新してください。',
  M39: '文字のみ入力可能で数字と記号などを入力しないでください。',
  M40: 'ご登録ありがとうございます。メールを送信致しましたので登録手続きを完了させるため、メールで記載されたリンクをクリックしてください。メールが届かない場合、迷惑メールフォルダーなどもご確認ください。',
  M41: '認証手続きが完了するため、メールをご確認ください。',
  M42: 'この質問の正しい答えを選択してください。',
  M43: '選択された トークン は正しくありません。',
  M44: ':attribute 文字まで入力してください。',
  M45: '無効なメールアドレス。',
  M46: '設定しました。',
  M47: '低い年齢は高い年齢より大きい。',
  M48: 'ファイルフォーマットが間違っています。',
  M49: '指定アカウントの関連データをブロックしますが、宜しいですか。',
  M50: '本当に申込をキャンセルしたいですか?',
  M51: '成功しました',
  M52: '変更しました',
  M53: '現在、システムにより問題が発生しているため、データの保存が完了していません。しばらくもう一度やり直してください',
  M54: 'データが更新されるため、画面をリフレッシュしてください。',
  M55: '申し訳ございません。年齢が合いません。',
  M56: '申し訳ございません。このパーティーは満席となります。',
    /* End message valid */
    confirm: '確認',
    title_login : 'ログイン',
    TOKEN_VALID_401: 'トークンの有効期限を切れてしました',
  REGISTER_SUCCESS: 'アカウントが作成できました。',

};
