
import { mapGetters } from 'vuex'
import { LANGUAGE } from '@/constants/language'
import langEN from '@/static/images/language.png'
import langVN from '@/static/images/language_vn.png'

export default {
  data() {
    return {
      menuMobi: false,
    }
  },
  computed: {
    ...mapGetters({
      xLanguage: 'common/xLanguage'
    }),
    textLanguage: ({ xLanguage }) => (keyName) => {
      return LANGUAGE?.[xLanguage]?.[keyName]
    },
    getDomain() {
      if (this.xLanguage === 'vi_VN') {
        return 'https://invest.quangnam.gov.vn'
      }

      return 'https://dautu.quangnam.gov.vn'
    },
    getFlag() {
      if (this.xLanguage === 'vi_VN') {
        return langEN
      }

      return langVN
    },
    getHomeClass() {
      if (this.$nuxt.$route.name === 'trangchu-demo') {
        return 'main-container'
      }
      return ''
    }
  },
  methods: {
    onCloseModal() {
      this.menuMobi = false
    },
    pushRoute(route) {
      this.menuMobi = false
      this.$router.push({ name: route })
    },
    motcuadientu() {
      window.open('http://motcua.skhdt.quangnam.gov.vn');
    },
    dichvucong() {
      window.open('https://dichvucong.quangnam.gov.vn/expway/smartcloud/icloudgate/page/home.cpx');
    },
    congthongtindt() {
      window.open('https://quangnam.gov.vn/webcenter/portal/ubnd');
    },
    congthongtindtso() {
      window.open('https://skhdt.quangnam.gov.vn/webcenter/portal/sokhdt');
    },
    congthongtindtbo() {
      window.open('https://www.mpi.gov.vn/');
    },
    hotronhadautu() {
      window.open('https://htdn.quangnam.gov.vn/Default.aspx?tabid=40');
    },
    thutuchanhchinh() {
      window.open('https://dichvucong.quangnam.gov.vn/expway/smartcloud/iprocedure/page/public/procedure.cpx');
    },
    pushRouteParam(route, params) {
      this.$router.push({ name: route, params })
    }
  }
}
