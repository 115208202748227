const resource = 'auth'
export default ($api) => (
  {
    login: (data) => {
      return $api.post(`${resource}/login`, data)
    },
    resetPassword: (data) => {
      return $api.post(`${resource}/reset-password`, data)
    },
    sendEmail: (data) => {
      return $api.post(`${resource}/send-email-reset-password`, data)
    },
    checkTokenExits: (data) => {
      return $api.post(`${resource}/check-token`, data)
    },
    getJobAll(data) {
      return $api.get(`${resource}/job-for-engineer`, { params: data })
    },
    exportResume: () => {
      return $api.get(`export-engineer-resume` , { responseType: 'blob' })
    },
    getDetail: (idHash) => {
      return $api.get(`${resource}/detail/${idHash}`)
    },
  })
