
import { mapGetters } from 'vuex';
import { LANGUAGE } from '../../constants/language';

export default {
  name: 'Footer',
  computed: {
    ...mapGetters({
      xLanguage: 'common/xLanguage'
    }),
    textLanguage: ({ xLanguage }) => (keyName) => {
      return LANGUAGE?.[xLanguage]?.[keyName]
    }
  },
  methods: {
    hotronhadautu() {
      window.open('https://htdn.quangnam.gov.vn/Default.aspx?tabid=40');
    },
  }
}
