const EN_US = {
  home: 'Home',
  name: 'Quang Nam',
  slogan: 'A land rich in potential',
  gis: 'Project map',
  tiemNang: 'Investment Opportunities',
  quyTrinh: 'Investment Procedures',
  dauTu: 'Procedures',
  gisKhu: 'Industrial Zone map',
  congNghiep: 'Industrial',
  quyHoach: 'Planning information',
  duAnThuHutDauTu: 'investment project',
  kinhTeXaHoi: 'socio-economicnews',
  uuDaiDauTu: 'Investment incentives',
  hoTroNhaDauTu: 'investment support',
  soKeHoach: 'Department of Planning and Investment',
  phienBanThuNghiem: 'Test version',
  banQuyenSo: 'Copyright belongs to the Department of Planning and Investmen',
  trungTamXucTienDauTu: 'Investment Promotion and Start-up Support Center',
  diaChi: 'Address: 02 Tran Phu, Tam Ky, Quang Nam',
  email: 'Email: htdn.skhdt@gmail.com',
  dienThoai: 'Phone number: 0235 247 4567',
  title: 'Investment promotion',
  province: 'Quang Nam',
  lienKetNhanh: 'Quick Access',
  menuGis: 'Projects Map',
  menuDANguyenCuu: 'Investment research projects',
  menuDAThuHut: 'Investment calling projects',
  menuGisKhu: 'Industrial zone map',
  menuDauTu: 'Invest in Quang Nam',
  menuTiemNang: 'Investment opportunities',
  menuDanhMucDAThuHut: 'Investment projects list',
  menuDMDALuaChon: 'Announced investor selection project list',
  menuDMDANghienCuu: 'Investment research projects list',
  menuDMDAKeuGoi: 'List of projects calling for investment',
  DMDALuaChon: 'Announced investor selection project list',
  DMDANghienCuu: 'Investment research projects list',
  DMDANghienCuuDeXuat: 'List of investment proposal research projects',
  DMDAKeuGoi: 'List of projects calling for investment',
  menuQuyTrinh: 'Investment procedures',
  menuTrinhTu: 'Investment procedures',
  menuTrinhTuLuaChon: 'Investor selection procedures ',
  menuUuDai: 'Investment incentives',
  menuNganhNghe: 'Investment incentives',
  menuNuocSach: 'Clean water',
  menuChatThaiRan: 'Solid waste',
  menuXaHoiHoa: 'Socializing',
  menuHopTacXa: 'Cooperative',
  menuNongNghiep: 'Agriculture',
  menuCacThuongHieu: 'Investor in Quang Nam',
  menuHoTroNhaDauTu: 'Investment support',
  menuThuTuc: 'Administrative procedures',
  menuMotCua: 'Electronic one-stop information system',
  menuDichVuCong: 'Public service',
  menuCongThongTinDienTuQN: 'Quang Nam Province Portal',
  menuCongThongTinDienTuSoKHDTQN: 'Quang Nam Department of Planning and Investment Portal',
  menuCongThongTinDienTuBoKHDTQN: 'Quang Nam Ministry of Planning and Investment Portal',
  menuLienHe: 'Contact',
  menuPhapLy: 'Legislation',
  menuThongTinQuyHoach: 'Planning information',
  menuThongTinKTXH: 'Socio-economic information',
  menuTaiLieuXucTien: 'Investment promotion documents',
  menuCamNang: 'Investment Handbook',
  menuThuVienAnh: 'Photo and video gallery',
  menuGisKhuCN: 'Industrial park map',
  menuGisCumCN: 'Industrial cluster map',
  menuKhuCN: 'Industrial park information',
  menuCumCN: 'Industrial cluster information',
  diaDiem: 'Address',
  dienTich: 'Area',
  viTriDiaLy: 'Geographical location',
  mucTieu: 'Target',
  thongTinDuAn: 'Project information',
  hienTrangSuDungDat: 'Current status of land use',
  danCu: 'Residential',
  loiTheDauTu: 'The advantages of investing',
  suPhuHopVoiHoSoQuyHoach: 'Conformity with planning documents, socio-economic development orientations of branches and localities',
  hieuQua: 'Efficiency of the project',
  ghiChu: 'Note',
  duAn: 'Project',
  loaiBanDo: 'Map type',
  loaiNen: 'Background map',
  banDo: 'Maps',
  veTinh: 'Satellite',
  thuongMai: 'Commerce',
  thoiGian: 'Time',
  khoangCach: 'Distance',
  thongTinKhuCongNghiep: 'Industrial Parks',
  cumCongNghiep: 'Industrial Clusters',
  giskhuCongNghiep: 'GIS KCN',
  gisCumCongNghiep: 'GIS CCN',
  banQuyen: '© 2022 Copyright belongs to Quang Nam Department of Planning and Investment',

  // Tiềm năng và cơ hội
  tiemNangVaCoHoi: 'Potential and Advantages',
  gioiThieuQuangNam: 'Situated in the center of the Central Key Economic Zone of Vietnam, Quang Nam is one of the key hubs of international transport, trade, and exchange in the Central Region and Central Highlands…',
  dienTichQuangNam: '10,438 km2',
  danSo: 'Approximately 1.5 million people',
  danSoChu: 'Population',
  donViHanhChinh: 'Administration units',
  donViHanhChinhChiTiet: '18 district-level administration units including 2 cities, 1 commune, and 15 districts. The provincial capital of Quang Nam is located in Tam Ky city. ',
  // Tiềm năng và cơ hội - Vị trí địa lý
  vitriDiaLy: 'Geographical location,',
  dieuKienTuNhien: 'natural conditions',
  cuaNgo: 'Situated in the center of the Central Key Economic Zone of Vietnam, Quang Nam is one of the key hubs of international transport, trade, and exchange in the Central Region and Central Highlands',
  khoangCachThuDo: 'Located 820 km from Hanoi capital to the North and 900 km from Ho Chi Minh city to the South along National Highway 1A.',
  giapTinhThanh: "Borders Danang city and Thua Thien - Hue province to the North, Quang Ngai province and Kon Tum province to the South, Laos border and Sekong province (People's Democratic Republic of Laos) to the West, and East Sea to the East.",
  gioBay: 'Takes only 4-5 hours to fly from Quang Nam to the most dynamic economic regions in Asia such as South Korea, China, and Japan.',
  // Tiềm năng và cơ hội - Hạ tầng
  haTang: 'Infrastructure',
  sanBayChuLai: 'Chu Lai Airport is planned to become a 4F-class International Airport. Chu Lai Port is planned to receive ships of 50,000 tons. It is near Danang International Airport and Tien Sa - Danang Port receiving ships of 50,000 tons.',
  tuyenBacNam: 'North-south route, national highway 1a, danang - quang ngai expressway, hoi an - chu lai coastal route,  north-south railway',
  tuyenDongTay: 'National highway 14b/14e/40b/ provincial road 617',
  dienNuocVienThong: 'Modern systems for electricity, water, and telecommunications are invested within the project boundary.',
  // Tiềm năng và cơ hội - Danh lam
  danhLam: 'Tourist attractions',
  boBien: '125km coastline with many famous and stunning beaches such as Ha My, An Bang (Dien Ban), Cua Dai (Hoi An), Binh Minh (Thang Binh), Tam Thanh (Tam Ky), Bai Rang (Nui Thanh), and so on.',
  diSanVanHoa: '2 World Cultural Heritages including Hoi An, My Son, and Cu Lao Cham - Hoi An Biosphere Reserve.',
  diSanPhiVatThe: 'Bai Choi as an Intangible Cultural Heritage of Humanity and 9 National Intangible Cultural Heritages. ',
  diTichLichSu: 'Over 300 historical and cultural sites, tourist attractions, traditional craft villages, and folk culture festivals have existed for 500 years.',
  hoPhuNinh: 'Like the mini version of Ha Long Bay, Phu Ninh Lake has 30 small and big islands such as Rua Island, Ong Khi, Su Island, and so on. Each island is covered with many green trees, creating a majestic landscape',
  heThongSongLon: 'Many large river systems such as Vu Gia, Co Co, Thu Bon, Truong Giang, and so on make connections between local areas to develop riverside tourism.',
  datQuangNam: "Currently, Quang Nam has 680,249.67ha of forested land, including 463,356.77ha of natural forest and 216,829.94induha of forested plantations. The province's forest coverage reaches 58.61 percent, of which natural forest coverage reaches 43.82 percent. The forest has many rare medicinal herbs such as Ngoc Linh Ginseng, Tra My Cinnamon, Ba Kich, Dang Sam, and so on.",
  monAnNgon: 'Quang Nam specialties such as Quang noodles, Cau Mong veal, Cao Lau, hemp leaves little cakes, chicken rice, and so on are among the top 50  special foods of Vietnam',
  soLuongLaoDong: 'Over 900 thousand people of working age (about 60% of the population)',
  truongDaiHocCaoDang: '9 universities and colleges',
  truongTrungCap: '4 middle schools',
  trungTamGiaoDuc: '17 vocational education and training centers',
  nguonNhanLuc: 'High-quality human resources',
  khuKTvaKhuCN: 'Economic Zones, Industrial Parks, and Industrial Clusters',
  cacKhuKT: 'Economic Zones and Industrial Parks in Quang Nam province with a total natural area of about 60,000ha, operate in multiple industries including non-tariff zones of Chu Lai airport; non-tariff zones of Chu Lai port; ports and logistics services; Industrial Parks, urban areas, tourist areas, and Nam Giang international border gate economic zone.  In which :',
  khuKTChuLai: 'Chu Lai Open Economic Zone',
  khuKTChuLaiChiTiet: 'Chu Lai Open Economic Zone with a total natural area of 27,040ha is the center of the automobile industry and ancillary industry for the automobile and aviation industry; the center for gas - electricity, and petrochemical products; the textile industry and its auxiliary industry, other industries with high technology and well-qualified human resources; production center for organic farming, hi-tech agriculture; non-tariff zones and logistics mining Chu Lai airport and Chu Lai - Ky Ha port system; concentrated tourist areas with a total area of about 2,475ha. The total land area for urban development of about 10,205ha, including Urban Areas such as Nui Thanh, Tam Anh, Dong Tam Ky, Dong Nam Thang Binh, and so on.',
  khuKTNamGiang: 'Nam Giang International Border Gate Economic Zone',
  khuKTNamGiangChiTiet: 'Nam Giang International Border Gate Economic Zone is located on the economic corridor of Da Nang - Southern Laos and Northeast Thailand. With a total natural area of 31,060ha, its development orientation is to become a service and trade area, a logistics area, a non-tariff zone combining the development of agriculture, forestry, tourism, etc;  the center line linking the area with inter-regional transport hubs along the National Highway 14D.',
  cacKhuCN: 'Industrial Parks',
  cacKhuCNChiTiet: '18 Industrial Parks. The total area of  4,702.65ha. 11 Industrial Parks in Chu Lai Open Economic Zone with a total  area of 2,959.89ha are licensed  (the remaining industrial land area is 1,990.11ha) The occupancy rate of operational Industrial Parks reaches 42 %. Orientations for investment attraction: Priorities to attract projects with advanced technology, new technology, high technology, modern management, high added value, and spillover effects making connections between global production and supply chain.03 Industrial Parks outside Chu Lai Open Economic Zone with a total area of 716.76ha are licensed (the remaining industrial land area is 435.24ha). 04 Industrial Parks with a total area of 1,026ha are applying for investment policies.',
  cacKhuCnHoatDong: 'Industrial Parks put into operation',
  khuCNChuLai: 'Chu Lai - Truong Hai Automobile - Mechanical Industrial Park 243.3ha',
  khuCNDienNam: 'Dien Nam - Dien Ngoc Industrial Park 357.08ha',
  khuCNTamThang: 'Tam Thang Industrial Park 197.1ha',
  khuCNBacChuLaiDienTich: 'Bac Chu Lai industrial park 361.4ha',
  khuCNBenCang: 'Chu Lai - Truong Hai port logistics industrial zone 142.43ha',
  khuCNTamHiep: 'Tam Hiep port logistics industrial zone 417ha',
  khuCNDongQueSon: 'Dong Que Son Industrial Park 211.26ha',
  khuCNThuanYen: 'Thuan Yen Industrial Park 148.42ha',
  khuCNTamThang2: 'Tam Thang 2 Industrial Park 103ha',
  khuCNTamAnh: 'Tam Anh - Korea Industrial Park 193.05ha',
  cacKNCDangTrienKhai: 'Industrial Parks implemented investment',
  khuCNDTKThaco: 'Thaco - Chu Lai Industrial Park 415.86ha',
  khuCNDTKTamAnh: 'Tam Anh - An An Hoa Industrial Park 435.8ha',
  khuCNDTKTamAnh1: 'Tam Anh 1 Industrial Park 167.05ha',
  khuCNDTKTamThang: 'Expanded Tam Thang Industrial Park 248ha',
  cacKCNDangXinChuTruong: 'Industrial zones applying for investment policies',
  khuCNDXCTNamThangBinh: 'Nam Thang Binh Industrial Park estimated 499ha',
  khuCNDXCTBacThangBinh: 'Bac Thang Binh Industrial Park estimated 239ha',
  khuCNDXCTPhuXuan: 'Phu Xuan Industrial Park estimated 108ha',
  khuCNDXCTTamAnh3: 'Tam Anh 3 Industrial Park estimated 190ha',
  cacKCN: 'Industrial clusters',
  cacKCNChiTiet: 'Currently, the province is planning 93 industrial clusters with a total area of 2,759.82ha.  There was a decision to establish 59 industrial clusters with a total area of 1,678.5ha and an industrial land area of 1,223.12ha; of which 51 industrial clusters with a total area of industrial land for lease of 493.85ha have been established, detailedly planned, and put into operation, the remaining area not leased is 577.58ha;  the occupancy rate reached 48.28% (493.85ha/ 1,022.79ha).',
  dinhHuongThuHutDauTu: 'Orientations for investment attraction',
  dinhHuongDauTu: 'Industrial clusters invested and put into operation are orientated to attract secondary investors to fill 100% of Industrial clusters.',
  dinhHuongDauTuXayDung: 'Industrial clusters not invested in the construction are orientated to attract enterprises to become investors in the technical infrastructure business.',
  dinhHuongPhatTrien: 'DEVELOPMENT ORIENTATIONS FOR ATTRACTING INVESTMENT',
  vaoQuangNam: 'IN QUANG NAM',
  dinhHuongPhatTrienChiTiet1: 'In recent years, on receiving investment capital from domestic and foreign enterprises on an increasingly larger scale, Quang Nam has become a center for investment attraction in the Central region in particular and in the country in general. This is a big motivation for the development of the province in the coming period, contributing to the fast and sustainable development, especially in adapting to the epidemic and new socio-economic conditions.',
  dinhHuongPhatTrienChiTiet2: 'Currently, Quang Nam focuses on attracting and promoting industries and fields in which the province has advantages, connecting with localities adjacent to the Central key economic zone and gaining many benefits from international economic integration, especially attracting investment in Chu Lai Open Economic Zone and Nam Giang International Border Gate Economic Zone to exploit the potential and advantages of the East-West Economic Corridor. It combines investment promotion activities with trade and tourism promotion activities and external propaganda programs to make effective use of resources and opportunities to promote investment. It focuses on monthly dialogue activities with enterprises and investors to promptly address their difficulties, problems, proposals, and recommendations. It actively supports projects, especially key projects with large scale and big motivation to create a ripple effect for other projects to develop.',
  veCongNghiep: 'About Industry',
  veCongnghiepChiTiet: 'To exploit the industrial development potential, Quang Nam has been planning and orienting to invest in the production in clusters with modern technology, green industry with few employees; orienting to form an eco-industrial park system based on Government regulations and the set of criteria of Quang Nam on ecological industry; strongly developing the automobile industry and the aviation industry to form a multi-purpose mechanical center in the Central region as well as the clusters of electrical and electronics industries, ancillary industry for the textile industry, food and beverage industry. To attract investment in Industrial Parks, industries must be advanced ones contributing to big budget and high efficiency per unit of land covered. Mining and mineral processing industries must innovate technology, process deeply, and not harm the environment. Quang Nam should resolutely say no to projects with a high risk of environmental pollution, over-exploitation of natural resources, and outdated technology.',
  veDuLich: 'About tourism and services',
  veDuLichChiTiet1: 'With a combination of geographical and cultural factors of all regions including mountains - hills - deltas - seas, the tourism development orientations of Quang Nam are to be green tourism; build Quang Nam into a tourist service center of the Central region and the whole country;  build a national and international brand, tourism products; focus on developing tourism to the South and West of the province;  gradually implement digital transformation in the tourism industry, diversify the tourism market, in which it strongly develops the domestic tourism market, cooperates and connects with localities, ensures adaptation to the negative impacts of economic crises or global epidemic.',
  veDuLichChiTiet2: 'For services, priorities are to develop tourism services, healthcare, vocational training, and logistics; form non-tariff zones and international trading floors; support Quang Nam to become a high-quality vocational training center in the Central Region. Especially, with its favorable geographical location and natural conditions, Quang Nam has a great opportunity to strongly develop transportation services on roads, railways, sea lanes, waterways, and airways. With its unique characteristics, Quang Nam can establish a key multimodal transportation center in the country.',
  veNongNghiep: 'About agriculture',
  veNongNghiepChiTiet1: 'Orientations are to develop high-tech agriculture (including farming, animal husbandry, forestry, and fishery) and the value-chain linkages of sustainable production - processing - consumption; ensure adaptation to climate change; adopt policies attracting enterprises strongly to invest in the production and deep processing of agricultural products to reorganize production based on strong and effective linkages with cooperatives and rural households, form large specialized cultivation areas, industrial and semi-industrial livestock farms; strongly convert small timber plantations into large timber plantations, fruit trees, and some major medicinal herbs, combining with deep processing to create.',
  veNongNghiepChiTiet2: 'High added value; reduce the proportion of aquaculture, increase the proportion of offshore fishing combined with deep processing and synchronous fisheries infrastructure. Other orientations are to encourage and support the outstanding development of start-up activities associated with promoting the production of OCOP products with high quality and large scale to supply key domestic markets.',

  // Ngành nghề
  doiTuongApDung: 'Subjects of application',
  duAnDauTu: 'Investment projects in industries and professions with investment incentives',
  nganhNghe: 'Industries and professions',
  nganhNgheDacBietUuDai: 'Industries and professions with special investment incentives',
  phuLuc: '(Decree No. 31/2021/ND-CP detailing and guiding the implementation of a number of articles of The Investment Law)',
  duAnDTCacDiaBanUuDai: 'Investment projects in areas entitled to investment incentives',
  diaBanKhoKhan: 'Areas with difficult socio-economic conditions',
  diaBanDacBietKhoKhan: 'Areas with extremely difficult socio-economic conditions.',
  khuKinhTe: 'Economic Zones, Hi-Tech Zones, and Industrial Parks',
  chinhSachUuDaiDauTu: 'INVESTMENT INCENTIVE POLICIES IN ECONOMIC ZONES AND INDUSTRIAL PARKS IN QUANG NAM',
  uuDaiThueThuNhap: 'Incentives on corporate income tax',
  soUuDaiThueThuNhap: '(Decree No. 218/2013/ND-CP, Decree No. 91/ 2014/ND-CP, Decree No. 12/2015/ND-CP, Decision No. 29/2021/QD-TTg)',
  thueThuNhapDoanhNghiep: 'Corporate income tax',
  linhVucDiaBan: 'Investment fields and areas ',
  thueThuNhap: 'Incentives on corporate income tax',
  thueSuat: 'Tax rate',
  thoiGianMienGiam: 'Exemption  and reduction time ',
  diaBanKTXHKhoKhan: 'Areas with difficult socio-economic conditions ',
  muoiNam: '17% (10 Years)',
  mienGiam: 'Exemption for 2 years and 50% reduction for the next 4 years',
  diaBanKTKhoKhan: 'Areas with extremely difficult socio-economic conditions',
  muoiLamNam: '10% (15 years)',
  mienBonNam: 'Exemption for 4 years, 50% reduction for the next 9 years',
  uuDaiThueNhapKhau: 'Import tax incentives',
  soUuDaiThueNhapKhau: '(Decree No. 134/2016/ND-CP)',
  hangHoaNhapKhau: 'Goods imported to create fixed assets of an investment project whose part is eligible for investment incentives are entitled to import tax exemption for imported goods distributed and accounted separately for direct use for the part of the project eligible for investment incentives.',
  duAnDauTuKhoKhan: 'Investment projects in areas with extremely difficult socio-economic conditions are entitled to import tax exemption for domestic raw materials, supplies, and components imported for production within 5 years from the date of commencement of production.',
  uuDaiDatDai: 'Incentives on land',
  soUuDauDatDai: '(Decree No. 46/2014/ND-CP, Decree No. 135/2016/ND-CP)',
  duAnDauTuHaTangKCN1: 'Infrastructure investment projects in Industrial Parks and functional zones in Economic Zones are exempted from land rent after 15 years of capital construction if they are invested in district-level areas with difficult socio-economic conditions',
  duAnDauTuHaTangKCN2: 'Infrastructure investment projects in Industrial Parks and functional zones in Economic Zones are exempt from land rent after the whole lease period of capital construction if they are invested in district-level areas with extremely difficult socio-economic conditions.',
  hoTroNhaNuoc: 'State support',
  soHoSoHoTroNhaNuoc: '(Decree No. 32/2017/ND-CP)',
  duAnDauTuNhaNuoc: 'Credit incentives: Investment projects in areas with difficult socio-economic conditions and extremely difficult socio-economic conditions are eligible for State’s investment credit loans',
  chinhSachDauTuKKT: 'INVESTMENT INCENTIVE POLICIES IN ECONOMIC ZONES AND INDUSTRIAL PARKS IN QUANG NAM',
  duAnDauTuKinhDoanh: 'Industrial park infrastructure business investment projects',
  coSoPhapLy: 'Legal basis',
  nghiDinh1: 'Decree No. 46/2014/ND-CP dated May 15, 2014',
  nghiDinh2: 'Decree No. 135/2016/ND-CP dated September 9, 2016',
  nghiDinh3: 'Decree No. 35/2017/ND-CP dated April 3, 2017 (For Industrial Parks in Economic Zones)',
  diaBanKhuKTXHKhoKhan: 'Areas with extremely difficult socio-economic conditions',
  mienThueDat: 'Exemption from land rental during the period of capital construction (maximum of 03 years from the date of issuance of the land lease decision of a competent state agency)..',
  mienToanBo: 'Exemption from land rent for the whole lease period',
  diaBanKTXHKhoKhan2: 'Areas with difficult socio-economic conditions ',
  mienTienThueDat: 'Land rental exemption for 11 years after the land rental exemption period during the period of capital construction',
  mienTienThue15Nam: 'Land rent exemption for 15 years after the land rental exemption period during the period of capital construction',
  khongThucDiaBanDauTu: 'Areas not eligible for investment incentives',
  danhMucDiaBanUuDaiDauTu: 'Investment incentive areas in quang nam (decree no. 31/20221/nd-cp of government dated march 26, 2021)',
  duAnThuCap: 'Secondary project in industrial park',
  nhom: 'Group',
  khuCongNghiep: 'Industrial Parks',
  cumCongNghiepTag: 'Industrial Clusters',
  uuDai: 'INCENTIVES',
  khuCNBacChuLai: 'Bac Chu Lai Industrial Park ',
  uuDaiThueDoanhNghiep: 'Incentives on corporate income tax',
  uuDaiThueDoanhNghiepChiTiet1: 'Tax rate of 10% for a period of 15 years (maximum of 30 years by the Prime Minister for projects with special investment attraction, large scale, and high technology).',
  uuDaiThueDoanhNghiepChiTiet2: 'Tax exemption for 4 years, 50% tax reduction for 9 years.',
  uuDaiThueNhapKhauChiTiet1: 'Import Tax exemption for goods imported to create fixed assets.',
  uuDaiThueNhapKhauChiTiet2: 'Import Tax exemption for raw materials, supplies, and components for a period of 5 years.',
  khongCo: 'none',
  kcnOToChuLaiTruongHai: 'Chu Lai - Truong Hai Automobile - Mechanical Industrial Park',
  kcnCangTamHiep: 'Tam Hiep port logistics industrial zone',
  kcnTamAnhHanQuoc: 'Tam Anh - Korea Industrial Park',
  kcnThaCoChuLai: 'Thaco Chu Lai Industrial Park',
  kcnTamAnhAnHoa: 'Tam Anh - An An Hoa Industrial Park',
  kcnTamAnh1: 'Tam Anh 1 Industrial Park',
  kcnTamThang: 'Tam Thang Industrial Park',
  uuDaiThueThuNhapDoanhNghiep: 'Incentives on CIT: Tax exemption for 2 years, 50% reduction for 4 years (tax rate of 20%)',
  uuDaiThueNhapKhauChiTiet: 'Incentives on Import Tax: Import Tax exemption for goods imported to create fixed assets',
  uuDaiVeDatDai: 'Incentives on land',
  kcnTamThang2: 'Tam Thang 2 Industrial Park',
  kcnTamThangMoRong: 'Expanded Tam Thang Industrial Park',
  kcnDienNgoc: 'Dien Nam Dien Ngoc Industrial Park',
  kcnQueSon: 'Dong Que Son Industrial Park',
  kcnThuanYen: 'Thuan Yen Industrial Park',
  kcnThuanYenChiTiet1: 'Exemption from land rental during the period of capital construction from the date of land allocation, up to 3 years.',
  kcnThuanYenChiTiet2: 'Three (3) years for projects on the list of fields eligible for investment incentives.',
  kcnThuanYenChiTiet3: 'Eleven (11) years for investment projects on the list of sectors entitled to special investment incentives',
  chuDauTu: 'Investor',
  linhVucDauTu: 'Investment sector',
  tongDienTich: 'Total area',
  dienTichChoThue: 'Rental area',

  // SEQUENCE, PROCEDURES FOR INVESTMENT
  trinhTuThuTucDauTuTTitle: 'SEQUENCE, PROCEDURES FOR INVESTMENT IN QUANG NAM',
  titleCacBuocTTTTDauTu: 'Steps to implement an investment project',
  hinhAnhBuocThucHien: 'investment-steps.jpg',
  titleTTTTDauTu: [
    'I. General regulations',
    `II. Investment projects under the authority to approve investment policies of the Provincial People's Committee`
  ],
  subTitleQDChung: [
    '1. Applicable objects',
    '2. Investment registration, guidance, support, supervision and evaluation agency',
    '3. Investor selection form',
    '4. Steps to implement an investment project'
  ],
  dSDoiTuongTTTTDauTu: [
    'Investors implementing investment projects in Quang Nam',
    'State agencies related to investment activities of investors in Quang Nam',
    'Other related individuals and organizations',
    'PPP investment projects'
  ],
  coQuanDauTu: [
    'a. Investment registration agency:',
    'b. Investment guidance, support, supervision and evaluation agency'
  ],
  dSCoQuanDKDauTu: [
    'Department of Planning and investment',
    'Management Board of EZs and Industrial Parks of Quang Nam Province'
  ],
  dSCoQuanHuongDanTTTTDauTu: [
    {
      tenCQ: 'Department of Planning and investment',
      motaCQ: 'The agency clues integrated in guiding, supporting, monitoring and evaluating investment activities.'
    },
    {
      tenCQ: 'Management Board of Economic Zones and Industrial Parks of Quang Nam Province',
      motaCQ: 'Guide, support, monitor and evaluate investment activities in economic zones and industrial zones in the province.'
    },
    {
      tenCQ: 'Departments',
      motaCQ: 'Guide, support, monitor and evaluate investment activities according to the functions and tasks of State management of the sector.'
    },
    {
      tenCQ: `District People's Committee`,
      motaCQ: 'Guide, support, monitor and evaluate investment activities in the area under management.'
    }
  ],
  dSHinhThucLuaChonNhaDT: [
    'Auction of land use rights in accordance with the law on land.',
    'Bidding to select investors in accordance with the law on bidding.',
    'Select investors according to the criteria.',
    'Approving the investment policy and at the same time approving the investor.'
  ],
  motaCacBuocThucHienDADT: 'Investment projects of investors implemented in Quang Nam province shall be implemented according to the following steps:',
  dSCacBuocThucHienDADT: [
    'Prepare and approve the investment project list or agree on the principle of investment location.',
    'Approving investment policies, selecting investors for projects that require the State to allocate or lease land or permit the change of land use purpose.',
    'Investment registration certificate (if any).',
    'Carry out procedures to ensure the implementation of investment projects (if any).',
    'Appraisal and approval of detailed construction planning, announcement of planning, agreement on connection of the project to the road system (if any).',
    'Carry out environmental procedures.',
    'Notice of land acquisition, decision on land acquisition, implementation of site clearance.',
    'Appraisal of basic design, approval of related infrastructure connection design.',
    'Carry out land procedures.',
    'Appraisal and design of construction drawings, construction permits, construction permits in road corridors, permits for connection to the traffic system (if any).',
    'Deploy construction, pre-acceptance test and put the works into operation according to the provisions of law (if any).'
  ],
  subTitleDADTTinh: [
    `1. Projects under the authority to approve investment policies of the Provincial People's Committee`,
    '2. Issuance of Investment Registration Certificate',
    '3. Ensuring the implementation of investment projects',
    `4. Projects under the National Assembly's authority to approve investment policies`,
    `5. Projects under the Prime Minister's authority to approve investment policies`
  ],
  dSDuAnThuocTinh: [
    'An investment project that requires the State to allocate or lease land without land use right auction or investor selection bidding.',
    `Investment project that requires the State to allocate or lease land from households or individuals that are subject to a written approval from the Provincial People's Committee in accordance with the land laws.`,
    'Investment projects that require the State to allocate or lease land are not eligible for transfer of land use rights and land-attached assets.',
    `Investment project with a request to change the land use purpose is required to have a written permission for the change of land use purpose issued by a competent State agency in accordance with the law on land, except for the following cases: change the land use purpose of households and individuals that are not subject to the approval of the Provincial People's Committee according to the provisions of the law on land.`,
    'Investment projects on construction of houses (for sale, lease, lease-purchase), urban areas in the following cases: investment projects with land use scale of less than 50 hectares and population size below 15,000 people in urban areas; investment projects with a land use scale of less than 100 hectares and a population of less than 10,000 people in non-urban areas; investment projects regardless of the size of the land area, the population in the restricted development area or the historic inner city (defined in the urban planning project) of the special-class urban area.',
    'Investment project on construction and business of golf courses (golf).',
    'Projects of foreign investors or foreign-invested economic organizations that request the State to allocate or lease land or permit the change of land use purpose in islands and communes, wards and townships. border; coastal communes, wards and towns; other areas affecting national defense and security as defined in Clause 8 Article 2 and Point dd Clause 2 Article 98 of Decree No. 31/2021/ND-CP dated March 26, 2021 of the Government or opinions of the Ministry of National Defense and the Ministry of Public Security as prescribed at Point dd, Clause 2, Article 98 of Decree No. 31/2021/ND-CP dated March 26, 2021 of the Government.',
  ],
  capGiayCNDKDT: [
    '2.1. Cases in which the procedures for issuance of an Investment Registration Certificate:',
    '2.2. The sequence carry out the procedure issuance of an Investment Registration Certificate'
  ],
  dSTHCapGiayCNDKDT: [
    'Investment projects of foreign investors and foreign-invested economic organizations specified in Clause 1, Article 23 of the Law on Investment that request the State to allocate or lease land or permit the change of land use purpose; use land that has been approved by the competent authority for investment policy.',
    'Investment projects of foreign investors and foreign-invested economic organizations specified in Clause 1, Article 23 of the Law on Investment do not request the State to allocate or lease land or permit the change of land use purpose land use (renting premises, renting locations of organizations and individuals).',
    'Projects of domestic investors, foreign investors and foreign-invested economic organizations specified in Clause 1, Article 23 of the Law on Investment implemented in industrial parks and industrial clusters that already exist. investors, construction and infrastructure business.',
    'Projects of domestic investors that have been allocated or leased land by the State and that investors wish to be granted investment registration certificates.',
    'In other cases, procedures for issuance of an Investment Registration Certificate must be carried out in accordance with law.',
  ],
  trinhTuTHTTCapGiayCNDKDT: [
    'a) For the case specified at Point a, Section 2.1:',
    `b) For the case specified at points b, c, d and item 2.1, the investor shall submit an application for an investment registration certificate as prescribed in Clause 1, Article 33 of the Law on Investment to the investment agency. investment registration; within 15 working days from the date the investment registration authority receives the investor's valid application, the investment registration authority shall issue the investment registration certificate.`
  ],
  dSTrinhTuTHTTCapGiayTaiDiemA: [
    `For an investment project whose investment policy is approved concurrently with the investor's approval, based on the decision approving the investment policy and concurrently approving the investor, the investment registration agency shall issue the investment policy certificate. receive investment registration within 05 working days from the day on which the decision on approval of investment policy and investor approval is received.`,
    `For investment projects already approved by the investor, projects in which the investor has won the auction or the bidding, the investor shall submit a written request for issuance of the Investment Registration Certificate, made according to form A.I.6 Appendix issued together with Circular No. 03/2021/TT-BKHDT dated April 9, 2021 of the Minister of Planning and Investment for the investment registration agency; Within 05 working days from the date of receiving the investor's written request, the investment registration agency shall issue the Investment Registration Certificate.`,
    'Management Board of Economic Zones and Industrial Parks of Quang Nam Province approves the investor, the Management Board of Economic Zones and Industrial Parks of Quang Nam Province decides to approve the investor. concurrently with the issuance of the Investment Registration Certificate.'
  ],
  dSBaoDamTHDADT: [
    'Cases in which procedures must be carried out to ensure the implementation of an investment project are specified in Clause 1, Article 43 of the Law on Investment.',
    'Procedures for ensuring the implementation of investment projects comply with the provisions of Article 26 of Decree No. 31/2021/ND-CP dated March 26, 2021 of the Government.'
  ],
  dSLoaiDADTQuocHoi: [
    'a) Investment projects that have a great impact on the environment or have the potential to seriously affect the environment, including:',
    'b) Investment projects that require change of land use purpose for wet rice cultivation from 02 crops or more with a scale of 500 hectares or more.',
    'c) Investment projects that require resettlement of 20,000 people or more in mountainous areas, and 50,000 or more people in other areas.',
    'd) Investment projects that require the application of special mechanisms and policies need to be decided by the National Assembly.'
  ],
  dSDADTQuocHoiLoaiMot: [
    'Nuclear power plants.',
    'The investment project requires the change of land use purpose of special-use forest, watershed protection forest or border protection forest of 50 hectares or more; protective forests against wind, flying sand and protection forests against waves and sea encroachment of 500 ha or more; production forests of 1,000 hectares or more.'
  ],
  moTaDADTChinhPhu: 'Except for investment projects under the competence of the National Assembly to approve investment policies, the Prime Minister shall approve investment policies for the following investment projects:',
  dSLoaiDADTChinhPhu: [
    'a) An investment project, regardless of capital source, falls into one of the following cases:',
    'b) Investment projects of foreign investors in the field of telecommunications service business with network infrastructure, afforestation, publishing and press.',
    `c) An investment project is concurrently under the authority to approve investment policies of two or more provincial-level People's Committees.`,
    'd) Other investment projects falling under the competence of the Prime Minister to approve investment policies or investment decisions in accordance with law.'
  ],
  dSDADTChinhPhuLoaiMot: [
    'Investment projects that require resettlement of 10,000 people or more in mountainous areas, and 20,000 or more people in other areas.',
    'New construction investment projects: airports; runways of airports; passenger terminals of international airports; cargo terminals of airports or airfields with a capacity of 01 million tons/year or more.',
    'New investment project for passenger transport business by air.',
    'New construction investment projects: wharves, port areas belonging to special seaports; wharves and port areas with an investment capital of 2,300 billion VND or more belong to class I seaports.',
    'Oil and gas processing investment project.',
    'Investment projects involving betting and casino business, except for the business of prize-winning electronic games for foreigners.',
    'Investment projects on construction of houses (for sale, lease, lease-purchase), urban areas in the following cases: investment projects with a land use scale of 50 hectares or more or with a scale of less than 50 ha but the population size is 15,000 or more in urban areas; investment projects with a land use scale of 100 hectares or more or less than 100 hectares but with a population of 10,000 or more in non-urban areas; investment projects, regardless of the size of the land area, the population within the protection scope of the relic recognized by the competent authority as a national relic or a special national relic.',
    'Investment projects on construction and business of infrastructure of industrial parks and export processing zones.',
  ],

  // READMORE
  thuGonBtn: 'Collapse',
  xemThemBtn: 'Read more',
  xemTatCaBtn: 'View all',

  // TRÌNH TỰ, THỦ TỤC LỰA CHỌN ĐẦU TƯ
  mainTitleLCDT: 'Process and procedure for the selection of investors to implement investment projects in Quang Nam province',
  mainSubTitleLCDT: `Decision No. 2132/QĐ-UBND dated July 30, 2021, of the People's Committee of Quang Nam Province`,
  menuLCDT: 'Main Content',
  listMenuLCDT: [
    {
      id: 'duancosudungdat',
      ten: 'I. Investment projects using land'
    },
    {
      id: 'duantheoluat',
      ten: 'II. Projects according to specialized law, socialization law'
    },
    {
      id: 'tieuchidautu',
      ten: 'III. The project selects investors according to the criteria'
    }
  ],
  subListMenuLCDT: [
    '1. Adjustment scope, applicable objects',
    '2. Conditions for project identification',
    '3. Create, approve and announce the project list',
    '4. Prepare, submit project registration documents',
    '3. Develop, publish the content calling for investment in the project and the set of criteria for investor selection'
  ],
  duAnCoSDDat: {
    listPhamViDCDT: {
      mota: 'Investment projects using land to build residential areas or urban areas; construction of civil works with one or more functions, including: commercial houses; office headquarters; commercial and service works (referred to as "investment projects using land in case the State recovers land"), in which:',
      listPV: [
        `Commercial housing: is a house invested and built for sale, lease, or lease-purchase according to the market mechanism.`,
        `Headquarters and offices: buildings used as headquarters and offices of enterprises and other organizations and individuals; buildings used as offices and accommodation.`,
        `Commercial construction: Trade centers, supermarkets, markets, shops; restaurants, food and beverage shops and other commercial buildings.`,
        `Service construction: hotels, guest houses, motels; resort facilities; accommodation villas, accommodation apartments and other accommodation service establishments.`
      ]
    },
    listDieuKienXDDA: {
      mota: 'Investment projects using land in case the State recovers land must select investors when meeting the following conditions:',
      listDK: [
        'Belonging to the projects specified in Section 1 above without using public investment capital to build the construction. Projects on construction of residential areas, urban areas, and commercial houses; office headquarters; Commercial and service works are classified according to the provisions of the law on construction. In case the project has many functions, the investment project using land shall be determined on the basis of the main works of the project or the main works with the highest grade in case the project has many main works as prescribed by law. construction law.',
        `Belonging to the list of projects that need to be recovered land approved in accordance with the land law, belonging to the land area being managed and used by the State that will be approved by the Provincial People's Committee or the Management Board of Economic Zones Industrial land allocation, land lease to investors is approved.`,
        `Belongs to the housing development program and plan in accordance with the housing law; urban development program (if any) in accordance with the law on urban development.`,
        `In accordance with the socio-economic development plan, construction planning with the rate of 1/2000 or 1/500 (if any) or urban zoning planning with the rate of 1/2000 or the rate of 1/500. 1/5000 as prescribed by law.`,
        `Not enough condition to meet the conditions to organize an auction of land use rights in accordance with the law on land.`,
        'Not belonging to the cases specified at Point b, Clause 4, Article 22 and Article 26 of the Bidding Law.'
      ]
    },
    listChuanBiHoSoDKTH: [
      `The investor is responsible for preparing and submitting the application for registration of project implementation on the national bidding network system based on the published information as prescribed in Point c, Section 3.3. An investor's application for project implementation registration includes: A written registration for project implementation; profile of the investor's legal status, capacity, experience; other relevant documents (if any).`,
      'The minimum time for an investor to prepare a profile of capacity, experience and register for project implementation is 30 days from the date the project list is posted. At the end of the project implementation registration period, the Department of Planning and Investment or the Management Board of Economic Zones and Industrial Parks (for projects implemented in the areas under the management of the Management Board of the Economic Zones and Industrial Parks) organizes preliminary assessment of capacity and experience of investors who have submitted application for project implementation registration on the national bidding network system.'
    ]
  },
  duAnTheoLuat: {
    listPhamViDCDT: {
      mota: 'The project is not specified in Section 1, Part I, but must organize the selection of investors in accordance with the provisions of specialized law, law on socialization (referred to as "project under specialized law, law socialization"'
    },
    listDieuKienXDDA: {
      listDK: [
        'Projects in accordance with specialized laws need to organize bidding to select investors.',
        'The project is entitled to policies to encourage socialization according to the provisions of Decree No. 69/2008/ND-CP dated May 30, 2008, Decree No. 59/2014/ND-CP dated June 16, 2014 of the Government. Government is on the list of types, criteria, scale and standards promulgated by the Prime Minister in Decisions No. 1466/QD-TTg dated October 10, 2008, No. 693/QD-TTg dated May 6, 2013, No. 1470/QD-TTg dated February 27,2016.'
      ]
    },
    listChuanBiHoSoDKTH: [
      `4.1. Based on the information disclosed as prescribed at Point b, Section 3.3, the investor shall prepare and submit the application for registration of project implementation directly at the Department of Planning and Investment or the Management Board of Economic Zones. Economic Zones and Industrial Parks (for projects subject to approval of investment policies, implemented in areas under the management of the Management Board of Economic Zones and Industrial Parks). An investor's application for project implementation registration includes: A written registration for project implementation; profile of the investor's legal status, capacity and experience; other relevant documents (if any).`,
      '4.2. The minimum time for an investor to prepare a profile of capacity, experience and register for project implementation is 30 days from the date the project list is posted. At the end of the project implementation registration deadline, the Department of Planning and Investment or the Management Board of Economic Zones and Industrial Parks (For projects implemented in areas under the management of the Management Board of Economic Zones and Industrial Parks) organizes preliminary assessment of capacity and experience of investors who have submitted application for project implementation registration.'
    ],
  },
  tieuChiDauTu: {
    listPhamViDCDT: {
      mota: 'Projects that are not specified in Section 1, Part I and Section 1, Part II but must organize the selection of investors according to criteria (referred to as "projects for selecting investors according to criteria"), except for the following projects the following cases:',
      listPV: [
        `Investment projects in the field of agriculture include: planting, tending, nurturing, protecting and developing forests; cultivating and preserving agricultural, forestry and fishery products; production, multiplication and cross-breeding of plant varieties, livestock breeds, forestry plant varieties, aquatic breeds;`,
        `Investment projects in industrial parks, export processing zones, non-tariff zones, industrial clusters;`,
        `Projects in which the investor has the right to use land for the entire land area for project implementation.`
      ]
    },
    listDieuKienXDDA: {
      listDK: [
        'The project is not subject to auction of land use rights; projects that do not fall into the case of bidding for investment projects using land, bidding according to the provisions of specialized laws, laws on socialization.',
        'The project is implemented on land that does not have or has a part of land managed and used by the State (unless the investor has the right to use land in accordance with the approved planning).'
      ]
    },
    listChuanBiHoSoDKTH: [
      '4.1. Based on the information disclosed as prescribed at Point b, Section 2.2, the interested investor shall prepare and submit the project proposal to the Department of Planning and Investment or the Management Board of Economic Zones and Zones industry (01 original and 05 copies). The time for investors to prepare and submit the project proposal is 30 days from the date the list and project information is posted.',
      `4.2. An investor's project implementation proposal includes: A written registration for project implementation; project proposal; documents proving the investor's legal status, capacity and experience; documents and records for evaluation as required by the investor selection criteria and other relevant documents (if any).`
    ]
  },
  listLapPheDuyetDMDA: {
    deMucLoai1: [
      {
        ten: '3.1 For projects subject to approval of investment policies:',
        duAnCoSDDat: [
          'The decision approving the investment policy is concurrently the document of the decision approving the list of projects.',
          `Pursuant to the decision approving the investment policy of the project, the Department of Planning and Investment shall coordinate with relevant agencies to determine the preliminary requirements on the investor's capacity and experience and approve the investment policy. in writing prior to the publication of the project list. For projects implemented in areas under the management of the Management Board of Economic Zones and Industrial Parks, the Management Board of Economic Zones and Industrial Parks shall determine and approve the preliminary requirements. about the investor's capacity and experience before announcing the project portfolio.`
        ],
        duAnTheoLuat: [
          'The decision to approve the investment policy is concurrently a document of the decision to approve the list of projects.',
          `Pursuant to the decision approving the project investment policy, the Department of Planning and Investment shall coordinate with relevant agencies to determine the preliminary requirements on the investor's capacity and experience and approve in writing before publishing the project list. For projects implemented in areas under the management of the Management Board of Economic Zones and Industrial Parks, the Management Board of Economic Zones and Industrial Parks shall determine and approve the preliminary requirements about the investor's capacity and experience before announcing the project list.`
        ]
      },
      {
        ten: '3.2. For projects not subject to investment policy approval:',
        duAnCoSDDat: [
          `a) Specialized agencies under the Provincial People's Committee, agencies directly under the Provincial People's Committees, and the People's Committees of districts shall make proposals for investment projects using land and send them to the Department of Planning and Investment. Proposal contents include: project name; investment objectives; investment scale; preliminary total cost of project implementation, investment capital; capital mobilization plan; investment term and progress; information on the current land use status at the project site, the expected land use demand (if any); preliminary cost of compensation and ground clearance; purpose of using land; approved planning targets; project directory posting language; analyzing the socio-economic efficiency of the project, ensuring national defense, security and sustainable development of the investment project and preliminary requirements on the capacity and experience of the investor.`,
          `b) Investors may propose to implement an investment project using land other than the list of investment projects using land approved by the Chairman of the Provincial People's Committee. The investor shall send the project proposal file to the People's Committee of the district where the project is implemented for review and formulation of the project proposal according to the contents specified at Point a of this Clause and send it to the Department of Planning and Investment.`,
          'c) Approving the list of investment projects using land'
        ],
        pheDuyetDuAnDTCoSDDat: [
          `Within 30 days from the date of receipt of the project proposal specified at Point a or Point b of this Clause, the Department of Planning and Investment shall coordinate with relevant agencies in compiling a list of projects; assess the satisfaction of conditions for determining investment projects using land specified in Section 2; determine the preliminary requirements of the investor's capacity, experience and report to the Chairman of the Provincial People's Committee. In case of necessity, the implementation time can be extended according to actual requirements.`,
          `Within 10 days after receiving the report from the Department of Planning and Investment, the Chairman of the Provincial People's Committee shall consider and approve the list of investment projects using land; which includes preliminary requirements on capacity and experience of investors.`
        ],
        duAnTheoLuat: [
          `Specialized agencies under the Provincial People's Committee, agencies directly under the Provincial People's Committee, and the People's Committee of the district shall make project proposals according to specialized laws and laws on socialization and send them to the Department of Planning and Investment. Proposal contents include: project name; investment objectives; investment scale; preliminary total cost of project implementation; preliminary costs of compensation and ground clearance; investment; capital mobilization plan; investment term and progress; information on the current land use status at the project site, the expected land use demand (if any); purpose of using land; analysis of socio-economic efficiency of the project, assurance of national defense, security and sustainable development of the investment project and preliminary requirements on the investor's capacity and experience.`,
          `The Department of Planning and Investment shall coordinate with relevant agencies in compiling a list of projects according to specialized laws and laws on socialization; assess the satisfaction of conditions for project identification in accordance with specialized laws and laws on socialization specified in Section 2; determine the preliminary requirements of the investor's capacity and experience, report to the Chairman of the Provincial People's Committee.`,
          `On the basis of the report of the Department of Planning and Investment, the Chairman of the Provincial People's Committee considers and approves the list of projects according to specialized laws and laws on socialization; which includes preliminary requirements on capacity, experience of investors.`
        ]
      },
      {
        ten: '3.3. Project list announcement',
        duAnCoSDDat: [
          'a) Pursuant to the decision approving the investment policy in accordance with the law on investment or the approved list of investment projects using land, the Department of Planning and Investment or the Management Board of Economic Zones and Economic Zones shall industrial zones (for projects implemented in the areas under the management of the Management Board of Economic Zones and Industrial Parks) to announce the list of projects as prescribed at Point c, Clause 1, Article 4 of Decree No. 25/2020/ND-CP no later than 07 working days, from the date the document is issued.',
          `b) For projects with preliminary total project implementation costs (excluding costs of compensation, support, resettlement, land use levy and land rent) from VND 800,000,000,000 (Eight hundred billion VND) In addition to posting information specified at Point a, Section 3.3, project information must be posted in English and Vietnamese on the website of the Provincial People's Committee. This regulation does not apply to projects in industries and trades that have not been able to access the market for foreign investors in accordance with the law on investment or projects with requirements on ensuring national defense and security decided by the Prime Minister.`,
          'c) Disclosure content:'
        ],
        noiDungCongBoTT: [
          'Name of project; investment objectives; investment scale; preliminary total cost of project implementation; investment; capital mobilization plan; investment term and progress;',
          'Information on the current status of land use at the project site, the area of the land area for which the project is to be implemented; purpose of using land; approved planning criteria',
          `Preliminary requirements on investor's capacity and experience;`,
          'Time limit for investors to submit application for project implementation registration;',
          'Investors submit application for project implementation registration on the National Procurement Network;',
          'Other information (if necessary).'
        ],
        duAnTheoLuat: {
          mota: 'Pursuant to the approved decision approving the investment policy in accordance with the investment law or the approved list of projects under the specialized law or the law on socialization, the Department of Planning and Investment or the Management Board Economic Zones and Industrial Parks (for projects subject to approval of investment policies implemented in areas under the management of the Management Board of Economic Zones and Industrial Parks) are responsible for posting information projects to the national bidding network system no later than 07 working days from the date the list of projects is approved.',
          noiDung: {
            ten: 'Disclosure content:',
            dSNoiDung: [
              `Name of project; investment objectives; investment scale; preliminary total cost of project implementation; preliminary costs of compensation and ground clearance; investment; capital mobilization plan; investment term and progress;`,
              `Information on the current status of land use at the project site, the area of the land area for which the project is to be implemented; purpose of using land;`,
              `Preliminary requirements on capacity, experience of investors;`,
              'Time limit for investors to submit application for project implementation registration;',
              'Address, phone number and fax number of the Department of Planning and Investment;',
              'Other information (if necessary).'
            ]
          }
        }
      }
    ],
    deMucLoai2: [
      {
        ten: '3.1. Develop content calling for project investment and investor selection criteria:',
        deMucCon: [
          `a) Based on the planning, socio-economic development orientation, investment attraction needs by industry, field, management area and the interest of investors (Investors are actively accessing information about proposal of content calling for project investment); Specialized agencies, units directly under the People's Committee of the province, the People's Committee of the district shall develop the content calling for investment in the project and the set of criteria for selecting investors according to the content specified at Points b and c, Section 3.1 of this article.`,
          `b) Content calling for project investment includes basic information: project name; investment objectives; investment scale; estimated total cost of project implementation, investment capital; capital mobilization plan; location, term, investment schedule; information on the current land use status, the expected land use demand (if any); purpose of using land; propose investment incentives; analysis of socio-economic efficiency of the project, assurance of national defense, security and sustainable development of the investment project, preliminary assessment of environmental impacts (if any) in accordance with the law on environmental protection and investor selection criteria.`,
          'c) Contents of investor selection criteria, including:',
          'd) On the basis of summarizing contents calling for investment projects of localities, units, Departments of Planning and Investment, Management Boards of Economic Zones and Industrial Parks shall assume the prime responsibility for, and coordinate with relevant agencies incompleting content calling for project investment and implementation:'
        ],
        dSNoiDung: [
          'Criteria on prerequisites;',
          'Criteria for evaluation according to the scale;',
          'Methods of evaluation and selection of investors.'
        ],
        tongHopNoiDung: [
          `For projects of important socio-political significance; projects related to national defense, security, foreign affairs, religion, historical and cultural relics at provincial and national levels, forest protection; hydropower projects; the project has a great impact on the environment, landscape and socio-economic; projects in areas where development and production of toxic substances and explosives are restricted; infrastructure of economic zones, industrial parks and high-tech zones; projects using land fund with mineral resources in the ground or affecting political security, social order and safety; projects with a land use area of ​​05 ha or more, for urban areas, and 10 ha or more, for rural areas; projects requiring compensation, site clearance and resettlement from 100 households or more; Department of Planning and Investment, Management Board of Economic Zones and Industrial Parks (for projects implemented in areas under the management of Economic Zones and Industrial Parks Management Board) submit to the Provincial People's Committee approve the content calling for investment in the project. Based on the decision approving the content of calling for investment in the project of the Provincial People's Committee, the Department of Planning and Investment, the Management Board of Economic Zones and Industrial Parks complete and approve the set of criteria for investor selection by document;`,
          'For the remaining projects; The Department of Planning and Investment, the Management Board of Economic Zones and Industrial Parks approve the contents of the call for investment in the project and the set of criteria for investor selection in writing.'
        ]
      },
      {
        ten: '3.2. Project announcement and investor selection criteria:',
        deMucCon: [
          `a) The Department of Planning and Investment or the Management Board of Economic Zones and Industrial Parks is responsible for announcing and posting on the unit's website the content calling for project investment and the set of criteria for housing selection invest.`,
          'b) The content of the announcement includes the following information:'
        ],
        dSNoiDung: [
          'Name of project; investment objectives, investment scale of the project; preliminary total cost of project implementation; summarize the basic requirements of the project; term, investment progress;',
          'Project implementation location, land area, land use purpose; the current status of the land;',
          'Set of criteria for investor selection;',
          'Method of evaluating the set of criteria;',
          'Time limit for investors to submit application for project implementation registration;',
          'Address, phone number, fax number of the Department of Planning and Investment or the Management Board of Economic Zones and Industrial Parks.'
        ]
      }
    ]
  },

  // ƯU ĐÃI CẤP NƯỚC SẠCH
  tieuDeChinhCapNuocSach: 'Mechanism to encourage, support investment in construction of concentrated clean water supply works in the province in the period of 2021-2025',
  canCuCapNuocSach: `Pursuant to the provisions of Resolution No. 30/2021/NQ-HDND dated September 26, 2021 of the Provincial People's Council and Decision No. 2969/QD-UBND dated October 20, 2021 of the Provincial People's Committee.`,
  // Ưu đãi cấp nước sạch - Đề mục
  doiTuongCapNuocSach: 'I. Subjects of application',
  dieuKienNguyenTacCNS: 'II. Conditions and principles of preferential application',
  noiDungMucHoTroNhaDauTuCNS: 'III. Content and level of investor support',
  // Ưu đãi cấp nước sạch - Đối tượng
  noiDungDoiTuongCNS: 'Localities, businesses, public non-business units, cooperatives and cooperation groups to carry out investment to build concentrated clean water supply works in the province',
  giaiDoanCNS: 'IN THE PERIOD',
  namGiaiDoanCNS: '2021-2025',
  // Ưu đãi cấp nước sạch - Điều kiện
  dSDieuKienNguyenTacCNS: [
    `Investment projects on the construction of a concentrated clean water supply contructions must be consistent with the master plans, plans on water supply (priority is given to investment projects on construction of water plants in areas where there are no water plants and development). water supply network connected to the existing water supply system), under the investment list approved by the Provincial People's Committee, the Provincial People's Committee decides the investment policy or agrees on the investment policy.`,
    `Investor being an enterprise, cooperative or cooperative group that has a plan to organize production, business, and service provision; have the financial capacity, management capacity, exploitation and commitment to provide clean water services to serve people in the province according to the project approved by the provincial People's Committee`
  ],
  // Ưu đãi cấp nước sạch - Nội dung
  quyDinhPhanVungCNS: {
    tieuDe: '1. Zoning Regulations',
    dSNoiDung: [
      {
        loai: 'Area',
        ten: '01',
        noiDung: 'Includes communes of Tam Ky city, Hoi An city, Dien Ban town, Nui Thanh district (except island communes and mountainous communes) and towns of delta districts.'
      },
      {
        loai: 'Area',
        ten: '02',
        noiDung: 'Includes the communes of the districts: Que Son, Dai Loc, Duy Xuyen, Thang Binh, Phu Ninh, except for communes with special difficulties in coastal areas.',
      },
      {
        loai: 'Area',
        ten: '03',
        noiDung: 'Includes mountainous communes of Nui Thanh district; the central towns and communes 4 of the mountainous districts and the remaining communes (except the communes of Region 4).'
      },
      {
        loai: 'Area',
        ten: '04',
        noiDung: 'Includes communes in ethnic minority areas and mountainous areas, communes with special difficulties in coastal areas, border communes and island communesI'
      },
    ]
  },
  mucHoTroCNS: {
    tieuDe: '2. Investor support level',
    dSNoiDung: [
      'a) Support investment capital for enterprises assigned as investors in investment projects to build concentrated clean water supply project:',
      'b) Budget return solution:'
    ]
  },
  // Ưu đãi cấp nước sạch - Nội dung -- Mức hỗ trợ CNS
  hoTroDoiVoiCongTrinhXDCNS: 'For the construction of factories and clean water treatment systems (prioritize new construction in areas without water factory; upgrade and renovate water plants belonging to state property), the level of support as follows:',
  bangLoaiHinhXDCNS: 'Type of construction',
  bangMucHoTroCNS: 'Investment support level (million VND/m3/day)',
  bangLoaiVungHoTroCNS: [
    'Area 1',
    'Area 2',
    'Area 3',
    'Area 4'
  ],
  xayDungMoiCNS: [
    'New construction',
    '1,5',
    '2,0',
    '2,5',
    '3,0'
  ],
  nangCapCaiTaoCNS: [
    'Upgrade, Improve',
    '1,0',
    '1,33',
    '1,67',
    '2,0'
  ],
  hoTroDoiVoiDuAnXDMoi: 'For new construction and expansion projects of clean water supply pipeline network: Investment support depends on each area, each type of pipeline and local socio-economic conditions according to the investment support level table. as follows:',
  bangVungCNS: 'Area',
  doiVoiDuongOng: [
    {
      ten: 'For pipeline level I',
      noiDung: 'Support 100% of investment costs according to the Table.'
    },
    {
      ten: 'For pipeline level II',
      noiDung: 'Support 100% of investment costs according to the Table for investment projects in localities in regions 3 and 4; support 50% of investment costs according to the Table for investment projects in the localities of region 1 and region 2.'
    },
    {
      ten: 'For pipeline level III',
      noiDung: 'Support 100% of investment costs according to the Table for investment projects in localities in regions 3 and 4.'
    },
    {
      ten: 'Support for household connection costs according to approved projects, with the support level as follows:',
      noiDung: 'Support 1.5 million VND/connection/household for localities in regions 3 and 4; support 1.0 million VND/connection/household for localities in Region 1 and Region 2.'
    }
  ],
  noiDungHoanTraNganSachCNS: 'The support funding from the State budget for investment in construction of clean water supply works concentrated in localities that are not in difficult socio-economic areas, are capable of The collection of expenses shall be fully accounted into the historical cost of fixed assets and shall be refunded to the State budget by depreciation of fixed assets according to current regulations, excluding household connection costs.',

  // ƯU ĐÃI CHẤT THẢI RẮN
  tieuDeChinhChatThaiRan: 'Mechanism to encourage investment, support concentrated domestic solid waste treatment zones in Quang Nam province in the period of 2020 - 2030',
  canCuChatThaiRan: `Pursuant to the provisions of Resolution No. 01/2020/NQ-HĐND dated April 4, 2020 of the Provincial People's Council and Decision No. 1331/QD-UBND dated May 5, 2020 of the Provincial People's Committee and Decision No. 42/VP-TH dated April 29, 2020 of the Office of the Provincial People's Council`,
  // Ưu đãi chất thải rắn - Đề mục
  doiTuongChatThaiRan: 'I. Subjects of application',
  dieuKienNguyenTacCTR: 'II. Conditions and principles of preferential application',
  noiDungMucHoTroNhaDauTuCTR: 'III. Content and level of investor support',
  // Ưu đãi chất thải rắn - Đối tượng
  noiDungDoiTuongCTR: [
    'Organizations, individuals, businesses, cooperatives at home and abroad must have financial, technical, technological and experience capacity to invest in waste treatment zones.',
    'Communes, wards, townships have waste treatment zones and communes, wards and townships bordering on waste treatment areas.'
  ],
  // Ưu đãi chất thải rắn - Điều kiện
  veCongNgheXuLyCTR: 'About processing technology',
  dSCongNgheXuLyCTR: [
    'Applying sanitary landfill technology to waste treatment zones with a maximum capacity of 50 tons/day and night.',
    'Apply sanitary landfill technology, incineration technology or other advanced technologies to waste treatment zones with a capacity of over 50 tons/day and night.'
  ],
  truongHopCoNhieuNhaDauTuCTR: 'In case there are many investors who meet the prescribed conditions, priority will be given to supporting investors to apply advanced and more experienced technology.',
  phuongThucHoTroNhaDauTuCTR: 'Support method for investors: State budget support after completing the work contents and certified by competent authorities or based on the volume of acceptance, settlement according to design documents, cost estimates approved by competent authorities (post-investment support).',
  altBgXuLyCTR: 'Business offers',
  dSNoiDungMucHoTroCTR: [
    {
      thuTu: '01',
      mucHoTro: 'Support 100%',
      noiDung: 'The cost of compensation, support resettlement for households according to current regulations of the state to create a clean ground for the construction area of ​​the waste treatment zones (taking into account the fence).'
    },
    {
      thuTu: '02',
      mucHoTro: 'Support 100%',
      noiDung: 'The cost of compensation, support resettlement of housing areas for households within a safe radius (buffer zone) of the waste treatment zones (within a radius of 1,000 meters in case of applying landfill technology; radius of 500 meters in case of applying combustion technology or other advanced technology; where the radius is calculated from the boundary or fence of the project).'
    },
    {
      thuTu: '03',
      mucHoTro: 'Support 50%',
      noiDung: 'The funding for construction of roads to the waste treatment zones according to the new rural standard road standard for the district-level treatment zones; according to ĐH road standards for provincial treatment zones. In which, priority is given to choosing a route that does not go through residential or sparsely populated areas.'
    },
    {
      thuTu: '04',
      mucHoTro: 'Support 100%',
      noiDung: 'The investment cost for construction of power supply works (taking into account the fence) of the waste treatment zones.'
    }
  ],

  // ƯU ĐÃI HỢP TÁC XÃ
  tieuDeChinhHopTacXa: 'Mechanisms and policies to support the development of collective economy, cooperatives in Quang Nam province in the period of 2021 - 2025',
  canCuHopTacXa: `Pursuant to the provisions of Resolution No. 25/2021/NQ-HDND dated July 22, 2021 of the Provincial People's Council and Decision No. 2406/QD-UBND dated August 23, 2021 of the Provincial People's Committee.`,
  // Ưu đãi hợp tác xã - Đề mục
  doiTuongHTX: 'I. Subjects of application',
  dieuKienNguyenTacHTX: 'II. Conditions to apply the offer',
  noiDungMucHoTroNhaDauTuHTX: 'III. Content and level of investor support',
  noiDungDoiTuongHTX: 'Cooperatives, unions of cooperatives (in the absence of separate regulations, hereinafter collectively referred to as cooperatives and abbreviated as HTX) meet the criteria of each specified support content.',
  giaiDoanHTX: 'THE PERIOD',
  // Ưu đãi hợp tác xã - Điều kiện
  noiDungDieuKienApDungHTX: 'The cooperative organizes its activities according to the provisions of the Law on Cooperatives 2012; The cooperative strictly abides by the information and reporting regime; Classifies and evaluates cooperatives according to the provisions of law; The cooperative needs to be supported, meeting the criteria of each specified support content.',
  vePhanChiaDiaBanHTX: 'About the division of areas',
  tenCotBangPCDBHTX: [
    'Area',
    'Investment support level (million VND/m3/day)'
  ],
  noiDungBangPCDBHTX: [
    {
      ten: 'High mountain areas',
      noiDung: 'Bac Tra My district, Nam Tra My district, Nam Giang district, Tay Giang district, Dong Giang district and Phuoc Son district'
    },
    {
      ten: 'Low mountain areas',
      noiDung: 'Tien Phuoc district, Hiep Duc district and Nong Son district'
    },
    {
      ten: 'Plain area',
      noiDung: 'Tam Ky city, Hoi An city, Dien Ban town, Duy Xuyen district, Que Son district, Thang Binh district, Dai Loc district, Nui Thanh district and Phu Ninh district'
    }
  ],
  altBgHopTacXa: 'Bussiness offers',
  // Ưu đãi Hợp tác xã - Nội dung
  dSHoTroHTX: [
    {
      idHoTro: 'ht1',
      tieuDe: '1. Support from the central budget to support investment in infrastructure and product processing',
      noiDung: [
        {
          idND: 'nd01',
          ten: 'Subjects:',
          chiTiet: 'Cooperatives operating in the fields of agriculture, forestry, fishery and salt production.'
        },
        {
          idND: 'nd02',
          ten: 'Support conditions:',
          chiTiet: [
            'The cooperative has been in stable operation for at least 1 year',
            'Cooperatives located in mountainous districts must have 20 or more members; in lowland districts must have at least 30 members; located in districts, towns and cities in the delta region must have at least 50 members',
            'Infrastructure investment projects of cooperatives must be suitable for production, business and service needs of cooperatives operating in the fields of agriculture, forestry, fishery and salt production',
            'Each item is only supported 1 time',
            'Based on the need to support investment in infrastructure construction or product processing projects, the cooperative shall submit an application to the local authority where the cooperative organizes production and business',
            'Priority is given to support cooperatives participating in chain production; one-commune-one-product program (OCOP); produce safe products according to VietGAP standards; Global GAP,…; Agricultural cooperatives converted under the Law on Cooperatives 2012; The cooperative provides products and services for the majority of its members; Cooperatives have not been supported according to Decision No. 2261/QD-TTg dated December 15, 2014 of the Prime Minister approving the program to support cooperative development in the period of 2016-2020'
          ]
        },
        {
          idND: 'nd03',
          ten: 'Support content:',
          chiTiet: [
            'Building warehouses, product sorting and packing workshops, preliminary processing - processing workshops and procuring equipment for production and business activities to bring benefits to the member community',
            'Construction of irrigation, in-field traffic works in the fields of cultivation, forestry,  salt production including: culverts, pumping stations, wells, water pipelines, dams, canals, water tanks, above works. canals and embankments of all kinds, focal water supply systems for economical irrigation; main road for intra-field traffic, fire-blocking road, forestry road',
            'Construction of infrastructure works in aquaculture and seafood areas including: focal water supply and drainage systems (ponds, tanks, culverts, canals, water supply and drainage pipes, pumping stations), dikes, embankments, roads, common wastewater treatment works for aquaculture areas; buoy system, warning lights for the boundaries of farming areas, anchoring system for cages; upgrade and develop concentrated aquaculture cages of agricultural cooperatives at sea.'
          ]
        },
        {
          idND: 'nd04',
          ten: 'Support level:',
          moTa: 'The total level of support for all the above-mentioned support contents is up to 3,000 million VND/cooperative; in which, support to build warehouses, product sorting and packing workshops, preliminary processing - processing workshops and purchase equipment for production and business activities with a maximum support level of 600 million VND/ Cooperative.',
          chiTiet: [
            `Cooperatives located in mountainous districts, the maximum support level is 90% of the project's investment budget;`,
            `Cooperatives located in the lowland mountainous districts, the maximum support level is 80% of the project's investment budget;`,
            `Cooperatives located in districts, towns and cities in the delta region, with a maximum support level of 70% of the project's investment budget;`,
            `Depending on the nature of the project, the level of central budget support, the cooperative's reciprocal proposed capital, capital from other lawful sources; The district-level People's Councils shall stipulate the specific support rate of the local budget and direct the implementation of solutions to mobilize cooperative contributions, arrange to integrate capital sources for implementation, and ensure the total funding for the support state budget support for projects must not exceed 100% of the total investment approved by competent authorities.`
          ]
        },
      ]
    },
    {
      idHoTro: 'ht2',
      tieuDe: '2. Support from the provincial budget to support the establishment and consolidation of cooperative organizations',
      noiDung: [
        {
          idND: 'nd11',
          ten: 'Subjects:',
          chiTiet: [
            'Newly established cooperatives;',
            'Cooperatives rearranged due to consolidation, merger, restructuring.'
          ]
        },
        {
          idND: 'nd12',
          ten: 'Support conditions:',
          chiTiet: [
            'Having a written request for support in establishing a new cooperative; organize and rearrange activities of cooperatives',
            'Ensure the conditions for establishment, consolidation and merger in accordance with the Law on Cooperatives and relevant legal documents',
            'Cooperatives have operated for at least 5 years, unions of cooperatives have operated for at least 3 years before consolidating or merging',
          ]
        },
        {
          idND: 'nd13',
          ten: 'Support content:',
          chiTiet: [
            'Providing information, advice, training, and disseminating legal regulations on cooperatives before establishment to founders',
            'Consulting and supporting to build charters, production and business plans; guide and carry out procedures for establishment, registration and organization of activities of cooperatives',
            'Consulting, assisting in formulating charters and organizing activities of cooperatives after consolidation or merger'
          ]
        },
        {
          idND: 'nd14',
          ten: 'Support level:',
          moTa: 'The total level of support for all the support mentioned above contents is up to 3,000 million VND/cooperative; which includes support to build warehouses, product sorting and packing workshops, preliminary processing - processing workshops, and purchase of equipment for production and business activities with a maximum support level of 600 million VND/ Cooperative.',
          chiTiet: [
            'Support according to actual expenses incurred (with legal and valid invoices and vouchers). Specifically: (1) New establishment: Maximum support level: 30 million VND/cooperative for cooperatives located in mountainous districts; 25 million VND/cooperative for cooperatives located in low mountainous areas; 20 million VND / 5 cooperatives for cooperatives located in districts, towns and cities in the delta region; 40 million VND/union of cooperatives; (2) Consolidation, merger: Maximum support level: 20 million dong/1 case for cooperatives located in mountainous districts; 15 million dong/1 case for cooperatives located in low mountainous areas; 10 million dong/1 case for cooperatives located in districts, towns and cities in the delta region; 30 million dong/1 case for unions of cooperatives.',
            'Scale of implementation: In the period of 2021 - 2025, supporting the establishment of about 200 new cooperatives, 05 unions of cooperatives; consolidated, merged about 25 cooperatives, 02 unions of cooperatives.',
          ]
        },
      ]
    },
    {
      idHoTro: 'ht3',
      tieuDe: '3. Provincial budget support related to credit policy',
      noiDung: [
        {
          idND: 'nd21',
          ten: 'Subjects:',
          chiTiet: 'Agricultural cooperatives that have needs and projects to borrow capital to invest in production business and service development.'
        },
        {
          idND: 'nd22',
          ten: 'Support conditions:',
          chiTiet: `Completed loan procedures according to the State's regulations at commercial banks.`
        },
        {
          idND: 'nd23',
          ten: 'Support content:',
          chiTiet: 'Interest rate support for the difference between the actual lending interest rate of commercial banks and the lending interest rate of the Cooperative Development Support Fund.'
        },
        {
          idND: 'nd24',
          ten: 'Support level:',
          chiTiet: [
            ' The level of support is equal to the difference between the actual lending interest rate of the commercial bank and the lending interest rate of the Provincial Cooperative Development Support Fund; Loan limit is supported with the interest rate calculated on the actual debt balance at the time of reviewing the support dossier, but not exceeding 02 billion VND/cooperative.',
            'The support period (from the date of disbursement according to the credit contract with the commercial bank) is a maximum of 5 years for production investment loan projects and a maximum of 03 years for a production investment loan project service business investment loan project.',
          ]
        },
        {
          idND: 'nd25',
          ten: 'Scale of implementation:',
          chiTiet: 'In the period of 2021 - 2025, interest rates will be supported for about 35 cooperatives.'
        }
      ]
    },
    {
      idHoTro: 'ht4',
      tieuDe: '4. Support the provincial budget to invest in technical infrastructure',
      noiDung: [
        {
          idND: 'nd31',
          ten: 'Subjects:',
          chiTiet: 'Cooperatives operating in the fields of agriculture, forestry, fishery and salt production.'
        },
        {
          idND: 'nd32',
          ten: 'Support conditions:',
          chiTiet: [
            'The cooperative has been in stable operation for at least 1 year',
            'Cooperatives located in mountainous districts must have 20 members or more; in lowland districts must have at least 30 members; located in districts, towns and cities in the delta region must have at least 50 members',
            'Infrastructure investment projects of cooperatives must be suitable for production business and service needs of cooperatives operating in the fields of agriculture, forestry, fishery and salt production.',
            'Each item is only supported 1 time',
            'Based on the need to support investment in infrastructure construction or product processing projects, the cooperative shall submit an application to the local authority where the cooperative organizes production and business.',
            'Priority is given to support cooperatives participating in chain production; one-commune-one-product program (OCOP); produce safe products according to VietGAP standards; Global GAP,…; Agricultural cooperatives converted under the Law on Cooperatives 2012; The cooperative provides products, services for the majority of its members; Cooperatives have not been supported according to Decision No. 2261/QD-TTg dated December 15, 2014 of the Prime Minister approving the program to support cooperative development for the period 2016-2020.'
          ]
        },
        {
          idND: 'nd33',
          ten: 'Support level:',
          moTa: 'A maximum of 600 million VND/cooperative, of which:',
          chiTiet: [
            `Cooperatives located in mountainous districts, the maximum support level is 90% of the project's investment funds;`,
            `Cooperatives located in low mountainous districts, the maximum support level is 80% of the project's investment budget;`,
            `Cooperatives located in the districts, towns and cities in the delta region, the maximum support level is 70% of the project's investment budget.`,
            `Depending on the nature of the project, the level of support from the provincial budget, the reciprocal proposed capital of the cooperative, capital from other lawful sources; The district-level People's Councils shall stipulate the specific support rate of the local budget and direct the implementation of solutions to mobilize cooperative contributions, arrange to integrate capital sources for implementation, and ensure the total funding for the support. State budget support for projects must not exceed 100% of the total investment approved by competent authorities.`
          ]
        },
        {
          idND: 'nd34',
          ten: 'Scale of implementation:',
          chiTiet: 'In the period of 2021 - 2025, supporting investment in technical infrastructure for about 60 cooperatives.'
        }
      ]
    },
    {
      idHoTro: 'ht5',
      tieuDe: '5. Provincial budget support for start-up cooperatives',
      noiDung: [
        {
          idND: 'nd41',
          ten: 'Subjects:',
          chiTiet: 'Cooperatives founded by college graduates or co-founders and holding leadership and management positions.'
        },
        {
          idND: 'nd42',
          ten: 'Support conditions:',
          chiTiet: [
            'The founding members or co-founders who hold leadership and management positions of the cooperative must not be older than 40 years old and participate in the formation of an organization for the first time for economic activities',
            'Activities of cooperatives have a direct impact on supporting the development of local products, creating jobs for 10 workers or more',
            'Priority is given to cooperatives that participate in the product value chain, produce clean products, and apply high technology'
          ]
        },
        {
          idND: 'nd43',
          ten: 'Support content:',
          chiTiet: 'Invest in essential facilities, initial machinery and equipment to serve the operation of the cooperative.'
        },
        {
          idND: 'nd44',
          ten: 'Support level:',
          chiTiet: 'Up to 70% of the implementation cost but not more than 200 million VND/cooperative.'
        },
        {
          idND: 'nd45',
          ten: 'Scale of implementation:',
          chiTiet: 'In the period of 2021 - 2025, about 50 cooperatives will be supported.'
        }
      ]
    }
  ],

  // ƯU ĐÃI XÃ HỘI HÓA
  tieuDeChinhXaHoiHoa: 'Policy to promote socialization of investment in a number of fields in Quang Nam province in the period of 2022-2025',
  canCuXaHoiHoa: `Pursuant to the provisions of Resolution No. 06/2018/NQ-HDND dated January 12, 2022 of the Provincial People's Council`,
  // Ưu đãi xã hội hóa - Đề mục
  doiTuongXHH: 'I. Subjects of application',
  dieuKienNguyenTacXHH: 'II. Conditions to apply the offer',
  noiDungMucHoTroNhaDauTuXHH: 'III. Content and level of investor support',
  // Ưu đãi xã hội hóa - Đối tượng
  noiDungDoiTuongXHH: [
    `Establishments for implementing socialization according to the Government's regulations.`,
    'Agencies, units, organizations and individuals related to policies to encourage socialization of investment in the fields of education - training, vocational training, health, culture, physical training and sports, environment schools, judicial expertise in Quang Nam province in the period of 2022-2025.'
  ],
  // Ưu đãi xã hội hóa - Điều kiện
  dSDieuKienNguyenTacXHH: [
    {
      thuTu: '01',
      noiDung: `The project belongs to the list of types of incentives and meets the criteria of scale and standards as prescribed in the Prime Minister's Decisions: No. 1466/QD-TTg dated October 10, 2008, No. 693/ QD-TTg dated May 6, 2013, No. 1470/QD-TTg dated July 22, 2016; are in the list and conform to the criteria for attracting investment to socialization approved by the competent authority.`
    },
    {
      thuTu: '02',
      noiDung: `The project must be consistent with the development planning of the province, meeting the urgent needs of the locality; consistent with the master plan, plan on land use approved by the competent authority and approved by the Provincial People's Committee for investment policy, decision on investor approval (or decision on investor selection result) according to regulations.`
    },
    {
      thuTu: '03',
      noiDung: 'Projects to implement socialization must ensure implementation according to the schedule and scale according to the registered investment plan; implementing socialization projects for the right purposes and effectively.'
    }
  ],
  altBgXuLyXHH: 'Bussiness offers',
  // Ưu đãi xã hội hóa - Nội dung
  dSNoiDungMucHoTroXHH: [
    {
      id: 'nd1',
      ten: '1. Land rent exemption policy',
      noiDung: [
        'Exemption of 100% of land rent for the whole project implementation period (up to 50 years) for all projects in the fields of socialization as prescribed by the Government.',
        `Investment projects in the fields of socialization in the cities of Tam Ky, Hoi An and Dien Ban town are exempted from land rent according to the respective rates: exemption for up to 50 years, 25 years, 20 years, 15 years of the project's land rent depending on the type of priority for attraction in the period of 2022-2025.`,
        'In each project, only land rent is exempted for the land area used for the right purpose of socialization, the land area used not for the right purpose of socialization is not exempted.'
      ]
    },
    {
      id: 'nd2',
      ten: '2. Policy on financial support for compensation and site clearance',
      noiDung: [
        'The provincial budget shall allocate 100% of the funding for compensation and site clearance for projects implemented in areas with extremely difficult socio-economic conditions of the province, as specified in Appendix III. attached to Decree No. 31/2021/ND-CP dated March 26, 2021 of the Government detailing and guiding the implementation of a number of articles of the Investment Law, including the following districts: Dong Giang, Tay Giang and Nam Giang , Phuoc Son, Bac Tra My, Nam Tra My, Hiep Duc, Tien Phuoc, Nong Son, Nui Thanh (including communes: Tam Son, Tam Thanh, Tam My Dong, Tam My Tay, Tam Tra, Tam Hai), Thang Binh (including communes: Binh Lanh, Binh Tri, Binh Dinh Bac, Binh Dinh Nam, Binh Quy, Binh Phu, Binh Chanh, Binh Que) and Tan Hiep island commune, Hoi An city.',
        'The provincial budget supports 50%, the investor contributes 50% of the cost of compensation and site clearance for projects implemented in areas with difficult socio-economic conditions of the province, prescribed in Appendix III to Decree No. 31/2021/ND-CP dated March 26, 2021 of the Government, including districts: Dai Loc, Que Son, Phu Ninh, Duy Xuyen (including communes: Duy Chau). , Duy Hoa, Duy Phu, Duy Son, Duy Tan, Duy Thu, Duy Trinh, Duy Trung), Nui Thanh (including communes: Tam Xuan I, Tam Xuan II, Tam Anh Bac, Tam Anh Nam), Thang Binh including communes: Binh Nguyen, Binh Tu, Binh An, Binh Trung). In case the socialization project is located in an inter-district/commune area, the provincial budget shall support according to the area/expense incurred in the area of each locality in the project.',
        'For projects implemented in the remaining areas: The provincial budget does not support funding for compensation and site clearance.',
        'The land area used for the right purposes of socialization is entitled to the policy of financial support for compensation and ground clearance; part of the land area used not related to the field of socialization is not supported with funding for compensation and ground clearance.'
      ]
    },
    {
      id: 'nd3',
      ten: '3. Policy on leasing and building facilities',
      noiDung: [
        'Facility that implements socialization is given priority to rent infrastructure; socialization works to provide products and services in the field of socialization in accordance with the law on socialization.',
        'The rental price bracket for infrastructure and socialized works, including the minimum and maximum rents, is determined according to the provisions of Clause 2, Article 1 of Circular No. 156/2014/TT-BTC dated October 23. 2014 by the Minister of Finance. Project owners implementing socialization may apply a preferential rental price equal to the prescribed minimum rent (in the case of not auctioning the property); In the case of property rental auctions, the auction starting price is determined at 4 equal to the prescribed minimum rental price. The preferential period is only applicable for the first 50% of the lease term and not more than 10 years.',
      ]
    },
    {
      ten: '4. Preferential credit policy',
      noiDung: [
        `Establishments performing socialization, public non-business units operating in the field of socialization may borrow state investment credit capital according to the provisions of Decree No. 32/2017/ND-CP dated March 31 2017 of the Government on State investment credit; be considered in the priority list of loans with preferential interest rates at the Quang Nam Investment and Development Fund (the project must ensure the conditions specified in the Charter of organization and operation of the Quang Nam Investment and Development Fund). , in line with the list of investment and lending sectors of the Quang Nam Investment and Development Fund for the period 2021-2025 as specified in the Resolution No. 14/NQ-HDND dated March 16, 2021 of the Provincial People's Council on the portfolio of investment and lending sectors of the Quang Nam Investment and Development Fund, period 2021-2025).`,
      ]
    },
  ],

  // ƯU ĐÃI NÔNG NGHIỆP NÔNG THÔN
  tieuDeChinhNongNghiepNongThon: 'Mechanisms, policies to encourage enterprises to invest in agriculture, rural areas in Quang Nam province',
  canCuNongNghiepNongThon: `Pursuant to the provisions of Resolution No. 45/2018/NQ-HDND dated December 6, 2021 of the Provincial People's Council and Decision No. 522/QD-UBND dated February 25, 2021 of the Provincial People's Committee.`,
  // Ưu đãi nông nghiệp nông thôn - Đề mục
  doiTuongNNNT: 'I. Subjects of application',
  dieuKienNguyenTacNNNT: 'II. Conditions and principles of preferential application',
  noiDungMucHoTroNhaDauTuNNNT: 'III. Content and level of investor support',
  // Ưu đãi nông nghiệp nông thôn - Đối tượng
  dSDoiTuongNNNT: [
    'Enterprises established, registered, operating under the Law on Enterprises and whose investment projects are agricultural projects with special investment incentives, agricultural projects with investment incentives and agricultural projects with investment incentives according to the Law on Enterprises prescribed in Decree 57/2018 / ND-CP dated April 17, 2018 of the Government.',
    'Cooperatives, unions of cooperatives newly established or transformed under the Law on Cooperatives 2012 invest in agriculture and rural areas in Quang Nam province.'
  ],
  // Ưu đãi nông nghiệp nông thôn - Điều kiện
  dSDieuKienNguyenTacNNNT: [
    'The State provides investment incentives through exemption, reduction of taxes, fees and charges and reduction of a number of administrative procedures for businesses.',
    'The State supports investment in the form of partial support of investment funds or compensation for interest rate differences for enterprises.',
    'Enterprises with special agricultural projects with investment incentives, agricultural projects with investment incentives, agricultural projects with investment incentives are entitled to investment incentives and investment support in accordance with this Decree.',
    'Priority is given to investment support for innovative start-up small and medium-sized enterprises specified in Clause 2, Article 3 of the Law on Support for Small and Medium Enterprises; enterprises building material areas have contracts with farmers.',
    'In the same period, if an investment project entitled to investment incentives,  support has different levels of incentives and support, the enterprise may choose to apply the most profitable investment incentive and support level.',
    `Enterprises invest their own capital, mobilize capital to implement investment projects in industries,  trades eligible for investment incentives in agriculture, rural areas. The State shall provide post-investment support according to the support norms applicable to each type of item or work promulgated by the People's Committee of the province.`,
    'In case of the same content, enterprises, cooperatives and unions of cooperatives receive support from the central budget, they are not entitled to support policies from the provincial budget.'
  ],
  altBgNongNghiepNongThon: 'Bussiness offers',
  // Ưu đãi nông nghiệp nông thôn - Nội dung
  dsNoiDungMucHoTroNNNT: [
    {
      thuTu: '01',
      tieuDe: 'Support investment in infrastructure in areas of land accumulation and concentration',
      noiDung: [
        'Support 60% of investment funds for intra-field traffic, irrigation, other infrastructure and equipment for production in the area where land is accumulated, but not more than 03 billion VND/project, projects for investment projects on concentrated agricultural production (growing vegetables, tubers, fruits, medicinal herbs, aquaculture) with production association contracts between cooperatives and unions of cooperatives enterprises and the area of ​​05 hectares or more.'
      ]
    },
    {
      thuTu: '02',
      tieuDe: 'Support investment in renovating, upgrading concentrated livestock, poultry slaughtering establishments',
      noiDung: [
        'Enterprises, cooperatives, unions of cooperatives have projects to renovate and upgrade concentrated cattle and poultry slaughter establishments, apply advanced technologies, and have solid and liquid waste management systems in accordance with regulations, they are supported 60% of the total investment but not more than 1 billion VND/project to renovate and upgrade infrastructure in terms of traffic, electricity, water, factories, waste treatment and purchase equipment in the project fence.'
      ]
    },
    {
      thuTu: '03',
      tieuDe: 'Support to invest in concentrated livestock (pig, goat) breeding facilities',
      noiDung: [
        `Enterprises, cooperatives, unions of cooperatives have investment projects on pig breeding facilities with 1,000 heads or goats from 500 heads or more, in accordance with the concentrated livestock and poultry production planning approved by the Provincial People's Committee or conform to the new rural construction planning, implement the environmental protection plan as prescribed (for pigs, it is required to build a closed system of cages, advanced waste treatment technology, have a biogas cellar; for goats, it is required to have a suitable barn, with waste treatment technology that meets the requirements of environmental protection) is supported by the State 60% of the total investment but not more than 02 billion VND/project to build construction of infrastructure for traffic, electricity, water supply and drainage, waste treatment in the project fence.`
      ]
    },
    {
      thuTu: '04',
      tieuDe: 'Support growing medicinal plants',
      moTa: 'Enterprises, cooperatives and unions of cooperatives having investment projects on growing medicinal plants are supported as follows:',
      noiDung: [
        '1. Projects with a concentrated area of 05 ha or more, supporting 25 million VND/ha for seedlings, but not more than 01 billion VND/project.',
        '2. Projects with a concentrated area of 10 hectares or more, in addition to seed costs, are supported 60% of the total investment but not more than 02 billion VND/project for investment in infrastructure traffic, electricity, water supply system in the project fence.'
      ],
      noiDungThem: 'Enterprises, cooperatives, unions of cooperatives that have investment projects on production of Ngoc Linh ginseng seed with the scale of 50,000 Ngoc Linh ginseng seedlings/year or more are supported 80% of the cost to build the facility infrastructure, equipment and environmental treatment, setting up original seed gardens in the project fence but not exceeding VND 08 billion/project.'
    },
    {
      thuTu: '05',
      tieuDe: 'Support investment in the production of safe vegetables, tubers, fruits and mushrooms in the direction of organic agriculture or good agricultural practice (GAP)',
      moTa: 'Enterprises, cooperatives, unions of cooperatives have investment projects to produce safe vegetables, fruits, and mushrooms in the direction of organic agriculture or good agricultural practice (GAP) with high technology application, scale of concentrated area of ​​03 ha or more of arable land, or 2,000m2 or more for the area of ​​permanently built treehouses (greenhouses, net houses) and 300 m2 or more for the area of ​​mushroom houses supported as follows:',
      noiDung: [
        '1. One-time support, up to 50 million VND/ha to hire an organization to evaluate and certify safe products according to organic agricultural standards, GAP.',
        '2. Support 60% of total investment but not exceeding 02 billion VND/project for investment in reclamation, construction of traffic, electricity, waste treatment, water supply and drainage system; the system of net houses, greenhouses and accompanying equipment such as water saving, temperature regulation ... in the project fence.',
        '3. Support 30 million VND/ha in the first year to convert from other production to organic agricultural product production.'
      ]
    },
  ],

  // VẤN ĐỀ PHÁP LÝ
  noiDungChinhVDPL: 'Main content',
  thuTucDauTuVDPL: 'Investment procedures',
  nganhNgheKinhDoanhVaNguyenTacVDPL: 'Industry, business lines and principles of market access',
  laoDongVDPL: 'Labor',
  kiemTraVaGiamSatVDPL: 'Customs inspection and supervision for export processing enterprises',
  cacVanDeKhacVDPL: 'Other problems',
  duAnDauTuMoiVDPL: 'New investment projects',
  gopVonMuaCoPhanVDPL: 'Contributed capital, buying shares/contributing capital:',
  yeuCauThucHienThuTucDangKyGopVonVDPL: 'Requirements for carrying out procedures for registration of capital contribution, share purchase, purchase of contributed capital:',
  subThuTucDauTuVDPL: 'Economic organizations having certificates of land use rights in islands and border communes, wards and townships; coastal communes, wards and towns; other areas affecting national defense and security.',
  nganhNgheKinhDoanhVDPL: 'Business Line',
  nganhCamVDPL: 'Industries and professions banned from business investment',
  nganhKinhDoanhCoDieuKienVDPL: 'Sectors and trades with conditional business investment',
  contentNganhCamVDPL: '8 industries and trades (including trading in drugs, chemicals, minerals, firecracker business, debt collection service business, ...)',
  contentNganhKinhDoanhCoDieuKienVDPL: '227 industries and trades (mainly in the field of service business and trading in sensitive goods) and applicable conditions are publicly posted at the National Business Registration Portal',
  nguyenTacTiepCanDauTuNuocNgoaiVDPL: 'Principles of market access for foreign investors',
  nguyenTacTiepCanThiTruongVDPL: 'Principles of market access',
  tbContentNganhNgheKinhDoanhMotVDPL: 'List of industries and trades that have not been approached to the market (25 industries and occupations)',
  tbContentNganhNgheKinhDoanhMotAVDPL: '(Group A, Appendix I, Decree 31/2021/ND-CP dated March 26, 2021 of the Government)',
  tbContentNganhNgheKinhDoanhHaiVDPL: 'List of industries and occupations with conditional market access (59 industries and occupations)',
  tbContentNganhNgheKinhDoanhHaiBVDPL: '(Group B, Appendix I, Decree 31/2021/ND-CP dated March 26, 2021 of the Government)',
  tbContentNganhNgheKinhDoanhBaVDPL: 'There is no commitment from the Government of Vietnam on market access for foreign investors',
  tbContentNguyenTacTiepCanThiTruongMotVDPL: 'Foreign investors are not allowed to invest',
  tbContentNguyenTacTiepCanThiTruongHaiVDPL: 'Foreign investors are allowed to invest if they meet all the conditions as prescribed',
  tbContentNguyenTacTiepCanThiTruongBaAVDPL: 'If there are regulations on restricting foreign investors market access in accordance with Vietnamese law, the provisions of Vietnamese law shall apply.',
  tbContentNguyenTacTiepCanThiTruongBaBVDPL: 'If there are no regulations on restricting foreign investors market access as prescribed by Vietnamese law Foreign investors are entitled to market access as prescribed for domestic investors',
  dieuKienKiemTraHaiQuanVDPL: 'Conditions for customs supervision and inspection for export processing enterprises',

  // Thư viện
  thuVienVideo:'Video Library',
  thuVienAnh:'Photo library',
  anh: 'image',

  // Dự án
  timkiemDA: 'Search',
  linhVucDA: 'Field',
  nhapCCNDA: 'Enter the industrial cluster name',
  nhapKCNDA:'Enter the name of the industrial park',


  // Nghiên cứu đầu tư
  timKiemNKDT: 'Search',
  THU_HUT_DAU_TU: 'Attracting investment',
  NGHIEN_CUU_DAU_TU: 'Investment Research',
  duAnDoThi: 'Urban Project',
  confirm: {
    viTriDangCapNhat: 'Location is being updated',
    close: 'Close'
  },
  form: {
    search: 'Enter search address...'
  },

  // DỰ ÁN ĐẦU TƯ NỔI BẬT
    duAnDTNB: 'Outstanding investment project',
    thacoBusDTNB:'Thaco Bus Factory Project of Thaco Bus Manufacturing One Member Co., Ltd',
    vinpearlHoiAnDTNB:'Vinpearl Nam Hoi An Resort and Tourism Complex Project of Vinpearl Joint Stock Company',
    khuNghiDuongNamHoiAnDTNB:'Nam Hoi An Resort Project of South Hoi An Development Co., Ltd',
    ctyMaiDoanDTNB:'The project of Chu Lai Cat Vang eco-tourism area of Mai Doan Joint Stock Company',
    heinekenDTNB:'Quang Nam brewery operation project for production, marketing and distribution of beers; water business...',
    grozDTNB:'The Companys project of manufacturing equipment and production tools for the textile industry and related industries...',
    fvgtravelDTNB:'Dong Giang Heaven Gate Ecotourism Area Project of Hang Gop Ecotourism Joint Stock Company',
    pankoDTNB:'Investment project to build textile, garment, dyeing and textile accessories factory of Panko Tam Thang Co., Ltd',
    hanacanDTNB:'Project of Manufacturing aluminum cans and cans for aluminum beverage cans of Hanacans Joint Stock Company...',
    envDTNB:'SONG BUNG 4 HYDROPOWER PROJECT OF GENERATION CORPORATION 2 – One Member LLC',
    quangNamDTNB:'Quang Nam',
    subQuangNamDTNB:'is a province in the Central Key Economic Region, one of the six provinces and cities with the largest natural area in the country, located in the East-West Economic Corridor, with rich resources and strategic location. convenient to connect with other provinces and cities of Vietnam, countries in the region and the world.',
    fiveYearsDTNB:'5 YEARS',
    datDTNB:'OBTAIN',
    topDTNB:'TOP 10',
    thanhtichDTNB:'5 YEARS TO ACHIEVE TOP 10 PROVINCIAL COMPETITIVE INDUSTRY (CPI)',
    ThongTinKinhTeXaHoiDTNB:'ECONOMIC - SOCIAL INFORMATION',
    tinTucDTNB:'NEWS',

  // DANH MỤC DỰ ÁN CÔNG BỐ LỰA CHỌN NHÀ ĐẦU TƯ
  danhMucDuAn: 'Announced investor selection',
  luaChonNhaDauTu: 'project list',
  chiTietThongTin: 'Detail information',
  noiDungFileDinhKem: 'Specific content according to the attached files:',

  // DANH MỤC DỰ ÁN NGHIÊN CỨU ĐẦU TƯ
  nghienCuuDauTu: 'investment research',
  dauTuQuangNam: 'Investment in Quang Nam',
  taiLieuXucTien: 'Investment promotion documents',

  // CỤM HOẶC KHU CÔNG NGHIỆP
  tongVonDauTu: 'Total investment',
  tong: 'Total',
  tatCa: 'All',
  lienHe: 'Contact',
  dienThoaiCKCN: 'Phone',
  nguoiPhuTrach: 'Person in charge',
  tepDinhKem: 'Attachment(s)',
  dienTichVaGiaiDoanPhatTrienCKCN: 'Area & Development Stage',
  thongSo: 'Parameter',
  giaiDoanPhatTrien: 'Development Stage',
  giaiDoan: 'Stage',
  tyLeLapDay: 'Fill rate',
  coSoHaTang: 'Infrastructure',
  donGia: 'Unit price',
  dien: 'Electricity',
  nuocSach: 'Clean water',
  xuLyNuocThai: 'Wastewater treatment',
  xuLyRacThai: 'Process rubbish',
  gasChatDot: 'Gas, fuel',
  khac: 'Other',
  maSoDuAn: 'Project ID',
  tenDuAn: 'Project name',
  quocGia: 'Country',
  linhVucHoatDong: 'Fields of activity',
  nguonDien: 'Electricity supply',
  nguonNuoc: 'Water supply',
  heThongThoatNuocXuLyNuocVaChatThai: 'Drainage systems, sewage, and waste treatment systems',
  vienThong: 'Internet',
  haTangXaHoiVaDichVu: 'Social infrastructure and services',
  giaChoThueVaPhiQLBD: 'Land sublease price, infrastructure management, and maintenance service fees',
  timKiemThongTin: 'Look for information',
  duAnTrongCCN: 'Project in industrial cluster',

  // Liên Hệ
  delailoinhanLH: 'Leave message',
  fileLH:'File attached',
  hoTenLH:'Full name',
  nhapHoTenLH: 'Enter your first and last name',
  sdtLH: 'Phone number',
  nhapSdtLH: 'Enter your phone number',
  nhapEmail: 'Enter your email address',
  chuDeLH:'Topic',
  nhapChuDeLH: 'Enter subject',
  noiDungLH: 'Content',
  nhapNoiDungLH: 'Import content',

  // LÔ ĐẤT CCN VÀ KCN
  kiHieuLoDat: 'Plot of Land ID',
  loaiDat: 'Type of land',
  matDoXayDung: 'Building density',
  tangCaoXayDungTrungBinh: 'Average building height',

  // THƯƠNG HIỆU ĐÀU TƯ
  mucLuc: 'Table of contents',
  linhVuc: 'Field',
  nongNghiep: 'Agriculture',
  duLich: 'Tourism',
  doThi: 'Urban',

  // MAP LEAFLET
  hienThiKetQuaCho: 'Display results for',
  tatCaLinhVuc: 'All fields',
  tatCaTPHuyen: 'All Cities/ Districts',

  // DANH SÁCH DỰ ÁN
  tPHuyen: 'Cities/ Districts',
  loaiBanDoCum: 'Industrial Clusters',
}

export default EN_US
