export const state = () => ({
  dataVideoKinhTeXaHoi: [],
})

export const getters = {
  dataVideoKinhTeXaHoi: (state) => {
    return state.dataVideoKinhTeXaHoi
  },
}

export const mutations = {
  SET_RESPONSE_VIDEO_KINH_TE_XA_HOI(state, data) {
    state.dataVideoKinhTeXaHoi = data
  },
}

/* action */
export const actions = {
  async getVideoKinhTeXaHoi({ commit }, formData) {
    try {
      const data = await this.$repositories.video.videoKinhTeXaHoi(formData)
      commit('SET_RESPONSE_VIDEO_KINH_TE_XA_HOI', data?.content)
      return data?.content || []
    } catch (e) {
      commit('SET_RESPONSE_VIDEO_KINH_TE_XA_HOI', [])
      return []
    }
  },
}
