import Vue from 'vue';
import moment from "moment";

export const dateUtils = {
    methods: {
        confirmChange(evt) {
            if (!this.deepEqual(this.model, this.initial)) {
                const unsavedChhangesWarning = 'You have unsaved changes. Are you sure you wish to leave?';
                evt.returnValue = unsavedChhangesWarning;
                return unsavedChhangesWarning;
              } else {
                delete evt.returnValue
              }
      },
      confirmChangeForm(evt) {
        if (!this.deepEqual(this.form, this.initial)) {
            const unsavedChhangesWarning = 'You have unsaved changes. Are you sure you wish to leave?';
            evt.returnValue = unsavedChhangesWarning;
            return unsavedChhangesWarning;
          } else {
            delete evt.returnValue
          }
  },
        /**
        * calculatedate,
        * @string startDate
        * @string endDate
        */
        calculateDate(startDate, endDate) {
            const startTime = new Date(startDate);
            const endTime = new Date(endDate);
            let months = (endTime.getFullYear() - startTime.getFullYear()) * 12;
            months -= startTime.getMonth();
            months += endTime.getMonth();
            if (months < 0) {
                months = 0;
            }
            return Math.round(months) ? Math.round(months) : 0;
        },

        /**
        * Export excel common,
        * @string nameFile
        * @object data
        */
        exportXlsCommon(nameFile, data) {
            const blob = new Blob([data], {
                type: 'application/vnd.ms-excel'
            });
            const downloadLink = document.createElement("a");
            downloadLink.href = URL.createObjectURL(blob);
            downloadLink.download =
                nameFile + '_' + moment().format("yyyyMMDD") + ".xls";
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        },

        /**
        * get process,
        * @int process
        * @int numberDot
        * @int maxProcessDot
        */
        getProcess(process, numberDot = 4, maxProcessDot = 25) {
            const dataProgress = [];
            if (process === undefined) {
                process = 0;
            }
            for (let i = 1;
                i <= numberDot;
                i++) {
                process = process < 0 ? 0 : process;
                if (process >= maxProcessDot) {

                    dataProgress.push(100);
                } else {
                    dataProgress.push((process * 100) / maxProcessDot);
                }
                process -= maxProcessDot;
            }
            return dataProgress;
        },
        /**
         * Format currency
         * @int value
        */
        formatPrice(value) {
            const money = new Intl.NumberFormat('ja-JP', {
                style: 'currency',
                currency: 'JPY',
                currencyDisplay: "code"
            }).format(value).replace("JPY", "")
                .trim()

            return money
        },
        deepEqual (object1, object2) {
            const keys1 = Object.keys(object1);
            const keys2 = Object.keys(object2);
            if (keys1.length !== keys2.length) {
              return false;
            }
            for (const key of keys1) {
              const val1 = object1[key];
              const val2 = object2[key];
              const areObjects = (val1 != null && typeof val1 === 'object') && (val2 != null && typeof val2 === 'object');
              if (
                // eslint-disable-next-line no-mixed-operators
                areObjects && !this.deepEqual(val1, val2) ||
                  // eslint-disable-next-line no-mixed-operators
                  !areObjects && val1 !== val2
              ) {
                return false;
              }
            }
            return true;
          }
    }
}
Vue.mixin(dateUtils);
