
export default {
  props: {
    error: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {
    refresh() {
      if (this.$route.name === 'index') {
        window.location.reload(true)
      } else {
        this.$router.push({ name: 'index' })
      }
    },
  },
}
