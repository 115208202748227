export const state = () => ({
  cauHinhHeThong: [],
})

export const getters = {
  cauHinhHeThong: (state) => {
    return state.cauHinhHeThong
  },
}

/* action */
export const actions = {
  async getCauHinhHeThong({commit}, formData) {
    try {
      const data = await this.$repositories.heThong.cauHinhHeThong(formData)
      return data|| []
    } catch (e) {
      return []
    }
  },
}