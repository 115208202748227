
import '@/assets/css/global.scss'
import { mapGetters } from 'vuex'

export default {
  name: 'MasterPage',
  data() {
    return {
      isActive: false,
      showToTop: false
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'loggedInUser']),
    ...mapGetters({
      xLanguage: 'common/xLanguage'
    }),
    haveFooter() {
      if(this.$route.meta.notFooter) {
        return false
      }
      return true
    }
  },
  async created() {
    await this.getResponseHeader()
    await this.getDistrictCity()
    await this.getLinhVuc()
  },
  mounted() {
    window.addEventListener('scroll', (e)=>{
      if(window.scrollY > 1000) {
         this.showToTop = true;
      } else {
        this.showToTop = false;
      }
    });
  },
  methods: {
    async getDistrictCity() {
      try {
        await this.$store.dispatch('donvihanhchinh/getDonvihanhchinh', '49')
      } catch (e) {
        this.$notify.error(e.message)
      }
    },
    async getLinhVuc() {
      try {
        await this.$store.dispatch('donvihanhchinh/getLinhVuc', {ngonNgu: this.xLanguage})
      } catch (e) {
        this.$notify.error(e.message)
      }
    },
    moveTop() {
      window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
    },
    checkAuth(name) {
      if (this.loggedInUser) {
        this.$router.push({ name })
      } else {
        // this.$router.push({ name })
        this.isActive = true
      }
    },
    redirectLogin() {
      this.closeModal()
      this.$router.push('/login')
    },
    closeModal() {
      this.isActive = false
    },
    getResponseHeader() {
      try {
        this.$store.dispatch('common/getResponseHeader', document.location.origin)
      } catch (e) {
        this.$notify.error(e.message)
      }
    }
  },
}
