
import { mapGetters } from 'vuex'
import { LANGUAGE } from '@/constants/language'

export default {
  props: {
    className: {
      type: String,
      default: 'modal',
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeNames: []
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'loggedInUser']),
    ...mapGetters({
      xLanguage: 'common/xLanguage'
    }),
    textLanguage: ({ xLanguage }) => (keyName) => {
      return LANGUAGE?.[xLanguage]?.[keyName]
    },
  },
  methods: {
    onCloseModal() {
      this.$emit('close-modal');
    },
    close() {
      this.$emit('closeNav')
    },
    handleChange(val) {
      if (val.indexOf("index")) {
        this.$router.push({name: "index"})
      }
    },
    activeLink(routerName) {
      this.$emit('navigation', routerName)
    },
    checkAuth(router){
      this.close();
      this.$emit('check-login', router)
    },
    async logoutRedirect() {
      this.close();
      await this.$auth.logout()
      this.$router.push('/login')
    },
    redirect() {
      window.open(this.$config.baseUrlAdmin, '_blank');
    },
    hotrodautu() {
      this.$emit('close-modal');
      window.open('https://htdn.quangnam.gov.vn/Default.aspx?tabid=40', '_blank');
    },
    thutuchanhchinh() {
      this.$emit('close-modal');
      window.open('https://dichvucong.quangnam.gov.vn/expway/smartcloud/iprocedure/page/public/procedure.cpx', '_blank');
    },
    dichvucong() {
      window.open('https://dichvucong.quangnam.gov.vn/expway/smartcloud/icloudgate/page/home.cpx');
    },
    congthongtindt() {
      window.open('https://quangnam.gov.vn/webcenter/portal/ubnd');
    },
    congthongtindtso() {
      window.open('https://skhdt.quangnam.gov.vn/webcenter/portal/sokhdt');
    },
    congthongtindtbo() {
      window.open('https://www.mpi.gov.vn/');
    },
    motcuadientu() {
      this.$emit('close-modal');
      window.open('http://motcua.skhdt.quangnam.gov.vn/', '_blank');
    }
  },
}
