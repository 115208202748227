

export const getters = {
    isAuthenticated(state) {
        return state.auth.loggedIn
    },

    loggedInUser(state) {
        return state.auth.user
    },

    engineer(state) {
      return state.engineer.engineerDetail
    }
}
