import { STATUS } from '@/constants/constants'

export default async  function  (context) {
  try {
    const params = context.route.query.token;
    if(params) {
      // eslint-disable-next-line no-use-before-define
      const token = {token: params};
      const data = await context.store.dispatch('engineer/verified_account', token);
      if (data.code === STATUS.UNAUTHORIZED) {
        context.error({statusCode: data.code, message: data.message})
      }
      else{
        await context.$auth.setToken('local', 'Bearer ' + data.data.token)
        await context.$auth.setUser(data.data.user)
        this.$router.push('/')
      }
    }
  }catch (e){
    context.redirect('/')
  }
}
