/* action */
export const actions = {
  async login(ctx, dataLogin) {
    try {
      const data = await this.$repositories.authen.login(dataLogin)
      if (data?.data.token) {
        this.$api.setToken(data.data.token, 'Bearer')
      }

      return data
    } catch (e) {
      return e
    }
  },
  async mediaPage({ commit }, dataForm) {
    try {
      return await this.$repositories.mediaPortal.mediaPage(dataForm)
    } catch (e) {
      return e
    }
  },
}
