import {STATUS} from "~/constants/constants";
export default async function (context) {
    if (context.route.query.token){
       const data = await context.store.dispatch('authenticate/checkTokenExits',{token:context.route.query.token});
        if (data.code === STATUS.UNAUTHORIZED) {
            context.error({statusCode: data.code, message: data.message})
        }
        else{
          context.$auth.logout();
        }
    }
}
