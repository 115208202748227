import Vue from "vue";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { required, max } from 'vee-validate/dist/rules';
import { i18n } from "@/plugins/i18n";


Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
extend('required', {
  ...required,
  message: i18n.t('M01')
})
extend('max', {
  ...max,
  message: i18n.t('M44')
})
extend("requiredCheckbox", {
  computesRequired: true,
  validate(value) {
    return value.length
  },
  message: i18n.t('M25')
});





// check all space value
extend('spaceEmpty', {
  computesRequired: true,
  validate: value => {
    let valid = true;
   if (value.length !== 0 && !value.replace(/\s/g, '').length) {
        valid = false
      }
    return valid;
  },
  message: i18n.t('M02')
})

// check password
extend('passwordRegex', {
  computesRequired: true,
  validate: value => value.match(/^[a-zA-z0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{}~]{6,12}$/u),
  message: i18n.t('M18')
})
// check name katakana
extend('katakanaRegex', {
  computesRequired: true,
  validate: value => value.match(/^[ァ-ヴーｧ-ﾝﾞﾟ]+$/u),
  message: i18n.t('M37')
})

// check phone
extend('phoneRegex', {
  computesRequired: true,
  validate: value => value.match(/^[0-9.+]*$/),
  message: i18n.t('M12')
})
