export default ($api) => ({
  thongtinquyhoachtinh: (data) => {
    return $api.get(`thong-tin-quy-hoach-tinh-page`, { params: data })
  },
  tinKinhTe: (data) => {
    return $api.get('tin-kinh-te-page', { params: data })
  },
  thongTinQuyHoachHuyen: (data) => {
    return $api.get('thong-tin-quy-hoach-huyen-page', { params: data })
  }
})
