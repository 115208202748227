/* action */

export const actions = {
    async login(ctx, dataLogin) {
        try {
            const data = await this.$repositories.authen.login(dataLogin)

            if (data?.data.token) {
                this.$api.setToken(data.data.token, 'Bearer')
            }
            return data
        } catch (e) {
            return e;
        }
    },
    async resetPassword({commit}, dataForm){
        try {
            const data = await this.$repositories.authen.resetPassword(dataForm)

          return data
        } catch (e) {
          return e;
        }
    },
    async sendEmail({commit},dataForm){
        try {
            const data = await this.$repositories.authen.sendEmail(dataForm)
            return data
        } catch (e) {
            return e;
        }
    },
    async checkTokenExits({commit},dataForm){
        try {
            return await this.$repositories.authen.checkTokenExits(dataForm);
        } catch (e) {
            return e;
        }
    },
}
