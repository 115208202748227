
export default {
  name: 'compAdminHeader',
  data() {
    return {
      dataLanguage: [
        {
          label: 'Tiếng Việt',
          value: 'vn',
        },
        {
          label: 'Tiếng Anh',
          value: 'en',
        },
      ],
      activeLang: 'vn',
    }
  },
  methods: {
    onSelect(value) {
      this.activeLang = value
    },
    toggleMenu() {
      this.$emit('toggleMenu')
    }
  },
}
