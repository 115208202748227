export const STATUS = {
  SUCCESS: 200,
  BAD_REQUEST: 422,
  ERROR: 500,
  NOT_FOUND:404,
  FAIL:400,
  AUTHORIZATION: 403,
  UNAUTHORIZED: 401,
  HTTP_REQUEST_TIMEOUT: 408,
}

export const PREFECTURE = {
  'huyentaygiang': 'Tây Giang',
  'huyentienphuoc': 'Tiên Phước',
  'huyenphuninh': 'Phú Ninh',
  'huyennuithanh': 'Núi Thành',
  'thixadienban': 'Điện Bàn',
  'huyendailoc': 'Đại Lộc',
  'huyennamgiang': 'Nam Giang',
  'thanhphotamky': 'Tam Kỳ',
  'huyenqueson': 'Quế Sơn',
  'thanhphohoian': 'Hội An',
  'huyenhiepduc': 'Hiệp Đức',
  'huyennamtramy': 'Nam Trà My',
  'huyenthangbinh': 'Thăng Bình',
  'huyenduyxuyen': 'Duy Xuyên',
  'huyenphuocson': 'Phước Sơn',
  'huyendonggiang': 'Đông Giang',
  'huyenbactramy': 'Bắc Trà My',
  'huyennongson': 'Nông Sơn'
};

export const IDQuangNam = 1151

export const TYPE_DU_AN = {
  ThuHut: 1,
  NghienCuu: 2,
}

export const AGE = [20, 30, 40, 50, 60, 70, 80, 90, 100];

export const DATE = {
  YEAR_MONTH_DATE: 'YYYY-MM-DD',
}

export const TYPE = {
  PASSWORD: 'password'
}

export const TYPE_LOAI = {
  DU_AN: 'DU_AN',
  CUM_CONG_NGHIEP: 'CUM_CONG_NGHIEP'
}

export const TYPE_IMAGE = [
  'jpeg',
  'png',
  'jpg',
]
