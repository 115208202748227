export const Dialog = () => import('../..\\components\\Dialog.vue' /* webpackChunkName: "components/dialog" */).then(c => wrapFunctional(c.default || c))
export const Input = () => import('../..\\components\\Input.vue' /* webpackChunkName: "components/input" */).then(c => wrapFunctional(c.default || c))
export const LoadingBar = () => import('../..\\components\\LoadingBar.vue' /* webpackChunkName: "components/loading-bar" */).then(c => wrapFunctional(c.default || c))
export const LRoutingMachine = () => import('../..\\components\\LRoutingMachine.vue' /* webpackChunkName: "components/l-routing-machine" */).then(c => wrapFunctional(c.default || c))
export const NuxtLogo = () => import('../..\\components\\NuxtLogo.vue' /* webpackChunkName: "components/nuxt-logo" */).then(c => wrapFunctional(c.default || c))
export const Pagination = () => import('../..\\components\\Pagination.vue' /* webpackChunkName: "components/pagination" */).then(c => wrapFunctional(c.default || c))
export const Tutorial = () => import('../..\\components\\Tutorial.vue' /* webpackChunkName: "components/tutorial" */).then(c => wrapFunctional(c.default || c))
export const DautuLuachonnhadautuitem = () => import('../..\\components\\dautu\\luachonnhadautuitem.vue' /* webpackChunkName: "components/dautu-luachonnhadautuitem" */).then(c => wrapFunctional(c.default || c))
export const CommonAdminHeader = () => import('../..\\components\\common\\AdminHeader.vue' /* webpackChunkName: "components/common-admin-header" */).then(c => wrapFunctional(c.default || c))
export const CommonCard = () => import('../..\\components\\common\\card.vue' /* webpackChunkName: "components/common-card" */).then(c => wrapFunctional(c.default || c))
export const CommonCollapse = () => import('../..\\components\\common\\Collapse.vue' /* webpackChunkName: "components/common-collapse" */).then(c => wrapFunctional(c.default || c))
export const CommonNew = () => import('../..\\components\\common\\new.vue' /* webpackChunkName: "components/common-new" */).then(c => wrapFunctional(c.default || c))
export const CommonPagination = () => import('../..\\components\\common\\pagination.vue' /* webpackChunkName: "components/common-pagination" */).then(c => wrapFunctional(c.default || c))
export const CommonSlider = () => import('../..\\components\\common\\slider.vue' /* webpackChunkName: "components/common-slider" */).then(c => wrapFunctional(c.default || c))
export const CommonTable = () => import('../..\\components\\common\\table.vue' /* webpackChunkName: "components/common-table" */).then(c => wrapFunctional(c.default || c))
export const DuanBaoton = () => import('../..\\components\\duan\\Baoton.vue' /* webpackChunkName: "components/duan-baoton" */).then(c => wrapFunctional(c.default || c))
export const DuanBaotonDetail = () => import('../..\\components\\duan\\BaotonDetail.vue' /* webpackChunkName: "components/duan-baoton-detail" */).then(c => wrapFunctional(c.default || c))
export const FilpbookListDuanMobile = () => import('../..\\components\\filpbook\\ListDuanMobile.vue' /* webpackChunkName: "components/filpbook-list-duan-mobile" */).then(c => wrapFunctional(c.default || c))
export const FilpbookMucluc1 = () => import('../..\\components\\filpbook\\mucluc1.vue' /* webpackChunkName: "components/filpbook-mucluc1" */).then(c => wrapFunctional(c.default || c))
export const FilpbookMucluc2 = () => import('../..\\components\\filpbook\\mucluc2.vue' /* webpackChunkName: "components/filpbook-mucluc2" */).then(c => wrapFunctional(c.default || c))
export const FilpbookMucluc3 = () => import('../..\\components\\filpbook\\mucluc3.vue' /* webpackChunkName: "components/filpbook-mucluc3" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../..\\components\\Footer\\Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const FormButton = () => import('../..\\components\\form\\button.vue' /* webpackChunkName: "components/form-button" */).then(c => wrapFunctional(c.default || c))
export const FormEditor = () => import('../..\\components\\form\\editor.vue' /* webpackChunkName: "components/form-editor" */).then(c => wrapFunctional(c.default || c))
export const FormInput = () => import('../..\\components\\form\\input.vue' /* webpackChunkName: "components/form-input" */).then(c => wrapFunctional(c.default || c))
export const FormInputcustom = () => import('../..\\components\\form\\inputcustom.vue' /* webpackChunkName: "components/form-inputcustom" */).then(c => wrapFunctional(c.default || c))
export const FormRadio = () => import('../..\\components\\form\\radio.vue' /* webpackChunkName: "components/form-radio" */).then(c => wrapFunctional(c.default || c))
export const FormSelect = () => import('../..\\components\\form\\select.vue' /* webpackChunkName: "components/form-select" */).then(c => wrapFunctional(c.default || c))
export const FormUpload = () => import('../..\\components\\form\\upload.vue' /* webpackChunkName: "components/form-upload" */).then(c => wrapFunctional(c.default || c))
export const FormUploaddrag = () => import('../..\\components\\form\\uploaddrag.vue' /* webpackChunkName: "components/form-uploaddrag" */).then(c => wrapFunctional(c.default || c))
export const FormUploadimages = () => import('../..\\components\\form\\uploadimages.vue' /* webpackChunkName: "components/form-uploadimages" */).then(c => wrapFunctional(c.default || c))
export const GisCumcongnghiep = () => import('../..\\components\\gis\\cumcongnghiep.vue' /* webpackChunkName: "components/gis-cumcongnghiep" */).then(c => wrapFunctional(c.default || c))
export const GisNghiencuudautu = () => import('../..\\components\\gis\\nghiencuudautu.vue' /* webpackChunkName: "components/gis-nghiencuudautu" */).then(c => wrapFunctional(c.default || c))
export const GisNghiencuudautumini = () => import('../..\\components\\gis\\nghiencuudautumini.vue' /* webpackChunkName: "components/gis-nghiencuudautumini" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../..\\components\\Header\\Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const HeaderMobile = () => import('../..\\components\\Header\\HeaderMobile.vue' /* webpackChunkName: "components/header-mobile" */).then(c => wrapFunctional(c.default || c))
export const LightboxCloseIcon = () => import('../..\\components\\lightbox\\CloseIcon.vue' /* webpackChunkName: "components/lightbox-close-icon" */).then(c => wrapFunctional(c.default || c))
export const LightboxLeftArrowIcon = () => import('../..\\components\\lightbox\\LeftArrowIcon.vue' /* webpackChunkName: "components/lightbox-left-arrow-icon" */).then(c => wrapFunctional(c.default || c))
export const LightboxLightBox = () => import('../..\\components\\lightbox\\LightBox.vue' /* webpackChunkName: "components/lightbox-light-box" */).then(c => wrapFunctional(c.default || c))
export const LightboxRightArrowIcon = () => import('../..\\components\\lightbox\\RightArrowIcon.vue' /* webpackChunkName: "components/lightbox-right-arrow-icon" */).then(c => wrapFunctional(c.default || c))
export const LightboxVideoIcon = () => import('../..\\components\\lightbox\\VideoIcon.vue' /* webpackChunkName: "components/lightbox-video-icon" */).then(c => wrapFunctional(c.default || c))
export const ThuvienThuVienAnh = () => import('../..\\components\\thuvien\\ThuVienAnh.vue' /* webpackChunkName: "components/thuvien-thu-vien-anh" */).then(c => wrapFunctional(c.default || c))
export const ThuvienThuVienVideo = () => import('../..\\components\\thuvien\\ThuVienVideo.vue' /* webpackChunkName: "components/thuvien-thu-vien-video" */).then(c => wrapFunctional(c.default || c))
export const DautuThongtinquyhoachBanvequyhoachitem = () => import('../..\\components\\dautu\\thongtinquyhoach\\banvequyhoachitem.vue' /* webpackChunkName: "components/dautu-thongtinquyhoach-banvequyhoachitem" */).then(c => wrapFunctional(c.default || c))
export const LayoutsAdminHeader = () => import('../..\\components\\layouts\\admin\\header.vue' /* webpackChunkName: "components/layouts-admin-header" */).then(c => wrapFunctional(c.default || c))
export const LayoutsAdminMenuItem = () => import('../..\\components\\layouts\\admin\\menu-item.vue' /* webpackChunkName: "components/layouts-admin-menu-item" */).then(c => wrapFunctional(c.default || c))
export const LayoutsAdminMenu = () => import('../..\\components\\layouts\\admin\\menu.vue' /* webpackChunkName: "components/layouts-admin-menu" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
