
export const state = () => ({
  loaiDuAn: [],
})

export const getters = {
  loaiDuAn: (state) => {
    return state.loaiDuAn
  }
}

export const mutations = {
  SET_LOAI_DU_AN(state, loaiDuAn) {
    state.loaiDuAn = loaiDuAn
  },
}

/* action */
export const actions = {
  async duAns({commit},dataForm){
    try {
      return await this.$repositories.duAns.duAns(dataForm);
    } catch (e) {
      return e;
    }
  },
  async thongKeDuAn({commit},dataForm){
    try {
      return await this.$repositories.duAns.thongKeDuAn(dataForm);
    } catch (e) {
      return e;
    }
  },
  async mucLucDuAn({commit},dataForm){
    try {
      return await this.$repositories.duAns.mucLucDuAn(dataForm);
    } catch (e) {
      return e;
    }
  },
  async loaiDuAn({ commit }, dataForm) {
    try {
      const data = await this.$repositories.duAns.loaiDuAn(dataForm)
      commit('SET_LOAI_DU_AN', data?.content || [])
      return data
    } catch (e) {
      return e
    }
  },
}
