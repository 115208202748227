import Vue from "vue";
import VueI18n from "vue-i18n";

// Localisation language list

import { locale as jp } from "@/core/lang/jp";


Vue.use(VueI18n);

let messages = {};
messages = { ...messages, jp };

// get current selected language

export const i18n = new VueI18n({
    locale: 'jp', // set locale
    messages // set locale messages
});

export default ({ app, store }) => {
    app.i18n = i18n;
}
