export const state = () => ({
  dataThuVienVideo: [],
  dataThuVienAnh:[]
})

export const getters = {
  dataThuVienVideo: (state) => {
    return state.dataThuVienVideo
  },
  dataThuVienAnh: (state) => {
    return state.dataThuVienAnh
  },
}

/* action */
export const actions = {
  async getThuVienVideo({commit}, formData) {
    try {
      const data = await this.$repositories.thuVien.thuVienVideo(formData)
      return data || []
    } catch (e) {
      return []
    }
  },
  async getThuVienAnh({commit}, formData) {
    try {
      const data = await this.$repositories.thuVien.thuVienAnh(formData)
      return data|| []
    } catch (e) {
      return []
    }
  },
}
